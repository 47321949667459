import React, { memo } from "react";
import { ArrowLeftIcon } from "src/components/icons/ArrowLeftIcon";
import styled from "styled-components";
import { usePolyfitHistory } from "src/hooks/router";
import { useSearchParams } from "react-router-dom";

type Props = {
  targetRoute: "BE_CHATROOM_LIST" | "RESIDENT_CHAT";
};

export const ChatRoomBackButton = memo(({ targetRoute }: Props) => {
  const history = usePolyfitHistory();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const onClick = () => {
    history.push({
      to: targetRoute,
      query: {
        tab: tab ?? undefined,
      },
    });
  };
  return (
    <Wrapper>
      <EmptyButton onClick={onClick}>
        <ArrowLeftIcon size={18} />
      </EmptyButton>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const EmptyButton = styled.button`
  height: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;
