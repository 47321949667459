import React from "react";
import styled from "styled-components";

type LabelProps = {
  children?: React.ReactNode;
  required?: boolean;
  optional?: boolean;
  disabled?: boolean;
  size?: "s" | "m";
  marginBottom?: number;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

export const Label = ({
  children,
  required,
  optional,
  size = "m",
  disabled,
  marginBottom = 8,
  ...props
}: LabelProps) => {
  return (
    <LabelWrapper $disabled={disabled} $marginBottom={marginBottom} {...props}>
      <StyledLabel size={size}>{children}</StyledLabel>
      {required && <Required size={size}>*必須</Required>}
      {optional && <Optional size={size}>*任意</Optional>}
    </LabelWrapper>
  );
};

const StyledLabel = styled.span<{
  size: "s" | "m";
}>`
  font-size: ${({ size }) => (size === "s" ? "12px" : "14px")};
  color: #1a1c21;
  font-weight: bold;
`;

const Required = styled.span<{
  size: "s" | "m";
}>`
  color: #bd271e;
  font-size: ${({ size }) => (size === "s" ? "10px" : "12px")};
`;

const Optional = styled.span<{
  size: "s" | "m";
}>`
  color: #69707d;
  font-size: ${({ size }) => (size === "s" ? "10px" : "12px")};
`;

const LabelWrapper = styled.label<{
  $disabled?: boolean;
  $marginBottom: number;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: ${(props) => props.$marginBottom}px;
  ${(props) => props.$disabled && `opacity: 0.3;`}
`;
