import React, { useCallback, useEffect, useState } from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import { upload } from "../../../apiClients/storage";
import RecruitmentPreview from "../../../features/BoardEducation/Recruitment/recruitment/RecruitmentFormPreview";
import ResidentRecruitmentForm from "../../../features/BoardEducation/Recruitment/recruitment/RecruitmentForm";
import { useGeoCording } from "../../../hooks/useGeocoding";
import { Header } from "../../../components/Header";
import { createBERecruitment } from "src/apiClients/boardEducation/recruitment";
import {
  RecruitmentFormValue,
  convertRecruitmentCommunityUserTypes,
  useRecruitmentForm,
} from "src/features/BoardEducation/Recruitment/recruitment/useRecruitmentForm";
import { Spinner } from "src/components/icons/Spinner";
import styled from "styled-components";
import { transformIsAutoApprovalToEntryMethod } from "src/validator/form/recruitment/recruitmentSchema";

export default function BERecruitmentCreatePage() {
  const [isPreview, setIsPreview] = useState(false);
  const history = usePolyfitHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isPreview) return;
    setIsLoaded(true);
  }, []);

  const { getLocationAddressFromPostcode } = useGeoCording();

  const {
    control,
    register,
    getValues,
    setValue,
    trigger,
    isSubmitted,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors,
  } = useRecruitmentForm({
    defaultValues: {
      selectedLocationCommunityId: "",
      postalCode: "",
      prefecture: "",
      city: "",
      address1: "",
      address2: null,
      longitude: null,
      latitude: null,
      wageType: "HOURLY",
      wageAmount: 0,
      teacherLicenses: [],
      medicalLicenses: [],
      skills: [],
      isUnlimitedVolunteerCount: false,
      saveTemplate: false,
      alreadyUploadedPdfFiles: [],
      recruitmentCommunityUserTypes: {},
      isAutoApproval: false,
    },
  });

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getLocationAddressFromPostcode(value);
      setValue("prefecture", res.prefecture);
      setValue("city", res.city);
      setValue("address1", res.address1);
      setValue("address2", "");
      setValue("latitude", res.latitude);
      setValue("longitude", res.longitude);
      clearErrors("postalCode");
    } catch (err) {
      setError("postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  const watchAllValue = watch();

  const onPreviewClick = useCallback(() => {
    setIsPreview(true);
  }, []);

  const onDraftClick = async () => {
    const formValues = getValues();
    const files = formValues.pdfFiles ?? [];

    if (typeof formValues.uploadImage === "string") {
      throw new Error("画像のアップロードに失敗しました");
    }

    const pictureUploadPath =
      formValues?.uploadImage?.length === 1
        ? await upload({ file: formValues.uploadImage[0] })
        : null;

    const schedule = formValues.schedule.map((s) => ({
      date: new Date(s.date),
      start: new Date(s.date + "T" + s.startTime),
      end: new Date(s.date + "T" + s.endTime),
    }));

    await createBERecruitment(
      {
        status: "DRAFT",
        title: formValues.title,
        volunteerDetailType: formValues.volunteerDetailType,
        isPaidVolunteer: formValues.isPaidVolunteer,
        volunteerType: formValues.volunteerType,
        schedule,
        description: formValues.description,
        postalCode: formValues.postalCode,
        prefecture: formValues.prefecture,
        city: formValues.city,
        address1: formValues.address1,
        address2: formValues.address2,
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        treatment: formValues.treatment,
        wageType: formValues.wageType,
        wageAmount: formValues.wageAmount,
        teacherLicenses: formValues.teacherLicenses,
        medicalLicenses: formValues.medicalLicenses,
        skills: formValues.skills,
        alreadyUploadedPdfFiles: formValues.alreadyUploadedPdfFiles,
        targetCommunities: convertRecruitmentCommunityUserTypes(
          formValues.recruitmentCommunityUserTypes
        ),
        pictureUploadPath,
        saveTemplate: formValues.saveTemplate,
        volunteerCount: formValues.isUnlimitedVolunteerCount
          ? -1
          : formValues.volunteerCount ?? 0,
        entryMethod: transformIsAutoApprovalToEntryMethod(
          formValues.isAutoApproval
        ),
      },
      files
    );

    // TODO: 下書きページができたら変える
    // history.push({ to: "RESIDENT_RECRUITMENT_DRAFT_LIST" });
  };

  const [isLoading, setIsLoading] = useState(false);
  const onPreviewSubmitClick = async (formValues: RecruitmentFormValue) => {
    setIsLoading(true);
    if (typeof formValues.uploadImage === "string") {
      throw new Error("画像のアップロードに失敗しました");
    }
    const files = formValues.pdfFiles ?? [];

    const pictureUploadPath =
      formValues?.uploadImage?.length === 1
        ? await upload({ file: formValues.uploadImage[0] })
        : null;

    const schedule = formValues.schedule.map((s) => ({
      date: new Date(s.date),
      start: s.startTime ? new Date(s.date + "T" + s.startTime) : null,
      end: s.endTime ? new Date(s.date + "T" + s.endTime) : null,
    }));

    await createBERecruitment(
      {
        status: "OPENED",
        title: formValues.title,
        volunteerDetailType: formValues.volunteerDetailType,
        isPaidVolunteer: formValues.isPaidVolunteer,
        volunteerType: formValues.volunteerType,
        schedule,
        description: formValues.description,
        postalCode: formValues.postalCode,
        prefecture: formValues.prefecture,
        city: formValues.city,
        address1: formValues.address1,
        address2: formValues.address2,
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        wageType: formValues.wageType,
        wageAmount: formValues.wageAmount,
        treatment: formValues.treatment,
        teacherLicenses: formValues.teacherLicenses,
        medicalLicenses: formValues.medicalLicenses,
        skills: formValues.skills,
        alreadyUploadedPdfFiles: formValues.alreadyUploadedPdfFiles,
        targetCommunities: convertRecruitmentCommunityUserTypes(
          formValues.recruitmentCommunityUserTypes
        ),
        pictureUploadPath,
        saveTemplate: formValues.saveTemplate,
        volunteerCount: formValues.isUnlimitedVolunteerCount
          ? -1
          : formValues.volunteerCount ?? 0,
        entryMethod: transformIsAutoApprovalToEntryMethod(
          formValues.isAutoApproval
        ),
      },
      files
    );

    setIsLoading(false);
    history.push({ to: "BE_ADMIN_RECRUITMENT_LIST" });
  };

  const onPreviewCancelClick = useCallback(() => {
    setIsPreview(false);
  }, []);

  if (!isLoaded)
    return (
      <>
        <Header title={isPreview ? "プレビュー" : "新規作成"} />
        <SpinnerCard>
          <Spinner />
        </SpinnerCard>
      </>
    );

  return (
    <>
      <Header title={isPreview ? "プレビュー" : "新規作成"} />
      {!isPreview && (
        <ResidentRecruitmentForm
          control={control}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          trigger={trigger}
          isSubmitted={isSubmitted}
          watchAllValue={watchAllValue}
          onPreviewClick={handleSubmit(onPreviewClick)}
          onDraftSubmit={onDraftClick}
          getAddress={getAddress}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
      {isPreview && (
        <RecruitmentPreview
          watchAllValue={watchAllValue}
          onSubmit={handleSubmit(onPreviewSubmitClick)}
          onCancel={onPreviewCancelClick}
          isRecruitmentCreateNew={true}
          isLoading={isLoading}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
    </>
  );
}

const SpinnerCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-bottom: 40px;
  padding-top: 40px;
`;
