import {
  MultiChoiceQuestion,
  OpenEndedQuestion,
  Question,
} from "@shared/validator/features/survey.schema";

// 種別判定用の関数、質問用
export function isMultiChoiceQuestion(
  question: Question
): question is MultiChoiceQuestion {
  return question.questionType === "MULTI_CHOICE";
}
export function isOpenEndedQuestion(
  question: Question
): question is OpenEndedQuestion {
  return question.questionType === "OPEN_ENDED";
}

// 諸定数
// 最低限の質問数（この数以下は削除できなくする）
export const MINIMUM_NUMBER_OF_QUESTIONS: number = 1;
// 最低限の選択肢数（この数以下は削除できなくする）
export const MINIMUM_NUMBER_OF_CHOICES: number = 2;
// 締め切りのデフォルト（単位：ミリ秒）
export const SURVEY_DEFAULT_DEADLINE: number = 72 * 60 * 60 * 1000;
// 締め切りの時間間隔（単位：分）
export const SURVEY_DEADLINE_TIME_INTERVAL: number = 15;
// 最大の選択肢数（この数以上にはできなくする）
export const MAXIMUM_NUMBER_OF_CHOICES: number = 10;
