import React from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import { toDateIntervalFormat } from "src/utils/time";
import {
  applicationAttendanceStatus,
  applicationAttendanceStatusOptions,
} from "@shared/types/recruitmentApplicationEvent";
import { useForm } from "react-hook-form";
import { updateAttendanceStatus } from "src/apiClients/recruitmentApplicationEvent";
import { logger } from "src/utils/logger";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  updateEventAttendanceStatusSchema,
  UpdateEventAttendanceStatusSchema,
} from "@shared/validator/features/recruitmentApplicationEvent.schema";
import { ButtonGroupExtend, ButtonSelect } from "src/components/ButtonSelect";
import { Label } from "src/components/form/Label";

type Props = {
  applicant: Pick<RecruitmentApplication, "user" | "recruitment">;
  event: RecruitmentApplication["eventStatuses"][number];
  onClose: () => void;
  reFetchRecruitment: () => void;
};

export const AttendanceModal = ({
  applicant,
  event,
  onClose,
  reFetchRecruitment,
}: Props) => {
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<UpdateEventAttendanceStatusSchema>({
    resolver: zodResolver(updateEventAttendanceStatusSchema),
    defaultValues: {
      attendanceStatus: undefined,
      recruitApplicationEventId: event.id,
    },
    mode: "onChange",
  });

  const attendanceStatus = watch("attendanceStatus");
  const canSubmit = attendanceStatus !== undefined && !isSubmitting;

  const availableStatusOptions = applicationAttendanceStatusOptions.filter(
    (option) => option.value !== event.attendanceStatus
  );

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateAttendanceStatus(data);
      reFetchRecruitment();
    } catch (error) {
      logger.error(new Error(`Error during attendance process:` + error, {}), {
        applicant,
        event,
      });
    } finally {
      onClose();
    }
  });

  const getCurrentStatusText = (
    status:
      | (typeof applicationAttendanceStatus)[keyof typeof applicationAttendanceStatus]
      | null
  ) => {
    const statusOption = applicationAttendanceStatusOptions.find(
      (option) => option.value === status
    );
    return statusOption?.text ?? "未出席";
  };

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>出欠確認</ModalHeader>
        <ModalBody>
          <ModalContent>
            <div>以下の申込者の出席状況を変更しますか?</div>
            <InfoSection>
              <InfoTitle>名前</InfoTitle>
              <InfoText>{applicant.user.name}</InfoText>
            </InfoSection>
            <InfoSection>
              <InfoTitle>応募タイトル</InfoTitle>
              <InfoText>{applicant.recruitment.title}</InfoText>
            </InfoSection>
            <InfoSection>
              <InfoTitle>開催日時</InfoTitle>
              <InfoText>
                {toDateIntervalFormat(
                  new Date(event.recruitmentSchedule.start),
                  new Date(event.recruitmentSchedule.end)
                )}
              </InfoText>
            </InfoSection>
            <InfoSection>
              <InfoTitle>現在の出席状況</InfoTitle>
              <InfoText>
                {getCurrentStatusText(event.attendanceStatus)}
              </InfoText>
            </InfoSection>
            <InfoSection>
              <Label marginBottom={8} required>
                出席状況を変更
              </Label>
              <ButtonGroupExtend>
                {availableStatusOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={attendanceStatus === option.value}
                    onClick={() => setValue("attendanceStatus", option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
            </InfoSection>
            <Margin marginTop={4} />
            <ModalSubmitButtons
              submitText="変更する"
              onSubmit={onSubmit}
              onCancel={onClose}
              disabled={!canSubmit}
            />
          </ModalContent>
        </ModalBody>
      </ModalPortal>
    </>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & + & {
    margin-top: 16px;
  }
`;

const InfoTitle = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;
