import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { Margin } from "src/components/Margin";
import { toDateIntervalFormat } from "src/utils/time";

interface Props {
  status: string;
  applicants: RecruitmentApplication[];
  onSubmit: (status: string, comment: string) => void;
  onClose: () => void;
  disabled: boolean;
  changeStatusText: "選考中" | "参加決定" | "見送り" | "不参加" | undefined;
}

// 一括処理実行モーダル
// TODO: 複数応募に関しては改善の余地がある
export const ExecuteApplicationsModal = ({
  status,
  applicants,
  onSubmit,
  onClose,
  disabled,
  changeStatusText,
}: Props) => {
  const [text, setText] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= 3000) {
        setText(e.target.value);
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (showErrorMessage) return;
    onSubmit(status, text);
  }, [showErrorMessage, text, onSubmit, status]);

  // applicantsの中に見送り・不参加の人がいるか
  const isExistNotParticipate = applicants.some((applicant) =>
    applicant.eventStatuses.some(
      (event) =>
        event.selectionStatus === "REJECTED" ||
        event.selectionStatus === "DECLINED"
    )
  );

  const isDangerStatus =
    changeStatusText === "見送り" || changeStatusText === "不参加";

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>
          {applicants.length}件の応募を{changeStatusText}にしますか？
        </ModalHeader>
        <ModalBody>
          {isExistNotParticipate && (
            <>
              <p>※すでに見送り・不参加の人のステータスは変更されません。</p>
              <br />
            </>
          )}
          <ModalContent>
            <div>
              {changeStatusText}を確定すると、お相手に{changeStatusText}
              通知が届きます。
            </div>
            <InfoSection>
              <InfoTitle>応募タイトル</InfoTitle>
              <InfoText>{applicants[0].recruitment.title}</InfoText>
            </InfoSection>
            <InfoSection>
              <InfoTitle>開催日時</InfoTitle>
              {applicants[0].eventStatuses.map((event) => (
                <InfoText key={event.id}>
                  {toDateIntervalFormat(
                    new Date(event.recruitmentSchedule.start),
                    new Date(event.recruitmentSchedule.end)
                  )}
                </InfoText>
              ))}
            </InfoSection>
            <InfoTitle>対象の応募者</InfoTitle>
            <ApplicantInfo>
              {applicants.length > 0 &&
                applicants.map((applicant, index) => (
                  <InfoSection key={applicant.id}>
                    <InfoText>
                      {index > 0
                        ? `, ${applicant.user.name}`
                        : applicant.user.name}
                    </InfoText>
                  </InfoSection>
                ))}
            </ApplicantInfo>
            {isDangerStatus && (
              <>
                <Label>コメント</Label>
                <TextArea
                  name=""
                  id=""
                  cols={30}
                  rows={10}
                  value={text}
                  onChange={onChangeText}
                  placeholder="入力してください（3000文字以内）"
                />
                {showErrorMessage && <>3000文字以内で入力してください</>}
              </>
            )}
            <Margin marginTop={4} />
            <ModalSubmitButtons
              submitText={`${changeStatusText}にする`}
              onSubmit={handleSubmit}
              onCancel={onClose}
              disabled={showErrorMessage || disabled}
              submitColor={isDangerStatus ? "danger" : "primary"}
            />
          </ModalContent>
        </ModalBody>
      </ModalPortal>
    </>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ApplicantInfo = styled.div`
  display: flex;
  gap: 4px;
`;

const InfoSection = styled.div``;

const InfoTitle = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;

const Label = styled.div`
  color: #69707d;
  font-size: 12px;
`;
