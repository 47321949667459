import { z } from "zod";
import {
  zHiraganaString,
  zPastDateString,
  zPhoneNumberString,
  zRequiredString,
} from "../rules/string";
import { belongSchema } from "./belong.schema";
import { partSchema } from "./part.schema";

export const baseInfoSchema = z.object({
  id: z.string().optional(),
  userId: z.string().optional(),
  lastName: zRequiredString,
  firstName: zRequiredString,
  lastNameKana: zHiraganaString,
  firstNameKana: zHiraganaString,
  phoneNumber: zPhoneNumberString,
  schoolGroup: z.string().optional().nullable(),
  isPreschooler: z.boolean().optional(),
  selfFreeMemo: z.string().optional(),
  adminFreeMemo: z.string().optional(),
  gender: z
    .enum(["MALE", "FEMALE", "OTHER"], {
      errorMap: () => ({ message: "性別を選択してください" }),
    })
    .optional(),
  ageGroup: z.number().optional(),
  birthday: zPastDateString.optional(),
  postalCode: z.string().optional(),
  prefecture: z.string().optional(),
  city: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  belongId: z.string().optional().nullable(),
  belong: belongSchema.optional(),
  partId: z.string().optional().nullable(),
  part: partSchema.optional(),
});

export type BaseInfo = z.infer<typeof baseInfoSchema>;
