import React, { useCallback } from "react";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { useLoginUser } from "../../../hooks/recoil/user";
import { useOrganizationsByCommunityId } from "../../../hooks/api/internal/organizations";
import { useSearchParams } from "react-router-dom";
import {
  hasHigherInternalRole,
  isInternalRoleAdmin,
} from "@shared/utils/internalUserRole";

export default function OrganizationListPage() {
  const history = usePolyfitHistory();
  const [searchParams] = useSearchParams();
  const communityId = searchParams.get("communityId");

  const { organizations } = useOrganizationsByCommunityId(communityId ?? "");
  const { loginUser } = useLoginUser();

  const handleEditOrganization = useCallback((organizationId: string) => {
    history.push({
      to: "INTERNAL_ORGANIZATION_EDIT",
      query: { organizationId },
    });
  }, []);

  return (
    <>
      {!isInternalRoleAdmin(loginUser?.internalRole) ? (
        <>
          <div>読み込み中…</div>
          <div>画面が切り替わらない場合はお問い合わせください</div>
        </>
      ) : (
        <>
          <h2>コミュニティに紐づく組織一覧</h2>{" "}
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({ to: "INTERNAL_COMMUNITY_LIST" });
            }}
          >
            コミュニティ一覧
          </a>{" "}
          <table>
            <tr>
              <td>組織ID</td>
              <td>組織名</td>
              <td>サービス名</td>
              <td>NameListPublished</td>
              <td>requireAdminToUpdateCareer</td>
              <td>所属</td>
              <td>役職</td>
              <td>作成日時</td>
              <td>更新日時</td>
              <td>操作</td>
            </tr>
            {organizations.map((organization, i) => {
              return (
                <tr
                  key={i}
                  style={i % 2 === 0 ? { backgroundColor: "white" } : undefined}
                >
                  <td>
                    {hasHigherInternalRole(
                      loginUser?.internalRole,
                      "INTERNAL_ADMIN"
                    ) ? (
                      <a
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push({
                            to: "INTERNAL_ORGANIZATION_USER_LIST",
                            query: { organizationId: organization.id },
                          });
                        }}
                      >
                        {organization.id}
                      </a>
                    ) : (
                      <>{organization.id}</>
                    )}
                  </td>
                  <td>
                    {hasHigherInternalRole(
                      loginUser?.internalRole,
                      "INTERNAL_ADMIN"
                    ) ? (
                      <a
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push({
                            to: "INTERNAL_ORGANIZATION_USER_LIST",
                            query: { organizationId: organization.id },
                          });
                        }}
                      >
                        {organization.name}
                      </a>
                    ) : (
                      <>{organization.name}</>
                    )}
                  </td>
                  <td>{`${organization.serviceType}`}</td>
                  <td>{`${organization.nameListPublished}`}</td>
                  <td>{`${organization.requireAdminToUpdateCareer}`}</td>
                  <td>
                    <div>
                      {organization.belongs.map((belong, i) => {
                        return <div key={i}>{belong.name}</div>;
                      })}
                    </div>
                  </td>
                  <td>
                    <div>
                      {organization.parts.map((part, i) => {
                        return <div key={i}>{part.name}</div>;
                      })}
                    </div>
                  </td>
                  <td>{organization.createdAt}</td>
                  <td>{organization.updatedAt}</td>
                  <td>
                    <TransitionLink
                      onClick={() => {
                        handleEditOrganization(organization.id);
                      }}
                    >
                      edit
                    </TransitionLink>
                  </td>
                </tr>
              );
            })}
          </table>
        </>
      )}
    </>
  );
}

const TransitionLink = styled.a`
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;
