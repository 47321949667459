import * as client from "./client";
import {
  linkCreateSchema,
  LinkCreateSchemaType,
  linkDeleteSchema,
  LinkDeleteSchemaType,
  linkUpdateSchema,
  LinkUpdateSchemaType,
} from "../@shared/validator/features/link.schema";
export type Link = {
  id?: string | null;
  title: string;
  url: string;
  description: string | null;
};

export async function createLink(
  arg: LinkCreateSchemaType["arg"]
): Promise<void> {
  linkCreateSchema.parse({ arg });
  await client.post<LinkCreateSchemaType, { result: string }>(
    "/link/createLink",
    { arg }
  );
}

export async function updateLink(
  arg: LinkUpdateSchemaType["arg"]
): Promise<void> {
  linkUpdateSchema.parse({ arg });
  await client.post<LinkUpdateSchemaType, { result: string }>(
    "/link/updateLink",
    { arg }
  );
}

export async function deleteLink(linkId: LinkDeleteSchemaType): Promise<void> {
  linkDeleteSchema.parse(linkId);
  await client.post<LinkDeleteSchemaType, { result: string }>(
    "/link/deleteLink",
    linkId
  );
}

export async function getOrganizationLinks(): Promise<Link[]> {
  const links = await client.get<{}, Link[]>("/link/getOrganizationLinks", {});

  return links;
}
