import React, { memo } from "react";
import { Button } from "../../../components/Button";
import { CheckIcon } from "../../../components/icons/CheckIcon";
import styled from "styled-components";
import { Control, useController } from "react-hook-form";
import { SurveyQuestions } from "@shared/validator/features/survey.schema";
import {
  createNewMultiChoiceQuestion,
  createNewOpenEndedQuestion,
} from "./useCreateSurvey";

type Props = {
  index: number;
  control: Control<SurveyQuestions>;
};

export const ToggleQuestionTypeField = memo(({ index, control }: Props) => {
  const {
    field: { value, onChange },
  } = useController({
    name: `questionList.${index}`,
    control,
  });

  const handleChangeToOpenEnded = () => {
    if (value.questionType === "MULTI_CHOICE") {
      if (
        "choiceList" in value &&
        value.choiceList.some((choice) => choice.text !== "") &&
        !confirm("選択肢が破棄されますがよろしいですか？")
      ) {
        return;
      }
      onChange(createNewOpenEndedQuestion(value.text, value.description));
    }
  };

  const handleChangeToMultiChoice = () => {
    if (value.questionType === "OPEN_ENDED") {
      onChange(createNewMultiChoiceQuestion(value.text, value.description));
    }
  };

  return (
    <Container>
      <StyledButton
        fill
        color={value.questionType === "MULTI_CHOICE" ? "primary" : "ghost"}
        onClick={handleChangeToMultiChoice}
      >
        {value.questionType === "MULTI_CHOICE" && <CheckIcon size={8} />}
        選択式
      </StyledButton>
      <StyledButton
        fill
        color={value.questionType === "OPEN_ENDED" ? "primary" : "ghost"}
        onClick={handleChangeToOpenEnded}
      >
        {value.questionType === "OPEN_ENDED" && <CheckIcon size={8} />}
        記述式
      </StyledButton>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledButton = styled(Button)`
  gap: 8px;
`;
