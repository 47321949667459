import React, { JSX } from "react";
import { MultiChoiceQuestionForm } from "./MultiChoiceQuestionForm";
import { OpenEndedQuestionForm } from "./OpenEndedQuestionForm";
import styled from "styled-components";
import {
  SurveyQuestions,
  type Question,
} from "@shared/validator/features/survey.schema";
import { Control, useController } from "react-hook-form";

// 各質問用component
//   この質問削除ボタン
//   質問種類決めるボタン（選択式、記述式）
//   質問記述欄
//   （選択式のみ）選択肢記述欄
//
// question: この質問のプロパティ一覧
// isDeletable: この質問の削除を許すかのフラグ
export function Question({
  index,
  control,
  onDelete,
  isDeletable,
}: {
  index: number;
  control: Control<SurveyQuestions>;
  onDelete: (index: number) => void;
  isDeletable: boolean;
}): JSX.Element {
  // 表示内容、質問の種類によって変わる
  const {
    field: { value },
  } = useController({
    name: `questionList.${index}`,
    control,
  });
  if (value.questionType === "MULTI_CHOICE") {
    return (
      <MultiChoiceQuestionForm
        index={index}
        control={control}
        onDelete={onDelete}
        isDeletable={isDeletable}
      />
    );
  }
  return (
    <OpenEndedQuestionForm
      index={index}
      control={control}
      onDelete={onDelete}
      isDeletable={isDeletable}
    />
  );
}

export const QuestionContainer = styled.div`
  /* box-style */
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(245, 247, 250, 1);
  /* flex-style */
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* other-style */
  position: relative;
`;

export const QuestionField = styled.div``;
