import React, { useCallback } from "react";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { ModalLabel } from "../../Post/CreatePostModal";
import { Label } from "../../../components/form/Label";
import { CheckBox } from "../../../components/form/CheckBox";
import { Recruitment } from "@shared/types/recruitment";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toDateIntervalFormat } from "src/utils/time";
import {
  recruitmentScheduleCloseBodySchema,
  RecruitmentScheduleCloseBodyType,
} from "@shared/validator/features/recruitment-schedule";
import { closeRecruitmentSchedule } from "src/apiClients/recruitmentSchedule";
import { useToast } from "src/components/Toast";
import { colorsPallet } from "src/theme";

type Props = {
  recruitment: Recruitment;
  onClose: () => void;
  refetch: () => void;
};

export const CloseRecruitmentScheduleModal = ({
  recruitment,
  onClose,
  refetch,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<RecruitmentScheduleCloseBodyType>({
    resolver: zodResolver(recruitmentScheduleCloseBodySchema),
    defaultValues: {
      recruitmentId: recruitment.id,
      recruitmentScheduleIds: recruitment.schedule
        .filter((s) => !s.closedAt)
        .map((s) => s.id),
    },
    mode: "onChange",
  });
  const toast = useToast();

  const isSingleSchedule = recruitment.schedule.length === 1;
  const selectedScheduleIds = watch("recruitmentScheduleIds");

  const handleScheduleChange = (scheduleId: string, checked: boolean) => {
    const newSelectedIds = checked
      ? [...selectedScheduleIds, scheduleId]
      : selectedScheduleIds.filter((id) => id !== scheduleId);
    setValue("recruitmentScheduleIds", newSelectedIds, {
      shouldValidate: true,
    });
  };

  const onSubmit = useCallback(async () => {
    try {
      await closeRecruitmentSchedule(recruitment.id, selectedScheduleIds);
      refetch();
      toast.success("募集を停止しました");
    } catch (e) {
      toast.error("募集の停止に失敗しました");
    } finally {
      onClose();
    }
  }, [selectedScheduleIds]);

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>募集を停止</p>
      </ModalHeader>
      <ModalBody>
        <p>
          「<strong>{recruitment.title}</strong>」の募集を停止しますか？
        </p>
        <Margin marginTop={15} />
        <ModalLabel>
          <Label required>日程</Label>
        </ModalLabel>
        <Margin marginTop={5} />
        <ScheduleCheckboxContainer>
          {recruitment.schedule.map((schedule) => (
            <CheckboxLabel key={schedule.id}>
              <CheckBox
                checked={selectedScheduleIds.includes(schedule.id ?? "")}
                onChange={(e) =>
                  handleScheduleChange(schedule.id ?? "", e.target.checked)
                }
                disabled={isSingleSchedule || schedule.status === "CLOSED"}
              />
              <ScheduleText
                $disabled={isSingleSchedule || schedule.status === "CLOSED"}
              >
                {toDateIntervalFormat(
                  new Date(schedule.start),
                  new Date(schedule.end)
                )}
                {schedule.status === "CLOSED" && (
                  <IsAlreadyClosedText> ※停止済み</IsAlreadyClosedText>
                )}
              </ScheduleText>
            </CheckboxLabel>
          ))}
        </ScheduleCheckboxContainer>
        {errors.recruitmentScheduleIds && (
          <ErrorMessage>{errors.recruitmentScheduleIds.message}</ErrorMessage>
        )}
      </ModalBody>
      <ModalSubmitButtons
        submitText="募集停止"
        submitColor="danger"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onClose}
        disabled={!isValid}
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const ScheduleCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ScheduleText = styled.span<{ $disabled: boolean }>`
  color: ${(props) => (props.$disabled ? "#69707d" : "inherit")};
`;

const IsAlreadyClosedText = styled.span`
  font-size: 12px;
  color: ${colorsPallet.danger};
`;
