import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import RecruitmentThumbnailImage from "./RecruitmentThumbnailImage";
import { WalkingIcon } from "../../../components/icons/WalkingIcon";
import { BikeIcon } from "../../../components/icons/BikeIcon";
import { CarIcon } from "../../../components/icons/CarIcon";
import { toDateIntervalFormat, toHourMinuteFormat } from "../../../utils/time";
import { getWageTypeText } from "../../../utils/types/wageType";
import { BREAKPOINTS } from "../../../components/Responsive";
import HtmlViewer from "../../../features/Post/HTMLViewer";
import GoogleMapArea from "../../../components/GoogleMapArea";
import ExternalLinkIcon from "../../../components/icons/ExternalLinkIcon";
import { Avatar } from "../../../components/Common/Avatar";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { useCurrentCommunityId } from "../../../hooks/router";
import { useCommunityById } from "src/hooks/recoil/community";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { Recruitment, RecruitmentOrigin } from "@shared/types/recruitment";
import { RecruitmentTemplate } from "@shared/types/recruitmentTemplate";
import { PdfIcon } from "src/components/icons/PdfIcon";
import { SelectedFileInfo } from "../../common/Recruitment/RecruitmentFormPDFPicker";
import { useGetDownloadUrls } from "src/hooks/query/firebaseStorage";
import { useGetCommunityIcon } from "src/hooks/query/communityIcon";
import { useOrganization } from "src/hooks/recoil/organization";
import { colorsPallet } from "src/theme";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { statusTextMap } from "@shared/types/appliedRecruitment";
import { hasNotAppliedSchedule } from "./utils/hasNotAppliedSchedule";
import { ApplicationAddingScheduleBodySchema } from "@shared/validator/features/recruitment-application.schema";
import { AddScheduleRecruitmentModal } from "./AddScheduleRecruitmentModal";
import { useToast } from "src/components/Toast";
import { useModal } from "src/components/Modal";
import { updateAddingScheduleToExistApplication } from "../../../apiClients/recruitmentApplication";
import { logger } from "src/utils/logger";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");
const defaultCommunityIcon = getStaticImageUrl("/defaultCommunityIcon.png");

type RecruitmentDetailCardProps = {
  recruitment: Recruitment;
  applicant?: RecruitmentApplication;
  refetchApplicant?: () => void;
  recruitmentPreview?: Recruitment | RecruitmentTemplate | null;
  isRecruitmentCreateNew?: boolean;
  selectedFileInfos?: SelectedFileInfo[];
};

export const RecruitmentDetailCard = ({
  recruitment,
  applicant,
  recruitmentPreview,
  refetchApplicant,
  isRecruitmentCreateNew = false,
  selectedFileInfos = undefined,
}: RecruitmentDetailCardProps) => {
  const { communityId } = useCurrentCommunityId();
  const [{ community }] = useCommunityById(communityId);
  const user = useCurrentUser();
  const { organization } = useOrganization({ skip: false });
  const [
    isShownAddScheduleModal,
    { show: showAddScheduleModal, close: closeAddScheduleModal },
  ] = useModal();
  const toast = useToast();

  const createdNameBy = useMemo(() => {
    return {
      title:
        recruitment.origin === RecruitmentOrigin.CS ? "校区" : "教育委員会",
      value:
        recruitment.origin === RecruitmentOrigin.CS
          ? recruitment.community?.name
          : recruitment?.boardEducationOrganizationName,
    };
  }, [recruitment]);
  const { urls } = useGetDownloadUrls(
    recruitment.recruitmentPDFs?.map((fileInfo) => fileInfo.path) || []
  );
  const { communityIcon, isLoading: isLoadingCommunityIcon } =
    useGetCommunityIcon(
      recruitmentPreview || isRecruitmentCreateNew
        ? communityId
        : recruitment.community?.id ?? "",
      recruitmentPreview || isRecruitmentCreateNew
        ? organization.id
        : recruitment.organizationId ?? ""
    );

  const onOpenAddScheduleModal = useCallback(() => {
    showAddScheduleModal();
  }, [showAddScheduleModal]);

  const onCloseAddScheduleModal = useCallback(() => {
    closeAddScheduleModal();
  }, [closeAddScheduleModal]);

  const onSubmitAddSchedule = useCallback(
    async (values: ApplicationAddingScheduleBodySchema) => {
      try {
        await updateAddingScheduleToExistApplication({
          recruitmentId: values.recruitmentId,
          recruitmentApplicationId: values.recruitmentApplicationId,
          selectedScheduleIds: values.selectedScheduleIds,
          comment: values.comment,
        });
        toast.success("応募日程を追加しました");
        if (refetchApplicant) {
          await refetchApplicant();
        }
        onCloseAddScheduleModal();
      } catch (error) {
        toast.error("応募日程の追加に失敗しました");
        logger.error(error, { values, applicant, recruitment });
      }
      close();
    },
    [recruitment.id, applicant?.id, close, toast, refetchApplicant, logger]
  );

  return (
    <>
      <Card>
        <RecruitmentThumbnailImage
          src={recruitment.picture}
          title={recruitment.title}
        />
        <CardBody>
          <TagWrapper>
            <Tag color="blue">{recruitment.volunteerType}</Tag>

            <Tag color="blue">{recruitment.volunteerDetailType}</Tag>

            <Tag color="green">
              {recruitment.isPaidVolunteer ? "有償" : "無償"}
            </Tag>

            <Tag color="pink">単発</Tag>

            {recruitment.walkMinute !== undefined &&
              recruitment.walkMinute !== null &&
              recruitment.bikeMinute !== undefined &&
              recruitment.bikeMinute !== null &&
              recruitment.carMinute !== undefined &&
              recruitment.carMinute !== null && (
                <>
                  <Tag color="orange">
                    <WalkingIcon />
                    {toHourMinuteFormat(recruitment.walkMinute)}
                  </Tag>
                  <Tag color="orange">
                    <BikeIcon />
                    {toHourMinuteFormat(recruitment.bikeMinute)}
                  </Tag>
                  <Tag color="orange">
                    <CarIcon />
                    {toHourMinuteFormat(recruitment.carMinute)}
                  </Tag>
                </>
              )}
          </TagWrapper>

          <ContentsWrapper>
            <Title>{recruitment.title}</Title>
            {/* 新規募集作成のプレビュー */}
            {isRecruitmentCreateNew ? (
              <>
                <UserPicCell>
                  <PreUserPicCell>
                    <Avatar
                      src={user?.picture || defaultUserImg}
                      alt={user?.name || "作成者"}
                      size={30}
                    />
                  </PreUserPicCell>
                  <PreUserNameCell>
                    作成者
                    <Name>{user.name}</Name>
                  </PreUserNameCell>
                </UserPicCell>

                <UserPicCell>
                  <PreUserPicCell>
                    {!isLoadingCommunityIcon ? (
                      <Avatar
                        src={communityIcon?.iconUrl || defaultCommunityIcon}
                        alt={community?.name || "校区"}
                        size={30}
                      />
                    ) : (
                      <HiddenAvater>
                        <Avatar
                          src={defaultCommunityIcon}
                          alt="校区"
                          size={30}
                        />
                      </HiddenAvater>
                    )}
                  </PreUserPicCell>
                  <PreUserNameCell>
                    校区
                    <Name>{community?.name}</Name>
                  </PreUserNameCell>
                </UserPicCell>
              </>
            ) : (
              <>
                <UserPicCell>
                  <PreUserPicCell>
                    <Avatar
                      src={recruitment.account?.user.picture || defaultUserImg}
                      alt={recruitment.account?.user.name || "作成者"}
                      size={30}
                    />
                  </PreUserPicCell>
                  <PreUserNameCell>
                    作成者
                    <Name>{recruitment.account?.user.name}</Name>
                  </PreUserNameCell>
                </UserPicCell>

                <UserPicCell>
                  <PreUserPicCell>
                    {!isLoadingCommunityIcon ? (
                      <Avatar
                        src={communityIcon?.iconUrl || defaultCommunityIcon}
                        alt={community?.name || "校区"}
                        size={30}
                      />
                    ) : (
                      <HiddenAvater>
                        <Avatar
                          src={defaultCommunityIcon}
                          alt="校区"
                          size={30}
                        />
                      </HiddenAvater>
                    )}
                  </PreUserPicCell>
                  <PreUserNameCell>
                    {createdNameBy.title}
                    <Name>{createdNameBy.value}</Name>
                  </PreUserNameCell>
                </UserPicCell>
              </>
            )}
            <Content>
              <SubTitle>活動内容</SubTitle>
              <Text>
                <HtmlViewer htmlContent={recruitment.description} />
              </Text>
            </Content>
            {recruitment.recruitmentPDFs &&
              recruitment.recruitmentPDFs.length > 0 && (
                <Content>
                  <SubTitle>添付資料</SubTitle>
                  <PdfFiles>
                    {recruitment.recruitmentPDFs.map((fileInfo, index) => {
                      return (
                        <div key={index}>
                          <PdfPreview>
                            <PdfIconWrapper>
                              <PdfIcon size={24} />
                            </PdfIconWrapper>
                            <PdfLabel target="blank" href={urls[index]}>
                              {fileInfo.name}
                            </PdfLabel>
                          </PdfPreview>
                        </div>
                      );
                    })}
                  </PdfFiles>
                </Content>
              )}
            {/* 作成時のプレビュー用 */}
            {selectedFileInfos && selectedFileInfos.length > 0 && (
              <Content>
                <SubTitle>添付資料</SubTitle>
                <PdfFiles>
                  {selectedFileInfos.map((fileInfo, index) => {
                    return (
                      <div key={index}>
                        <PdfPreview>
                          <PdfIconWrapper>
                            <PdfIcon size={24} />
                          </PdfIconWrapper>
                          <PdfLabel target="blank" href={fileInfo.path}>
                            {fileInfo.name}
                          </PdfLabel>
                        </PdfPreview>
                      </div>
                    );
                  })}
                </PdfFiles>
              </Content>
            )}
            <Content>
              <SubTitle>募集カテゴリー</SubTitle>
              <Text>
                {recruitment?.targetCommunities.map((targetCommunity) => (
                  <Ul key={targetCommunity.communityId}>
                    <li>{targetCommunity.community?.name}</li>
                    <Ul>
                      {targetCommunity.communityUserTypes?.includes(
                        "PARENT"
                      ) && <li>保護者</li>}
                      {targetCommunity.communityUserTypes?.includes(
                        "TEACHER"
                      ) && <li>教職員</li>}
                      {targetCommunity.communityUserTypes?.includes(
                        "INSIDE_RESIDENT"
                      ) && <li>地域住民（校区内）</li>}
                      {targetCommunity.communityUserTypes?.includes(
                        "OUTSIDE_RESIDENT"
                      ) && <li>地域住民（校区外）</li>}
                      {targetCommunity.communityUserTypes?.includes(
                        "ADMIN"
                      ) && <li>管理者</li>}
                    </Ul>
                  </Ul>
                ))}
              </Text>
            </Content>

            <Content>
              <SubTitle>場所</SubTitle>
              <a
                href={`https://www.google.com/maps?q=${recruitment.latitude},${recruitment.longitude}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Text>
                  〒{recruitment.postalCode?.slice(0, 3)}-
                  {recruitment.postalCode?.slice(3)}
                </Text>
                <Address>
                  {recruitment.prefecture}
                  {recruitment.city}
                  {recruitment.address1}
                  {recruitment.address2}
                  <ExternalLinkIconWrapper>
                    <ExternalLinkIcon size={12} />
                  </ExternalLinkIconWrapper>
                </Address>
              </a>
              <GoogleMapWithLocationWrapper>
                <GoogleMapArea
                  lat={recruitment.latitude ?? 35.6809591}
                  lng={recruitment.longitude ?? 139.7673068}
                />
              </GoogleMapWithLocationWrapper>
            </Content>

            <Content>
              <ScheduleContainer>
                <SubTitle>時間・曜日</SubTitle>
                {applicant &&
                  hasNotAppliedSchedule(recruitment.schedule, applicant) && (
                    <AddScheduleButton onClick={onOpenAddScheduleModal}>
                      応募日程を追加する
                    </AddScheduleButton>
                  )}
              </ScheduleContainer>
              <div>
                {recruitment.schedule.map((schedule, i) => {
                  const appliedEventStatus = applicant?.eventStatuses?.find(
                    (eventStatus) =>
                      eventStatus.recruitmentSchedule.id === schedule.id
                  );

                  return (
                    <Text key={i}>
                      {applicant ? (
                        <span>
                          {appliedEventStatus
                            ? `[${
                                statusTextMap[
                                  appliedEventStatus.selectionStatus
                                ]
                              }] `
                            : "[未応募] "}
                        </span>
                      ) : null}
                      {toDateIntervalFormat(
                        new Date(schedule.start),
                        new Date(schedule.end)
                      )}
                      {schedule.status === "CLOSED" && (
                        <IsAlreadyClosedText> ※募集停止</IsAlreadyClosedText>
                      )}
                    </Text>
                  );
                })}
              </div>
            </Content>

            {recruitment.isPaidVolunteer && (
              <Content>
                <SubTitle>給与</SubTitle>
                <Text>
                  {`${getWageTypeText(recruitment.wageType)}${
                    recruitment.wageAmount
                  }円`}
                </Text>
              </Content>
            )}

            {recruitment.volunteerCount && (
              <Content>
                <SubTitle>募集人数</SubTitle>
                <Text>
                  {recruitment.volunteerCount === -1
                    ? "無制限"
                    : `${recruitment.volunteerCount}人`}
                </Text>
              </Content>
            )}

            <Content>
              <SubTitle>待遇</SubTitle>
              <Text>{recruitment.treatment}</Text>
            </Content>

            <Content>
              <SubTitle>応募資格</SubTitle>
              <Text>
                <Ul>
                  {[
                    ...recruitment.teacherLicenses,
                    ...recruitment.medicalLicenses,
                    ...recruitment.skills,
                  ].map((license, i) => (
                    <li key={i}>{license}</li>
                  ))}
                </Ul>
              </Text>
              {[
                ...recruitment.teacherLicenses,
                ...recruitment.medicalLicenses,
                ...recruitment.skills,
              ].length === 0 && <Text>なし</Text>}
            </Content>
            <Content>
              <SubTitle>応募後の流れ</SubTitle>
              <Text>
                {recruitment.entryMethod === "AUTO_APPROVAL"
                  ? "自動承認"
                  : "管理者による確認"}
              </Text>
            </Content>
          </ContentsWrapper>
        </CardBody>
      </Card>
      {isShownAddScheduleModal && applicant && (
        <AddScheduleRecruitmentModal
          recruitment={recruitment}
          applicant={applicant}
          onSubmit={onSubmitAddSchedule}
          onClose={onCloseAddScheduleModal}
        />
      )}
    </>
  );
};

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const CardBody = styled.div`
  padding: 24px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    padding: 12px;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 8px;
  margin-bottom: 12px;
`;

const Tag = styled.p<{
  color: "blue" | "green" | "pink" | "orange";
}>`
  background-color: ${(props) => {
    switch (props.color) {
      case "blue":
        return "#79aad9";
      case "green":
        return "#6DCCB1";
      case "pink":
        return "#EE789D";
      case "orange":
        return "#FF7E62";
      default:
        return "#79aad9";
    }
  }};
  padding: 4px 12px;
  border-radius: 16px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const Title = styled.p`
  color: #343741;
  font-size: 24px;
  font-weight: bold;
  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 16px;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    gap: 12px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    gap: 2px;
  }
`;

const SubTitle = styled.p`
  color: #343741;
  font-size: 20px;
  font-weight: bold;
  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 12px;
  }
`;

const Text = styled.p`
  color: #343741;
  white-space: pre-wrap;
  font-size: 14px;
`;

const Ul = styled.ul`
  padding-left: 18px;
`;

const GoogleMapWithLocationWrapper = styled.div`
  width: 100%;
  height: 320px;
`;

const Address = styled(Text)`
  position: relative;
`;

const ExternalLinkIconWrapper = styled.span`
  position: relative;
  & > svg {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
  }
`;

const UserPicCell = styled.th`
  color: #343741;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const HiddenAvater = styled.div`
  visibility: hidden;
`;

const PreUserPicCell = styled.div`
  padding: 10px;
`;

const PreUserNameCell = styled.div`
  text-align: left;
`;

const PdfFiles = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
`;
const PdfLabel = styled.a`
  font-size: 14px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    text-decoration: underline;
  }
`;

const PdfIconWrapper = styled.div`
  flex-shrink: 0;
`;
const PdfPreview = styled.div`
  max-width: 208px;
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  margin-top: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

const IsAlreadyClosedText = styled.span`
  font-size: 12px;
  color: ${colorsPallet.danger};
`;

const ScheduleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;

  @media (max-width: ${BREAKPOINTS.SP}) {
    gap: 2px;
  }
`;

const AddScheduleButton = styled.button`
  font-size: 14px;
  color: #0088ee;
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 12px;
  }
`;
