import { ChildrenFilterType } from "../hooks/api/child";
import * as client from "./client";
import { User } from "./users";

export type ChildGrade = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type ChildClass =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 100
  | -1;

export type Child = {
  id?: string;
  userId?: string;
  organizationId?: string;
  childLastName: string;
  childFirstName: string;
  childLastNameKana: string;
  childFirstNameKana: string;
  grade?: ChildGrade;
  class?: ChildClass;
  user?: User;
  accountId?: string;
};

export async function currentUserChildren() {
  return await client.get<{}, Child[]>("/child/currentUserChildren", {});
}

export async function getChildrenByCondition(
  q?: string,
  filter?: ChildrenFilterType
) {
  const filterQuery = JSON.stringify(filter);
  const children = await client.get<{ q?: string; filter?: string }, Child[]>(
    "/child/getChildrenByCondition",
    { q, filter: filterQuery }
  );
  return children;
}

export const getChildren = async (userId: string) => {
  const children = await client.get<{ userId: string }, Child[]>(
    "/child/getChildren",
    { userId }
  );
  return children;
};

export async function getDisabledUsersChildrenByOrganizationId(
  q?: string,
  filter?: ChildrenFilterType
) {
  const filterQuery = JSON.stringify(filter);
  const children = await client.get<{ q?: string; filter?: string }, Child[]>(
    "/child/getDisabledUsersChildrenByCondition",
    { q, filter: filterQuery }
  );
  return children;
}

export async function upsertAndDeleteChildren(userId: string, data: Child[]) {
  return await client.post<{ userId: string; data: Child[] }, Child[]>(
    "/child/upsertAndDeleteChildren",
    { userId, data }
  );
}

export async function getChildrenCountsByGrade() {
  return await client.get<{}, { grade: number; _count: number }[]>(
    "/child/getChildrenCountsByGrade",
    {}
  );
}
