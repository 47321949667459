import { z } from "zod";
import { zRequiredString } from "../rules/string";

export const belongSchema = z.object({
  id: zRequiredString,
  organizationId: zRequiredString,
  name: zRequiredString,
  createdAt: z.date(),
});

export type BelongSchemaType = z.infer<typeof belongSchema>;
