import React from "react";
import { useGetChildrenCountsByGrade } from "src/hooks/query/childrenList";
import { useGetGradeSizes } from "src/hooks/query/gradeSize";
import { useOrganization } from "src/hooks/recoil/organization";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import ChartList from "./ChartList";
import styled from "styled-components";
import { BREAKPOINTS } from "src/components/Responsive";
import SelectChart from "./SelectChart";

export default function GradeSizeChart() {
  const { organization } = useOrganization({ skip: false });
  const schoolType = organization?.schoolType;
  const schoolDisplayType = organization?.schoolDisplayType;
  const grades = useGradeOptions(schoolType);
  const { gradeSizes } = useGetGradeSizes();
  const { childrenCountsByGrade } = useGetChildrenCountsByGrade();

  return (
    <GradeSizeChartSection>
      <PCView>
        <ChartList
          childrenCountsByGrade={childrenCountsByGrade}
          gradeSizes={gradeSizes}
          grades={grades}
          schoolDisplayType={schoolDisplayType}
        />
      </PCView>
      <MobileView>
        <SelectChart
          childrenCountsByGrade={childrenCountsByGrade}
          gradeSizes={gradeSizes}
          grades={grades}
          schoolDisplayType={schoolDisplayType}
        />
      </MobileView>
    </GradeSizeChartSection>
  );
}

const GradeSizeChartSection = styled.div`
  margin-top: 12px;
  padding-bottom: 12px;
`;
const PCView = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: block;
  }
`;
const MobileView = styled.div`
  display: block;
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;
