import React, { memo } from "react";

type Props = {
  size: number;
};

export const ArrowLeftIcon = memo(({ size = 16 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6402 5.2318C15.0645 5.58537 15.1218 6.21593 14.7682 6.64021L10.3017 12L14.7682 17.3598C15.1218 17.7841 15.0645 18.4147 14.6402 18.7682C14.2159 19.1218 13.5853 19.0645 13.2318 18.6402L8.23178 12.6402C7.92274 12.2694 7.92274 11.7307 8.23178 11.3598L13.2318 5.35984C13.5853 4.93556 14.2159 4.87824 14.6402 5.2318Z"
        fill="#37434F"
      />
    </svg>
  );
});
