import React, { useState } from "react";
import { User } from "../../apiClients/users";
import { usePolyfitHistory } from "../../hooks/router";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../components/Modal";
import { Heading3 } from "../../components/Typo";
import styled from "styled-components";
import { updateRole } from "../../apiClients/auth";
import { csRoleOptions, ptaRoleOptions } from "../../utils/types/role";
import { useInvalidateGetChildrenByCondition } from "src/hooks/query/childrenList";
import { useInvalidateGetResidentUsers } from "src/hooks/query/residentUsers";
import { logger } from "src/utils/logger";

type EditRoleModalProps = {
  user: User;
  onClose: () => void;
  getUser: () => Promise<void>;
  isLastAdminUser: boolean;
  isLastCSAdminUser: boolean;
};

export const EditRoleModal = ({
  user,
  onClose,
  getUser,
  isLastAdminUser,
  isLastCSAdminUser,
}: EditRoleModalProps) => {
  const [role, setRole] = useState(user.role);
  const history = usePolyfitHistory();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const { invalidateGetChildrenByCondition } =
    useInvalidateGetChildrenByCondition();
  const { invalidateGetResidentUsers } = useInvalidateGetResidentUsers();
  const onSubmit = async () => {
    setDoubleClickBlocked(true);
    try {
      await updateRole({ userId: user.id, role });
      invalidateGetChildrenByCondition();
      invalidateGetResidentUsers();
      setRole(role);
      await getUser();
      history.push({
        to: "PTA_LIST_MEMBER",
        query: {
          userId: user.id,
        },
      });
      setDoubleClickBlocked(false);
      onClose();
    } catch (err) {
      setDoubleClickBlocked(false);
      logger.error(err);
    }
  };

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>権限を変更</ModalHeader>
      <ModalBody>
        {isLastAdminUser ? (
          <Message>
            管理者が不在の場合、管理者を新規で増やすことができません。
            <br />
            以後、新規招待や退会処理ができなくなってしまいます。
            <br />
            他の方を管理者権限に移行してください。
          </Message>
        ) : isLastCSAdminUser ? (
          <Message>
            CS管理者が不在の場合、CS管理者を新規で増やすことができません。
            <br />
            他の方を管理者権限に移行してください。
          </Message>
        ) : (
          <>
            {(user.type === "PARENT" || user.type === "TEACHER") && (
              <>
                <Message>
                  {user.name}
                  さんの権限を選択できます。
                </Message>
                {ptaRoleOptions.map((role) => {
                  return (
                    <div key={role.value}>
                      <input
                        type="radio"
                        value={role.value}
                        name="role"
                        defaultChecked={role.value === user.role}
                        onChange={() => setRole(role.value)}
                      />{" "}
                      <Role>{role.text}</Role>
                      <DescriptionList>
                        {role.descriptions.map((description) => (
                          <Description key={description}>
                            {description}
                          </Description>
                        ))}
                      </DescriptionList>
                    </div>
                  );
                })}
              </>
            )}{" "}
            {user.type === "RESIDENT" && (
              <>
                <Message>
                  {user.name}
                  さんの権限を選択できます。
                </Message>
                {csRoleOptions.map((role) => {
                  return (
                    <div key={role.value}>
                      <input
                        type="radio"
                        value={role.value}
                        name="role"
                        defaultChecked={role.value === user.role}
                        onChange={() => setRole(role.value)}
                      />{" "}
                      <Role>{role.text}</Role>
                      <DescriptionList>
                        {role.descriptions.map((description) => (
                          <Description key={description}>
                            {description}
                          </Description>
                        ))}
                      </DescriptionList>
                    </div>
                  );
                })}
              </>
            )}
            <ModalSubmitButtons
              disabled={user?.role === role || doubleClickBlocked}
              submitText="保存"
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          </>
        )}
      </ModalBody>
    </ModalPortal>
  );
};

const ModalHeader = styled(Heading3)`
  display: flex;
`;

const Message = styled.div`
  padding: 16px 0;
  max-width: 432px;
`;

const Role = styled.span`
  font-weight: bold;
`;

const DescriptionList = styled.ul`
  padding: 2px 4px 8px 24px;
`;

const Description = styled.li`
  font-size: 14px;
`;
