import { z } from "zod";

export const gradeSizeSchema = z.object({
  grade: z.number().int(),
  size: z
    .number()
    .min(0, { message: "0以上の数値を入力してください" })
    .max(9999, { message: "人数が多すぎます" })
    .int()
    .optional(),
});

export const createOrUpdateGradeSizeSchema = z.object({
  gradeSizes: z.array(gradeSizeSchema),
});

export type GradeSize = z.infer<typeof gradeSizeSchema>;
export type CreateOrUpdateGradeSizeSchemaType = z.infer<
  typeof createOrUpdateGradeSizeSchema
>;
