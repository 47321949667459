import React from "react";
import styled from "styled-components";
import { ArrowLeftIcon } from "../icons/ArrowLeftIcon";
import { ArrowRightIcon } from "../icons/ArrowRightIcon";
import { colorsPallet } from "src/theme";

type Props = {
  activePage: number;
  setActivePage: (page: number) => void;
  totalCount: number;
  take: number; // 表示件数
};

export const Pagination = ({
  activePage,
  setActivePage,
  totalCount,
  take,
}: Props) => {
  const totalPage = Math.ceil(totalCount / take); // 総ページ数: 総件数 / 表示件数
  const viewPageRange = 5; // 表示ページ数
  const activePageRange = 2; // アクティブページの前後表示ページ

  const handlePrevPage = () => {
    setActivePage(activePage - 1);
  };

  const handleNextPage = () => {
    setActivePage(activePage + 1);
  };

  const handlePageClick = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  return (
    <PaginationContainer>
      <ArrowButton
        onClick={handlePrevPage}
        disabled={activePage === 1}
        $disabled={activePage === 1}
      >
        <ArrowLeftIcon size={16} />
      </ArrowButton>
      {Array.from({ length: totalPage }, (_, i) => i + 1).map((pageNumber) => {
        const isWithinActiveRange =
          pageNumber >= activePage - activePageRange &&
          pageNumber <= activePage + activePageRange;
        const isAtStartRange =
          activePage <= activePageRange && pageNumber <= viewPageRange;
        const isAtEndRange =
          activePage > totalPage - activePageRange &&
          pageNumber > totalPage - viewPageRange;

        if (isWithinActiveRange || isAtStartRange || isAtEndRange) {
          return (
            <NumberButton
              key={pageNumber}
              onClick={() => handlePageClick(pageNumber)}
              $active={activePage === pageNumber}
            >
              {pageNumber}
            </NumberButton>
          );
        }
        return null;
      })}
      <ArrowButton
        onClick={handleNextPage}
        disabled={activePage === totalPage}
        $disabled={activePage === totalPage}
      >
        <ArrowRightIcon size={16} />
      </ArrowButton>
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-top: 24px;
  padding-bottom: 40px;
  gap: 36px;
`;

const ArrowButton = styled.button<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
`;

const NumberButton = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $active }) => ($active ? "default" : "pointer")};
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  font-size: ${({ $active }) => ($active ? "16px" : "14px")};
  color: ${({ $active }) => ($active ? "#000" : colorsPallet.primary)};
  &:hover {
    text-decoration: ${({ $active }) => ($active ? "none" : "underline")};
  }
`;
