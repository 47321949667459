import { ApplicationCountsForFilterType } from "@shared/validator/features/recruitment-application.schema";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getApplicationCountsForFilter } from "src/apiClients/recruitmentApplication";
import { QueryOptions } from "src/hooks/query/QueryOptions";

export const useRecruitmentApplicationCountsForFilter = (
  {
    recruitmentId,
    recruitmentScheduleId,
    name,
    status,
  }: ApplicationCountsForFilterType,
  queryOptions: QueryOptions = {}
) => {
  const query = useQuery({
    queryKey: [
      "api",
      "recruitment",
      "application",
      recruitmentId,
      recruitmentScheduleId,
      name,
      status,
    ],
    queryFn: async () =>
      await getApplicationCountsForFilter({
        recruitmentId,
        recruitmentScheduleId,
        name,
        status,
      }),
    ...queryOptions,
  });

  return {
    applicationUserTypeCounts: useMemo(
      () => query.data?.userTypeCounts || [],
      [query.data]
    ),
    applicationStatusCount: useMemo(
      () => query.data?.statusCounts || [],
      [query.data]
    ),
    ...query,
  };
};
