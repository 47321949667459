// 「アンケートを確認する」ボタンを表示するかどうかを判定する

import { User } from "../../../apiClients/users";
import { PostType } from "../../../hooks/api/post";
import { isSurveyDefined } from "../../../apiClients/surveyQuestions";
import { isPtaAdminRole } from "../../../utils/types/role";
import { SurveyQuestions } from "@shared/validator/features/survey.schema";

export function shouldShowCheckSurvey(
  user: User,
  post: PostType,
  surveyQuestions: SurveyQuestions | undefined
): boolean {
  // アンケートが定義されていないときは表示しない
  if (!isSurveyDefined(surveyQuestions)) {
    return false;
  }
  // undefinedの可能性がないことをTSCが見抜いてくれないので再度弾く
  if (surveyQuestions === undefined) {
    return false;
  }
  // 管理者であれば表示する
  if (isPtaAdminRole(user.role)) {
    return true;
  }
  // 投稿者本人にも表示する
  if (user.id === surveyQuestions.userId) {
    return true;
  }
  return false;
}
