import { VolunteerType } from "./volunteerType";
import { RecruitmentAccount } from "./auth";
import { Community } from "./community";

export type AlreadyUploadedPdfFile = {
  name: string;
  path: string;
};

export type RecruitmentPDF = {
  name: string;
  path: string;
};
/**
 * 募集系APIクライアント
 */
export type Recruitment = {
  id: string;
  communityId: string;
  organizationId: string | null;
  boardEducationOrganizationName?: string | null;
  status: "DRAFT" | "OPENED" | "CLOSED";
  picture: string | null;
  pictureUploadPath: string | null;
  title: string;
  isPaidVolunteer: boolean | null;
  volunteerType: VolunteerType | null;
  volunteerDetailType: string;
  volunteerCount: number;
  description: string;
  schedule: {
    id?: string;
    date: Date;
    start: Date;
    end: Date;
    status?: "OPENED" | "CLOSED" | "DELETED";
    closedAt?: Date | null;
  }[];
  postalCode: string | null;
  prefecture: string | null;
  city: string | null;
  address1: string | null;
  address2: string | null;
  latitude: number | null;
  longitude: number | null;
  wageType: "HOURLY" | "DAILY" | "MONTHLY" | null;
  wageAmount: number | null;
  treatment: string | null;
  teacherLicenses: string[];
  medicalLicenses: string[];
  skills: string[];
  targetCommunities: RecruitmentTargetCommunity[];
  saveTemplate: boolean;
  walkMinute: number | null;
  bikeMinute: number | null;
  carMinute: number | null;
  createdBy?: string;
  createdAt?: string;
  account?: RecruitmentAccount;
  community?: Community;
  deletedAt?: Date;
  origin: RecruitmentOrigin;
  recruitmentPDFs?: RecruitmentPDF[];
  entryMethod: RecruitmentEntryMethod;
};

export type RecruitmentListResponse = {
  recruitments: Recruitment[];
  totalCount: number;
  hasNextPage: boolean;
};

export type RecruitmentTargetCommunity = {
  communityId: string;
  community?: {
    name: string;
  };
  communityUserTypes: RecruitmentCommunityUserType[];
};

export const RecruitmentOrigin = {
  CS: "CS",
  BOARD_EDUCATION: "BOARD_EDUCATION",
} as const;
export type RecruitmentOrigin = "CS" | "BOARD_EDUCATION";

export const recruitmentEntryMethod = {
  AUTO_APPROVAL: "AUTO_APPROVAL",
  MANUAL_SCREENING: "MANUAL_SCREENING",
} as const;
export type RecruitmentEntryMethod = "AUTO_APPROVAL" | "MANUAL_SCREENING";

export const RecruitmentCommunityUserType = {
  PARENT: "PARENT",
  TEACHER: "TEACHER",
  INSIDE_RESIDENT: "INSIDE_RESIDENT",
  OUTSIDE_RESIDENT: "OUTSIDE_RESIDENT",
  ADMIN: "ADMIN",
} as const;

export const RecruitmentCommunityUserTypes = Object.values(
  RecruitmentCommunityUserType
);

export type RecruitmentCommunityUserType =
  (typeof RecruitmentCommunityUserType)[keyof typeof RecruitmentCommunityUserType];

export type RecruitmentStatus = "DRAFT" | "OPENED" | "CLOSED" | "DELETED";

/**
 * request parameter
 */
export type RecruitmentCreateArgsType = {
  communityId: string;
  status: "DRAFT" | "OPENED" | "CLOSED";
  pictureUploadPath: string | null;
  title: string | null;
  isPaidVolunteer: boolean | null;
  volunteerType: string | null;
  volunteerDetailType: string | null;
  volunteerCount: number | null;
  description: string | null;
  postalCode: string | null;
  prefecture: string | null;
  city: string | null;
  address1: string | null;
  address2: string | null;
  latitude: number | null;
  longitude: number | null;
  wageType: "HOURLY" | "DAILY" | "MONTHLY" | null;
  wageAmount: number | null;
  treatment: string | null;
  teacherLicenses: string[];
  medicalLicenses: string[];
  skills: string[];
  targetCommunities: RecruitmentTargetCommunity[];
  schedule: {
    date: Date | null;
    start: Date | null;
    end: Date | null;
  }[];
  saveTemplate: boolean;
  alreadyUploadedPdfFiles: AlreadyUploadedPdfFile[];
  entryMethod: RecruitmentEntryMethod;
};

export type RecruitmentUpdateArgsType = {
  id: string;
  createdBy?: string;
  communityId: string;
  status: "DRAFT" | "OPENED" | "CLOSED";
  pictureUploadPath: string | null;
  title: string | null;
  isPaidVolunteer: boolean | null;
  volunteerType: string | null;
  volunteerDetailType: string | null;
  volunteerCount: number | null;
  description: string | null;
  postalCode: string | null;
  prefecture: string | null;
  city: string | null;
  address1: string | null;
  address2: string | null;
  longitude: number | null;
  latitude: number | null;
  wageType: "HOURLY" | "DAILY" | "MONTHLY" | null;
  wageAmount: number | null;
  treatment: string | null;
  teacherLicenses: string[];
  medicalLicenses: string[];
  skills: string[];
  targetCommunities: RecruitmentTargetCommunity[];
  schedule: {
    id?: string;
    date: Date | null;
    start: Date | null;
    end: Date | null;
  }[];
  saveTemplate: boolean;
  alreadyUploadedPdfFiles: AlreadyUploadedPdfFile[];
  entryMethod: RecruitmentEntryMethod;
};
