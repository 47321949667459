import React from "react";
import {
  Path,
  Controller,
  ControllerProps,
  FieldValues,
  FieldPath,
  UseFormTrigger,
} from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { toInputDateFormatHourMinute } from "../../utils/time";
import styled from "styled-components";

type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  errorMessage?: string | undefined;
  trigger?: UseFormTrigger<TFieldValues>;
  triggeredValue?: Path<TFieldValues> | Path<TFieldValues>[];
};

export const TimePicker = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      render={({ field: { onBlur, onChange, value } }) => (
        <DateInputWrapper>
          <ReactDatePicker
            dateFormat="HH:mm"
            onChange={(date) => {
              onChange(toInputDateFormatHourMinute(date ?? undefined));
              props.trigger?.(props.triggeredValue);
            }}
            onBlur={onBlur}
            selected={value ? parseTimeStringToDate(value) : undefined}
            placeholderText="09:00"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="時間"
            locale="ja"
          />
          {props.errorMessage && (
            <span className="error-message">{props.errorMessage}</span>
          )}
        </DateInputWrapper>
      )}
      {...props}
    />
  );
};

const parseTimeStringToDate = (time: string) => {
  const [hour, minute] = time.split(":");
  const date = new Date();
  date.setHours(Number(hour));
  date.setMinutes(Number(minute));
  return date;
};

const DateInputWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
`;
