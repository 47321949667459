import React, { useCallback, useMemo, useState } from "react";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import styled from "styled-components";
import { useModal } from "../../../components/Modal";
import {
  applyRecruitment,
  closeRecruitment,
  deleteOpenRecruitment,
  recruitmentIsOpened,
  resendRecruitment,
} from "../../../apiClients/recruitment";
import { useToast } from "../../../components/Toast";
import { useRecruitmentDetail } from "src/hooks/query/recruitments/recruitmentDetail";
import { BreadCrumb } from "../../../components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import ApplyRecruitmentModal from "../../../features/Recruitment/recruitment/ApplyRecruitmentModal";
import { useCurrentUser } from "../../../hooks/recoil/user";
import CloseRecruitmentModal from "../../../features/Recruitment/recruitment/CloseRecruitmentModal";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import DeleteOpenRecruitmentModal from "../../../features/Recruitment/recruitment/DeleteOpenRecruitmentModal";
import { RecruitmentDetailCard } from "../../../features/Recruitment/recruitment/RecruitmentDetailCard";
import { useApplicantByRecruitmentId } from "../../../hooks/api/recruitmentApplication";
import { hasHigherRole, isCsAdminRole } from "src/utils/types/role";
import { RecruitmentOrigin } from "@shared/types/recruitment";
import { ResendRecruitmentModal } from "src/features/Recruitment/recruitment/ResendRecruitmentModal";
import { RecruitmentApplyBodyType } from "@shared/validator/features/recruitment.schema";
import { logger } from "src/utils/logger";
import { CloseRecruitmentScheduleModal } from "src/features/Recruitment/recruitment/CloseRecruitmentScheduleModal";
import { getApplicationStatus } from "src/features/common/Recruitment/application/utils/getApplicationStatus";

export default function ResidentRecruitmentDetailPage() {
  const { id: recruitmentId } = usePolyfitLocationQuery(
    "RESIDENT_RECRUITMENT_DETAIL",
    { id: "" }
  );
  const history = usePolyfitHistory();
  const { communityId } = useCurrentCommunityId();

  const [isApplyModal, setIsApplyModal] = useState<boolean>(false);
  const [, { show, close }] = useModal();
  const toast = useToast();

  const { recruitment, isLoading, refetch, isError, error } =
    useRecruitmentDetail(recruitmentId);
  const currentUser = useCurrentUser();
  const [isCloseRecruitment, setIsCloseRecruitment] = useState<boolean>(false);
  const [isResendRecruitment, setIsResendRecruitment] =
    useState<boolean>(false);
  const [isCloseRecruitmentSchedule, setIsCloseRecruitmentSchedule] =
    useState<boolean>(false);
  const [isDeleteRecruitment, setIsDeleteRecruitment] =
    useState<boolean>(false);

  const canEdit = useMemo(() => {
    return (
      recruitment?.origin === RecruitmentOrigin.CS &&
      (isCsAdminRole(currentUser.role) ||
        // CSOperatorかつ自分で作成した募集の場合は編集可能
        recruitment?.createdBy === currentUser.account?.id)
    );
  }, [recruitment, currentUser]);

  const {
    applicant,
    isLoading: isLoadingApplicant,
    refetch: refetchApplicant,
  } = useApplicantByRecruitmentId(recruitmentId);
  const onEditRecruitment = useCallback(() => {
    history.push({
      to: "RESIDENT_RECRUITMENT_EDIT_OPENED",
      query: { id: recruitmentId },
    });
  }, []);

  const onOpenModalCloseRecruitment = useCallback(() => {
    setIsCloseRecruitment(true);
  }, []);
  const onOpenModalResendRecruitment = useCallback(() => {
    setIsResendRecruitment(true);
  }, []);
  const onOpenModalCloseRecruitmentSchedule = useCallback(() => {
    setIsCloseRecruitmentSchedule(true);
  }, []);
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmitCloseRecruitment = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await closeRecruitment(recruitmentId);
      await refetch();
      toast.success("募集の掲載を終了しました");
    } catch (e) {
      toast.error("募集の掲載の終了に失敗しました");
    } finally {
      setIsCloseRecruitment(false);
      setDoubleClickBlocked(false);
    }
  }, []);

  const onSubmitResendRecruitment = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await resendRecruitment(recruitmentId);
      toast.success("再通知を送信しました");
    } catch (e) {
      toast.error("再通知の送信に失敗しました");
    } finally {
      setIsResendRecruitment(false);
      setDoubleClickBlocked(false);
    }
  }, []);

  const onCancelCloseRecruitment = useCallback(() => {
    setIsCloseRecruitment(false);
  }, []);

  const onCancelResendRecruitment = useCallback(() => {
    setIsResendRecruitment(false);
  }, []);

  const onCancelCloseRecruitmentSchedule = useCallback(() => {
    setIsCloseRecruitmentSchedule(false);
  }, []);

  const onOpenModalDeleteRecruitment = useCallback(() => {
    setIsDeleteRecruitment(true);
  }, []);

  const onSubmitDeleteRecruitment = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await deleteOpenRecruitment(recruitmentId);
      toast.success("募集を削除しました");
      history.push({
        to: "RESIDENT_RECRUITMENT_LIST",
      });
    } catch (e) {
      toast.error("募集の削除に失敗しました");
    } finally {
      setIsDeleteRecruitment(false);
    }
    setDoubleClickBlocked(false);
  }, []);

  const onCancelDeleteRecruitment = useCallback(() => {
    setIsDeleteRecruitment(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsApplyModal(true);
    show();
  }, []);

  const onSubmit = useCallback(
    async (values: RecruitmentApplyBodyType) => {
      setIsApplyModal(false);
      try {
        await applyRecruitment(values);
        toast.success("応募しました");
        await refetchApplicant();
      } catch (e) {
        toast.error("応募に失敗しました");
        logger.error(e, { values, currentUser });
      }

      close();
    },
    [recruitment]
  );

  const onClose = useCallback(() => {
    setIsApplyModal(false);
    close();
  }, []);

  const isRecruitmentStarted = useMemo(() => {
    if (!recruitment) return false;

    const firstDay = new Date(
      recruitment.schedule.reduce((a, b) =>
        new Date(a.date) < new Date(b.date) ? a : b
      ).date
    );
    firstDay.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return firstDay <= today;
  }, [recruitment]);

  // 応募者の選考ステータス
  const applicantStatus = useMemo(() => {
    if (!applicant) return null;

    if (applicant?.eventStatuses?.length > 0) {
      return getApplicationStatus(applicant.eventStatuses);
    }
    return null;
  }, [applicant?.eventStatuses]);

  const isApplyButtonDisabled = useMemo(() => {
    if (isLoadingApplicant || !recruitment || applicantStatus !== null) {
      return true;
    }

    switch (recruitment.origin) {
      case RecruitmentOrigin.CS:
        if (
          isCsAdminRole(currentUser.role) ||
          recruitment.createdBy === currentUser.account?.id
        ) {
          return true;
        }
        return hasHigherRole(currentUser.role, "CSOperator");

      case RecruitmentOrigin.BOARD_EDUCATION:
        return false;

      default:
        return true;
    }
  }, [recruitment, applicantStatus, currentUser, isLoadingApplicant]);

  if (isLoading) {
    return <></>;
  }

  if (isError || !recruitment) {
    return <span>{error?.message || "募集情報が見つかりません"}</span>;
  }

  return (
    <>
      <BreadCrumb>
        <Link
          to={{
            pathname: "/resident/recruitment",
            search: `communityId=${communityId}`,
          }}
        >
          募集
        </Link>
        <LinkWrap>{recruitment.title}</LinkWrap>
      </BreadCrumb>
      <DetailContainer>
        {canEdit && recruitment?.status !== "CLOSED" && (
          <ControllerWrapper>
            {!isRecruitmentStarted && (
              <>
                <ControllerEditLabel onClick={onEditRecruitment}>
                  編集
                </ControllerEditLabel>
                <Separator />
                <ControllerEditLabel onClick={onOpenModalCloseRecruitment}>
                  掲載を終了
                </ControllerEditLabel>
                <Separator />
                <ControllerEditLabel onClick={onOpenModalResendRecruitment}>
                  再通知
                </ControllerEditLabel>
                <Separator />
                {/* TODO: スマホUIは後で対応 */}
                <ControllerEditLabel
                  onClick={onOpenModalCloseRecruitmentSchedule}
                >
                  募集を停止
                </ControllerEditLabel>
                <Separator />
              </>
            )}
            <ControllerCloseLabel onClick={onOpenModalDeleteRecruitment}>
              募集を削除
            </ControllerCloseLabel>
          </ControllerWrapper>
        )}
        {canEdit && recruitment.status === "CLOSED" && (
          <ControllerWrapper>
            <ControllerCloseLabel onClick={onOpenModalDeleteRecruitment}>
              募集を削除
            </ControllerCloseLabel>
          </ControllerWrapper>
        )}
        {applicantStatus === "APPROVED" || applicantStatus === "APPLIED" ? (
          <ConfirmArea>応募済みの募集です</ConfirmArea>
        ) : applicantStatus === "DECLINED" ? (
          <ConfirmArea>不参加にした応募です</ConfirmArea>
        ) : applicantStatus === "REJECTED" ? (
          <ConfirmArea>見送りになった応募です</ConfirmArea>
        ) : null}
        <RecruitmentDetailCard
          recruitment={recruitment}
          applicant={applicant ?? undefined}
          refetchApplicant={refetchApplicant}
        />
        <Margin marginBottom={24} />
        {recruitmentIsOpened(recruitment) && (
          <Button
            size="large"
            fill
            style={{ width: "100%" }}
            onClick={onOpen}
            disabled={
              isApplyButtonDisabled ||
              recruitment.schedule.every(
                (schedule) => schedule.status === "CLOSED"
              )
            }
          >
            応募する
          </Button>
        )}
        {!recruitmentIsOpened(recruitment) && (
          <Button
            size="large"
            fill
            style={{
              width: "100%",
              color: "#FFFFFF",
              backgroundColor: "#99C9Eb",
            }}
          >
            この募集は終了しました
          </Button>
        )}
      </DetailContainer>
      {isApplyModal && recruitment && (
        <ApplyRecruitmentModal
          recruitment={recruitment}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
      {!!isCloseRecruitment && (
        <CloseRecruitmentModal
          title={recruitment.title}
          onSubmit={onSubmitCloseRecruitment}
          onClose={onCancelCloseRecruitment}
        />
      )}
      {!!isResendRecruitment && (
        <ResendRecruitmentModal
          title={recruitment.title}
          onSubmit={onSubmitResendRecruitment}
          onClose={onCancelResendRecruitment}
        />
      )}
      {!!isCloseRecruitmentSchedule && (
        <CloseRecruitmentScheduleModal
          recruitment={recruitment}
          onClose={onCancelCloseRecruitmentSchedule}
          refetch={refetch}
        />
      )}
      {!!isDeleteRecruitment && (
        <DeleteOpenRecruitmentModal
          title={recruitment.title}
          onSubmit={onSubmitDeleteRecruitment}
          onClose={onCancelDeleteRecruitment}
          disabled={doubleClickBlocked}
        />
      )}
    </>
  );
}

const LinkWrap = styled.span`
  font-weight: bold;
`;

const ConfirmArea = styled.div`
  padding: 8px;
  margin: 8px 0;
  background-color: #fff9e8;
  color: #8a6a0a;
  width: 100%;
  text-align: center;
`;

// TODO: 作成プレビュー側とCSS/コンポーネントを共通化
const DetailContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding-bottom: 30px;
`;

const ControllerWrapper = styled.div`
  margin: 8px;
  margin-left: auto;
  width: fit-content;
`;

const Separator = styled.span`
  border: 1px solid #d3dae6;
  margin: 16px;
`;

const ControllerEditLabel = styled.button`
  font-size: 14px;
  cursor: pointer;
  color: #005ec4;
  background-color: transparent;
  border: 0;
`;

const ControllerCloseLabel = styled.button`
  font-size: 14px;
  cursor: pointer;
  color: #bd271e;
  background-color: transparent;
  border: 0;
`;
