import React, { useEffect, useState } from "react";

import {
  PageContainer,
  PageHeader,
  PageHeaderTitle,
  PageBody,
} from "../../../components/Page";
import { usePolyfitHistory } from "../../../hooks/router";
import * as invitationApi from "../../../apiClients/invitation";
import { useSearchParams } from "react-router-dom";
import { useOrganizationById } from "../../../hooks/api/internal/organizations";
import { useLoginUser } from "src/hooks/recoil/user";
import { hasHigherInternalRole } from "@shared/utils/internalUserRole";

export default function InvitationPage() {
  const { loginUser } = useLoginUser();
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get("organizationId");
  const { organization } = useOrganizationById(organizationId ?? "");
  const [invitationLink, setInvitationLink] = useState<string>();
  const history = usePolyfitHistory();

  useEffect(() => {
    if (organizationId && organization) {
      (async () => {
        if (organization.serviceType === "PTA") {
          const ptaToken = await invitationApi.getOrGenerateInvitationToken({
            organizationId,
          });
          const ptaUrl = `${location.origin}/signup?token=${ptaToken}&openExternalBrowser=1`;
          setInvitationLink(ptaUrl);
        } else if (organization.serviceType === "CS") {
          const csToken =
            await invitationApi.getOrGenerateInvitationResidentToken({
              organizationId,
            });
          const csUrl = `${location.origin}/signup?token=${csToken}&openExternalBrowser=1`;
          setInvitationLink(csUrl);
        }
      })();
    }
  }, [organization]);

  const copyLink = async (url: string) => {
    if (url && url.startsWith("http")) {
      await navigator.clipboard.writeText(url);
      alert("招待リンクがコピーされました。");
    }
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageHeaderTitle>招待ページを発行する</PageHeaderTitle>
      </PageHeader>
      <a
        style={{
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push({
            to: "INTERNAL_INVITATION_LIST",
          });
        }}
      >
        招待一覧へ戻る
      </a>{" "}
      {hasHigherInternalRole(loginUser?.internalRole, "INTERNAL_ADMIN") && (
        <a
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push({
              to: "INTERNAL_ORGANIZATION_USER_LIST",
              query: { organizationId: organizationId ?? "" },
            });
          }}
        >
          組織詳細へ戻る
        </a>
      )}
      <PageBody>
        {invitationLink ? (
          <div>
            {organization?.serviceType}招待リンク：{invitationLink}
            {invitationLink.startsWith("http") && (
              <a
                onClick={() => copyLink(invitationLink)}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                コピー
              </a>
            )}
          </div>
        ) : (
          "取得中..."
        )}
      </PageBody>
    </PageContainer>
  );
}
