export const applicationSelectionStatus = {
  APPLIED: "APPLIED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DECLINED: "DECLINED",
} as const;

export type ApplicationSelectionStatus =
  (typeof applicationSelectionStatus)[keyof typeof applicationSelectionStatus];

export const applicationAttendanceStatus = {
  ATTENDED: "ATTENDED",
  ABSENT: "ABSENT",
} as const;

export const applicationAttendanceStatusOptions = [
  { value: applicationAttendanceStatus.ATTENDED, text: "出席" },
  { value: applicationAttendanceStatus.ABSENT, text: "欠席" },
  { value: null, text: "未出席" },
] as const;

export type ApplicationAttendanceStatus =
  (typeof applicationAttendanceStatus)[keyof typeof applicationAttendanceStatus];

export type RecruitmentApplicationEvent = {
  id?: string;
  recruitmentApplicationId: string;
  recruitmentScheduleId: string;
  selectionStatus: ApplicationSelectionStatus;
  rejectedAt?: Date;
  attendanceStatus?: ApplicationAttendanceStatus | null;
  attendedAt?: Date | null;
};
