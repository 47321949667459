import { Role } from "@shared/types/role";

export { Role };

export const ptaRoleOptions = [
  {
    value: "PTAAdmin",
    text: "管理者",
    descriptions: [
      "入会メンバーの承認",
      "連絡の新規作成",
      "連絡の承認者の設定",
      "連絡の表示年度の設定",
      "名簿閲覧",
      "名簿のCSV出力",
      "名簿の公開範囲設定",
      "所属・役職の設定",
      "招待用リンク生成",
      "メンバーの権限変更",
      "メンバーの退会",
    ],
  },
  {
    value: "PTAMember",
    text: "メンバー",
    descriptions: ["連絡の新規作成", "名簿閲覧（公開されてる場合）"],
  },
] as const;

export const csRoleOptions = [
  {
    value: "CSAdmin",
    text: "管理者",
    descriptions: [
      "地域住民の名簿閲覧",
      "募集作成",
      "応募者の参加決定・見送り",
      "応募者とのメッセージ",
      "招待用リンク生成",
      "メンバーの権限変更",
      "メンバーの退会",
    ],
  },
  {
    value: "CSOperator",
    text: "オペレーター",
    descriptions: [
      "募集作成",
      "作成した募集に応募したメンバー情報の閲覧",
      "作成した募集に応募したメンバーの参加決定・見送り",
      "作成した募集に応募したメンバーとのメッセージ",
    ],
  },
  {
    value: "CSMember",
    text: "メンバー",
    descriptions: ["募集閲覧", "応募", "募集者とのメッセージ"],
  },
] as const;

export const beRoleOptions = [
  {
    value: "BEAdmin",
    text: "教育委員会",
    descriptions: ["CS管理者の作成", "CS管理者の削除"],
  },
  ...csRoleOptions,
] as const;

export const roleOptions = [...ptaRoleOptions, ...csRoleOptions];

export function isPtaRole(role: Role): boolean {
  return role.startsWith("PTA");
}

export function isPtaMemberRole(role: Role): boolean {
  return role === Role.PTAMember;
}

export function isPtaAdminRole(role: Role): boolean {
  return role === Role.PTAAdmin;
}

export function isCsRole(role: Role): boolean {
  return role.startsWith("CS");
}

export function isCsGuestRole(role: Role): boolean {
  return role === Role.CSGuest;
}

export function isCsMemberRole(role: Role): boolean {
  return role === Role.CSMember;
}

export function isCsOperatorRole(role: Role): boolean {
  return role === Role.CSOperator;
}

export function isCsAdminRole(role: Role): boolean {
  return role === Role.CSAdmin;
}

export function isBeAdminRole(role: Role): boolean {
  return role === Role.BEAdmin;
}

export function isAdminRole(role: Role): boolean {
  return isPtaAdminRole(role) || isCsAdminRole(role);
}

export function isCsAdminOrCsOperator(role: Role): boolean {
  return isCsAdminRole(role) || isCsOperatorRole(role);
}

/**
 * 指定した権限以上の権限を持つかどうか
 * @param currentRole 自身のrole
 * @param referenceRole 比較するrole
 * @example hasHigherRole("CSOperator", "CSMember") => true
 * @example hasHigherRole("PTAMember", "PTAMember") => true
 * @example hasHigherRole("PTAMember", "PTAAdmin") => false
 */
export function hasHigherRole(currentRole: Role, referenceRole: Role): boolean {
  if (
    (isPtaRole(currentRole) && isCsRole(referenceRole)) ||
    (isCsRole(currentRole) && isPtaRole(referenceRole))
  ) {
    return false;
  }

  if (isPtaRole(currentRole)) {
    switch (referenceRole) {
      case "PTAAdmin":
        return currentRole === "PTAAdmin";
      case "PTAMember":
        return true;
    }
  } else if (isCsRole(currentRole)) {
    switch (referenceRole) {
      case "CSAdmin":
        return true;
      case "CSOperator":
        return currentRole === "CSAdmin" || currentRole === "CSOperator";
      case "CSMember":
        return true;
    }
  }
  return false;
}
