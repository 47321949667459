import * as client from "./client";

export async function closeRecruitmentSchedule(
  recruitmentId: string,
  recruitmentScheduleIds: string[]
): Promise<void> {
  await client.post<
    { recruitmentId: string; recruitmentScheduleIds: string[] },
    { result: string }
  >("/recruitment-schedule/close", { recruitmentId, recruitmentScheduleIds });
}
