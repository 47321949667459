import { useEffect, useState } from "react";
import * as postUserGroupApi from "../../apiClients/postUserGroup";
import {
  CreateGroupRequest,
  UpdatePostUserGroupRequest,
} from "@shared/types/postUserGroup";

export type PostUserGroupType = {
  id: string;
  name: string;
};

export type PostUserGroupUserType = {
  id: string; // account id
  user: {
    name: string;
  };
};

/**
 * 連絡ユーザーグループのユーザー一覧取得
 */
export function usePostUserGroupUsers(id: string) {
  const [postGroupUsers, setPostGroupUsers] = useState<PostUserGroupUserType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPostGroupUsers();
  }, []);

  const getPostGroupUsers = async () => {
    const postGroupUsers = await postUserGroupApi.getGroupUsers(id);
    setPostGroupUsers(postGroupUsers);
    setIsLoading(false);
  };

  return { postGroupUsers, isLoading, getPostGroupUsers };
}

/**
 * 連絡ユーザーグループ作成
 */
export async function createPostUserGroup(args: CreateGroupRequest) {
  const postUserGroup = await postUserGroupApi.createGroup({ args });
  return postUserGroup;
}

/**
 * 連絡ユーザーグループ更新
 */
export async function updatePostUserGroup(args: UpdatePostUserGroupRequest) {
  const postUserGroup = await postUserGroupApi.updateGroup({ args });
  return postUserGroup;
}

/**
 * 連絡ユーザーグループ削除
 */
export async function deletePostUserGroup(id: string) {
  await postUserGroupApi.deleteGroup(id);
}

/**
 * コミュニティに連絡グループが存在するか
 */
export function useExistPostUserGroup(isPtaAdmin: boolean) {
  const [isGroupExist, setIsGroupExist] = useState<boolean>(false);

  useEffect(() => {
    fetchExistGroup();
  }, [isPtaAdmin]);

  const fetchExistGroup = async () => {
    if (!isPtaAdmin) return;
    const res = await postUserGroupApi.existGroup();
    setIsGroupExist(res.exist);
  };

  return { isGroupExist };
}
