import React, { JSX } from "react";
import { Label } from "../../../components/form/Label";
import { QuestionContainer, QuestionTextField } from "./Components";
import { OpenEndedQuestion } from "@shared/validator/features/survey.schema";

export function OpenEndedQuestionField({
  question,
}: {
  question: OpenEndedQuestion;
}): JSX.Element {
  return (
    <QuestionContainer>
      <Label size="s" marginBottom={0}>
        質問
      </Label>
      <QuestionTextField>{question.text}</QuestionTextField>
      <Label size="s" marginBottom={0}>
        補足説明
      </Label>
      <QuestionTextField>{question.description}</QuestionTextField>
    </QuestionContainer>
  );
}
