import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  useCurrentUser,
  useFetchCurrentUser,
} from "../../../hooks/recoil/user";
import {
  PCBaseInfoWrapper,
  StyledFlex,
  UserImg,
  FullName,
  FullNameKana,
  FullNameContainer,
  StyledTable,
  StyledTr,
  StyledTh,
  StyledTd,
  EditLinkContainer,
  EditBaseInfoIcon,
} from "../../../pages/pta/admin/ProfilePage";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import BaseInfoEditModal from "./BaseInfoEditModal";
import { isResidentUser } from "../../../apiClients/users";
import { getGenderText } from "../../../utils/types/gender";
import { toDisplayDateFormatYearMonth } from "../../../utils/time";
import { useBaseInfo } from "../../../hooks/api/baseInfo";
import { isPtaAdminRole, roleOptions } from "../../../utils/types/role";
import { getAgeGroupText } from "../../../utils/types/ageGroup";
import {
  useOrganization,
  useOrganizationCommunity,
} from "../../../hooks/recoil/organization";
import { getCommunityUserTypeText } from "src/features/Member/Community/utils/utils";
import {
  useFindAdditionalCommunities,
  useFindMainCommunity,
} from "src/features/Member/Community/utils/hooks";
import { ChangeEmailModal } from "../LoginSetting/ChangeEmailModal";
import { useFirebaseUserContext } from "src/hooks/context/firebaseUser";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

const BaseInfoContent = () => {
  const currentUser = useCurrentUser();
  const fetchUser = useFetchCurrentUser();
  const { firebaseUser } = useFirebaseUserContext();
  const {
    currentBaseInfo,
    getBaseInfo,
    isLoading: isBaseInfoLoading,
  } = useBaseInfo();
  const [isOpen, setIsOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const { organization } = useOrganization({});
  const communityRoles = currentUser.account?.communityRoles ?? [];
  const { elementarySchoolCommunities, juniorHighSchoolCommunities } =
    useOrganizationCommunity();

  const mainElementarySchool = useFindMainCommunity(
    elementarySchoolCommunities,
    communityRoles
  );
  const mainJuniorHighSchool = useFindMainCommunity(
    juniorHighSchoolCommunities,
    communityRoles
  );
  const elementarySchools = useFindAdditionalCommunities(
    elementarySchoolCommunities,
    communityRoles
  );
  const juniorHighSchools = useFindAdditionalCommunities(
    juniorHighSchoolCommunities,
    communityRoles
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
    fetchUser();
    getBaseInfo();
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <PCBaseInfoWrapper>
        <BaseInfoFlexContainer>
          {isBaseInfoLoading ? (
            <>読み込み中…</>
          ) : (
            <div>
              <StyledContainerPc>
                <StyledFlex>
                  <UserImg
                    src={currentUser?.picture || defaultUserImg}
                    alt="画像"
                  />
                  <FullNameContainer>
                    <FullNameKana>
                      {currentBaseInfo?.lastNameKana}{" "}
                      {currentBaseInfo?.firstNameKana}
                    </FullNameKana>
                    <FullName>
                      {currentBaseInfo?.lastName} {currentBaseInfo?.firstName}
                    </FullName>
                    <BelongPartWrapper>
                      <BelongNameText>
                        {currentBaseInfo.belong?.name}
                      </BelongNameText>
                      <PartNameText>{currentBaseInfo.part?.name}</PartNameText>
                    </BelongPartWrapper>
                  </FullNameContainer>
                </StyledFlex>

                <StyledTable>
                  <tbody>
                    {currentUser && isResidentUser(currentUser) && (
                      <>
                        <StyledTr>
                          <StyledTh>性別</StyledTh>
                          <StyledTd>
                            {currentUser.baseInfo?.gender &&
                              getGenderText(currentUser.baseInfo.gender)}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>年代</StyledTh>
                          <StyledTd>
                            {getAgeGroupText(currentUser.baseInfo?.ageGroup)}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>生年月日</StyledTh>
                          <StyledTd>
                            {currentUser.baseInfo?.birthday &&
                              toDisplayDateFormatYearMonth(
                                currentUser.baseInfo.birthday
                              )}
                          </StyledTd>
                        </StyledTr>
                      </>
                    )}
                    <StyledTr>
                      <StyledTh>メールアドレス</StyledTh>
                      <StyledTd>{currentUser?.email}</StyledTd>
                    </StyledTr>
                    <StyledTr>
                      <StyledTh>電話番号</StyledTh>
                      <StyledTd>{currentBaseInfo?.phoneNumber}</StyledTd>
                    </StyledTr>
                    {currentUser.type === "PARENT" && (
                      <StyledTr>
                        <StyledTh>登校班</StyledTh>
                        <StyledTd>{currentBaseInfo?.schoolGroup}</StyledTd>
                      </StyledTr>
                    )}
                    <StyledTr>
                      <StyledTh>権限</StyledTh>
                      <StyledTd>
                        {
                          roleOptions.find(
                            (role) => currentUser.role === role.value
                          )?.text
                        }
                      </StyledTd>
                    </StyledTr>
                    {currentUser.type === "PARENT" && (
                      <StyledTr>
                        <StyledTh>来年度に入学予定の児童（同学校）</StyledTh>
                        <StyledTd>
                          {currentBaseInfo?.isPreschooler == true
                            ? "いる"
                            : "いない"}
                        </StyledTd>
                      </StyledTr>
                    )}
                    {(currentUser?.type === "PARENT" ||
                      currentUser?.type === "TEACHER") &&
                      currentBaseInfo?.selfFreeMemo && (
                        <StyledTr>
                          <StyledTh>自由記述</StyledTh>
                          <StyledTd>
                            {currentBaseInfo?.selfFreeMemo
                              .split("\n")
                              .map((line, i) => (
                                <p key={i}>{line}</p>
                              ))}
                          </StyledTd>
                        </StyledTr>
                      )}
                    {(currentUser?.type === "PARENT" ||
                      currentUser?.type === "TEACHER") &&
                      isPtaAdminRole(currentUser.role) &&
                      currentBaseInfo?.adminFreeMemo && (
                        <StyledTr>
                          <StyledTh>自由記述（役員管理用）</StyledTh>
                          <StyledTd>
                            {currentBaseInfo?.adminFreeMemo
                              .split("\n")
                              .map((line, i) => (
                                <p key={i}>{line}</p>
                              ))}
                          </StyledTd>
                        </StyledTr>
                      )}
                    {currentUser && isResidentUser(currentUser) && (
                      <>
                        <StyledTr>
                          <StyledTh>住所</StyledTh>
                          <StyledTd>
                            〒{currentUser?.baseInfo?.postalCode}{" "}
                            {currentUser?.baseInfo?.prefecture}
                            {currentUser?.baseInfo?.city}
                            {currentUser?.baseInfo?.address1}
                            {currentUser?.baseInfo?.address2}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>最寄りの小学校区</StyledTh>
                          <StyledTd>
                            {mainElementarySchool?.community?.name ?? ""}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>最寄りの小学校区での役割</StyledTh>
                          <StyledTd>
                            {getCommunityUserTypeText(
                              mainElementarySchool?.communityRole?.type
                            )}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>最寄りの中学校区</StyledTh>
                          <StyledTd>
                            {mainJuniorHighSchool?.community.name ?? ""}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>最寄りの中学校区での役割</StyledTh>
                          <StyledTd>
                            {getCommunityUserTypeText(
                              mainJuniorHighSchool?.communityRole.type
                            )}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>追加小学校区</StyledTh>
                          <StyledTd>
                            {elementarySchools.length > 0 &&
                              elementarySchools
                                .map((community) => community.name)
                                .join("、")}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>追加中学校区</StyledTh>
                          <StyledTd>
                            {juniorHighSchools.length > 0 &&
                              juniorHighSchools
                                .map((community) => community.name)
                                .join("、")}
                          </StyledTd>
                        </StyledTr>
                      </>
                    )}
                  </tbody>
                </StyledTable>
              </StyledContainerPc>

              <StyledContainerSp>
                <StyledFlex>
                  <UserImg
                    src={currentUser?.picture || defaultUserImg}
                    alt="画像"
                  />
                  <FullNameContainer>
                    <FullNameKana>
                      {currentBaseInfo?.lastNameKana}{" "}
                      {currentBaseInfo?.firstNameKana}
                    </FullNameKana>
                    <FullName>
                      {currentBaseInfo?.lastName} {currentBaseInfo?.firstName}
                    </FullName>
                    {(currentUser.type === "PARENT" ||
                      currentUser.type === "TEACHER") && (
                      <BelongPartWrapper>
                        <BelongNameText>
                          {currentBaseInfo.belong?.name}
                        </BelongNameText>
                        <PartNameText>
                          {currentBaseInfo.part?.name}
                        </PartNameText>
                      </BelongPartWrapper>
                    )}
                  </FullNameContainer>
                </StyledFlex>

                <StyledTableSp>
                  {currentUser && isResidentUser(currentUser) && (
                    <>
                      <div>
                        <StyledTHSp>性別</StyledTHSp>
                        <StyledTdSp>
                          {currentUser.baseInfo?.gender === "MALE"
                            ? "男性"
                            : currentUser.baseInfo?.gender === "FEMALE"
                            ? "女性"
                            : ""}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>生年月日</StyledTHSp>
                        <StyledTdSp>
                          {currentUser.baseInfo?.birthday &&
                            toDisplayDateFormatYearMonth(
                              currentUser.baseInfo.birthday
                            )}
                        </StyledTdSp>
                      </div>
                    </>
                  )}
                  <div>
                    <StyledTHSp>メールアドレス</StyledTHSp>
                    <StyledTdSp>{currentUser?.email}</StyledTdSp>
                  </div>
                  <div>
                    <StyledTHSp>電話番号</StyledTHSp>
                    <StyledTdSp>{currentBaseInfo?.phoneNumber}</StyledTdSp>
                  </div>
                  {currentUser.type === "PARENT" && (
                    <div>
                      <StyledTHSp>登校班</StyledTHSp>
                      <StyledTdSp>{currentBaseInfo?.schoolGroup}</StyledTdSp>
                    </div>
                  )}
                  <div>
                    <StyledTHSp>権限</StyledTHSp>
                    <StyledTdSp>
                      {
                        roleOptions.find(
                          (role) => currentUser.role === role.value
                        )?.text
                      }
                    </StyledTdSp>
                  </div>
                  {currentUser.type === "PARENT" && (
                    <div>
                      <StyledTHSp>来年度に入学予定の児童（同学校）</StyledTHSp>
                      <StyledTdSp>
                        {currentBaseInfo?.isPreschooler == true
                          ? "いる"
                          : "いない"}
                      </StyledTdSp>
                    </div>
                  )}

                  {(currentUser?.type === "PARENT" ||
                    currentUser?.type === "TEACHER") &&
                    currentBaseInfo?.selfFreeMemo && (
                      <div>
                        <StyledTHSp>自由記述</StyledTHSp>
                        <StyledTdSp>
                          {currentBaseInfo?.selfFreeMemo
                            ?.split("\n")
                            .map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                        </StyledTdSp>
                      </div>
                    )}
                  {(currentUser?.type === "PARENT" ||
                    currentUser?.type === "TEACHER") &&
                    isPtaAdminRole(currentUser.role) &&
                    currentBaseInfo?.adminFreeMemo && (
                      <div>
                        <StyledTHSp>自由記述（役員管理用）</StyledTHSp>
                        <StyledTdSp>
                          {currentBaseInfo?.adminFreeMemo
                            ?.split("\n")
                            .map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                        </StyledTdSp>
                      </div>
                    )}
                  {currentUser && isResidentUser(currentUser) && (
                    <>
                      <div>
                        <StyledTHSp>住所</StyledTHSp>
                        <StyledTdSp>
                          〒{currentUser?.baseInfo?.postalCode}{" "}
                          {currentUser?.baseInfo?.prefecture}
                          {currentUser?.baseInfo?.city}
                          {currentUser?.baseInfo?.address1}
                          {currentUser?.baseInfo?.address2}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>最寄りの小学校区</StyledTHSp>
                        <StyledTdSp>
                          {mainElementarySchool?.community?.name ?? ""}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>最寄りの小学校区での役割</StyledTHSp>
                        <StyledTdSp>
                          {getCommunityUserTypeText(
                            mainElementarySchool?.communityRole?.type
                          )}
                        </StyledTdSp>
                      </div>

                      <div>
                        <StyledTHSp>最寄りの中学校区</StyledTHSp>
                        <StyledTdSp>
                          {mainJuniorHighSchool?.community.name ?? ""}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>最寄りの中学校区での役割</StyledTHSp>
                        <StyledTdSp>
                          {getCommunityUserTypeText(
                            mainJuniorHighSchool?.communityRole.type
                          )}
                        </StyledTdSp>
                      </div>

                      <div>
                        <StyledTHSp>追加小学校区</StyledTHSp>
                        <StyledTdSp>
                          {elementarySchools.length > 0 &&
                            elementarySchools
                              .map((community) => community.name)
                              .join("、")}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>追加中学校区</StyledTHSp>
                        <StyledTdSp>
                          {juniorHighSchools.length > 0 &&
                            juniorHighSchools
                              .map((community) => community.name)
                              .join("、")}
                        </StyledTdSp>
                      </div>
                    </>
                  )}
                </StyledTableSp>
              </StyledContainerSp>
            </div>
          )}
          {!isBaseInfoLoading && (
            <EditLinkContainer onClick={onOpen}>
              <PencilIcon />
              <EditBaseInfoIcon>編集</EditBaseInfoIcon>
            </EditLinkContainer>
          )}
        </BaseInfoFlexContainer>
      </PCBaseInfoWrapper>

      <BaseInfoEditModal
        isOpen={isOpen && !isEmailModalOpen}
        openChangeEmailModal={() => {
          setIsEmailModalOpen(true);
        }}
        baseInfo={currentBaseInfo}
        organization={organization!}
        close={onClose}
      />
      {firebaseUser && isEmailModalOpen && (
        <ChangeEmailModal
          firebaseUser={firebaseUser}
          isOpen={isEmailModalOpen}
          isFromBaseInfo={true}
          close={() => {
            setIsEmailModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  word-break: break-all;
  font-size: 12px;
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

const BelongPartWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const BaseInfoFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

const BelongNameText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #69707d;
  margin-right: 8px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const PartNameText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #69707d;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export default BaseInfoContent;
