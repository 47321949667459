import * as client from "../client";
import { UpdateApplicationEventSchema } from "@shared/validator/features/recruitmentApplicationEvent.schema";

/**
 * 応募系APIクライアント 更新系
 */
export async function approveApplication(
  recruitApplicationEventId: string
): Promise<void> {
  await client.post<UpdateApplicationEventSchema, { result: string }>(
    "/boardEducation/recruitment-application-event/approve",
    { recruitApplicationEventId }
  );
}

export async function rejectApplication(
  recruitApplicationEventId: string,
  comment: string
): Promise<void> {
  await client.post<UpdateApplicationEventSchema, { result: string }>(
    "/boardEducation/recruitment-application-event/reject",
    {
      recruitApplicationEventId,
      comment,
    }
  );
}

export async function declineApplicationByAdmin(
  recruitApplicationEventId: string,
  comment: string
): Promise<void> {
  await client.post<UpdateApplicationEventSchema, { result: string }>(
    "/boardEducation/recruitment-application-event/declineByAdmin",
    {
      recruitApplicationEventId,
      comment,
    }
  );
}
