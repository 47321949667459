import { z } from "zod";

// 停止APIのリクエストボディのスキーマ
export const recruitmentScheduleCloseBodySchema = z.object({
  recruitmentId: z.string(),
  recruitmentScheduleIds: z.array(z.string()).min(1, {
    message: "少なくとも1つの日程を選択してください",
  }),
});

export type RecruitmentScheduleCloseBodyType = z.infer<
  typeof recruitmentScheduleCloseBodySchema
>;
