import React, { JSX, memo } from "react";
import styled from "styled-components";
import { Control, Controller } from "react-hook-form";
import { SurveyQuestions } from "@shared/validator/features/survey.schema";

type Props = {
  control: Control<SurveyQuestions>;
  questionIndex: number;
  choiceIndex: number;
  onDelete: () => void;
  isDeletable: boolean;
};

export const ChoiceOption = memo(
  ({
    control,
    questionIndex,
    choiceIndex,
    onDelete,
    isDeletable,
  }: Props): JSX.Element => {
    return (
      <Container>
        <CircleIcon />
        <Controller
          name={`questionList.${questionIndex}.choiceList.${choiceIndex}.text`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField placeholder="選択肢を入力してください" {...field} />
              {error && <ErrorText>{error.message}</ErrorText>}
            </>
          )}
        />
        {isDeletable && <CloseIcon onClick={onDelete} />}
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg: {
    width: 16px;
    height: 16px;
  }
`;

const TextField = styled.input`
  flex: 1;
  background-color: transparent;
  font-size: 14px;
  color: #343741;
  line-height: 24px;
  border-width: 0px;
  border-bottom: rgba(19, 34, 149, 0.1) 1px solid;
  ::placeholder {
    color: rgba(90, 96, 107, 1);
    font-size: 14px;
  }
  -webkit-appearance: none;
`;

const CircleIcon = memo(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.5" fill="white" stroke="#C9CBCD" />
  </svg>
));

const Button = styled.button`
  /* reset-css */
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CloseIcon = memo(
  (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Button {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7262 12L7.83895 7.85355C7.6559 7.65829 7.6559 7.34171 7.83895 7.14645C8.02201 6.95118 8.31881 6.95118 8.50187 7.14645L12.3892 11.2929L16.2765 7.14645C16.4595 6.95118 16.7563 6.95118 16.9394 7.14645C17.1224 7.34171 17.1224 7.65829 16.9394 7.85355L13.0521 12L16.9394 16.1464C17.1224 16.3417 17.1224 16.6583 16.9394 16.8536C16.7563 17.0488 16.4595 17.0488 16.2765 16.8536L12.3892 12.7071L8.50187 16.8536C8.31881 17.0488 8.02201 17.0488 7.83895 16.8536C7.6559 16.6583 7.6559 16.3417 7.83895 16.1464L11.7262 12Z"
          fill="#343741"
        />
      </svg>
    </Button>
  )
);

const ErrorText = styled.p`
  color: #bd271e;
  font-size: 14px;
`;
