import { format, isThisYear, isToday, isYesterday } from "date-fns";
import { ja } from "date-fns/locale";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { statusTextMap } from "@shared/types/appliedRecruitment";
import { toDateIntervalFormat } from "src/utils/time";

export function formatRecruitmentDateAndStatusLabel(
  eventStatuses: RecruitmentApplication["eventStatuses"]
): string {
  const formattedRecruitmentDates = eventStatuses.map((eventStatus) => {
    const selectionStatusText = statusTextMap[eventStatus.selectionStatus];

    const { start, end } = eventStatus.recruitmentSchedule;

    const startDate = new Date(start);
    const endDate = new Date(end);

    const formattedInterval = toDateIntervalFormat(startDate, endDate);

    return `[${selectionStatusText}] ${formattedInterval}`;
  });

  return formattedRecruitmentDates.join(", ");
}

export function formatDateTime(datetime: string) {
  const formattedDateTime = `yyyy年MM月dd日(E) HH:mm`;

  return format(new Date(datetime), formattedDateTime, { locale: ja });
}

export function formatDateTimeToDate(datetime: string) {
  const date = new Date(datetime);
  if (isToday(date)) {
    return "今日";
  }

  if (isYesterday(date)) {
    return "昨日";
  }

  const yearFormat = isThisYear(date) ? "" : "yyyy年";
  const formattedDate = `${yearFormat}MM月dd日(E)`;

  return format(date, formattedDate, { locale: ja });
}

export function formatDateTimeToTime(datetime: string) {
  const formattedDateTime = `HH:mm`;

  return format(new Date(datetime), formattedDateTime, { locale: ja });
}
