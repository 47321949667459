import React from "react";
import { useCheckUserInThisCommunity } from "src/hooks/query/auth/checkUserInThisCommunity";
import styled from "styled-components";
import { Link } from "src/components/Link";
import { BREAKPOINTS } from "src/components/Responsive";

export const MemberDetailLink = ({ userId }: { userId: string }) => {
  const { checkUserInThisCommunity } = useCheckUserInThisCommunity(userId);
  return checkUserInThisCommunity ? (
    <LinkText
      to={{
        to: "PTA_LIST_MEMBER",
        query: { userId: userId },
      }}
      $place="end"
    >
      応募者の詳細確認
    </LinkText>
  ) : (
    <NotInThisCommunity>
      この応募者は現在コミュニティに所属していません
    </NotInThisCommunity>
  );
};

const LinkText = styled(Link)<{ $place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.$place};
  color: #07c;
  @media (max-width: ${BREAKPOINTS.SP}) {
    text-align: center;
  }
`;

const NotInThisCommunity = styled.p`
  font-size: 12px;
`;
