import {
  CreateOrUpdateGradeSizeSchemaType,
  GradeSize,
} from "@shared/validator/features/gradeSize.schema";
import * as client from "./client";

export async function getGradeSizes(): Promise<GradeSize[]> {
  const res = await client.get<{}, GradeSize[]>(`/gradeSize/getGradeSize`, {});
  return res;
}

export async function createOrUpdateGradeSizes(
  gradeSizes: CreateOrUpdateGradeSizeSchemaType
): Promise<void> {
  await client.post(`/gradeSize/createOrUpdateGradeSize`, {
    gradeSizes,
  });
}
