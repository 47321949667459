import { z } from "zod";

export const SurveyStateSchema = z.enum(["DRAFTED", "CREATED"]);
export const QuestionTypeSchema = z.enum(["MULTI_CHOICE", "OPEN_ENDED"]);

export const ChoiceSchema = z.object({
  id: z.string().uuid(),
  text: z.string().min(1, "選択肢を入力してください"),
});

export const MultiChoiceQuestionSchema = z.object({
  id: z.string().uuid(),
  text: z.string().min(1, "質問を入力してください"),
  description: z.string(),
  questionType: QuestionTypeSchema.pipe(z.literal("MULTI_CHOICE")),
  choiceList: z.array(ChoiceSchema).min(2, "最低2つの選択肢が必要です"),
  weightsNumberOfChildren: z.boolean(),
  allowMultipleAnswers: z.boolean(),
});

export const OpenEndedQuestionSchema = z.object({
  id: z.string().uuid(),
  text: z.string().min(1, "質問を入力してください"),
  description: z.string(),
  questionType: QuestionTypeSchema.pipe(z.literal("OPEN_ENDED")),
});

export const QuestionSchema = z.union([
  MultiChoiceQuestionSchema,
  OpenEndedQuestionSchema,
]);

export const SurveyQuestionsSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  state: SurveyStateSchema,
  questionList: z.array(QuestionSchema).min(1, "最低1つの質問が必要です"),
  deadLine: z
    .string()
    .datetime()
    .refine(
      (date) => new Date(date) > new Date(),
      "回答期限を過去の日付に設定することはできません"
    ),
});

export const CreatedSurveyQuestionsSchema = SurveyQuestionsSchema.extend({
  state: SurveyStateSchema.pipe(z.literal("CREATED")),
}).optional();

//Draft用、バリデーションチェックしない
export const DraftedQuestionChoiceSchema = ChoiceSchema.extend({
  text: z.string(),
});

export const DraftedMultiChoiceQuestionSchema =
  MultiChoiceQuestionSchema.extend({
    text: z.string(),
    choiceList: z.array(DraftedQuestionChoiceSchema),
  });

export const DraftedOpenEndedQuestionSchema = OpenEndedQuestionSchema.extend({
  text: z.string(),
});

export const DraftedQuestionSchema = z.union([
  DraftedMultiChoiceQuestionSchema,
  DraftedOpenEndedQuestionSchema,
]);

export const DraftedSurveyQuestionsSchema = SurveyQuestionsSchema.extend({
  questionList: z.array(DraftedQuestionSchema),
  deadLine: z.string().datetime(),
  state: SurveyStateSchema.pipe(z.literal("DRAFTED")),
}).optional();
// 型をスキーマから生成
export type SurveyState = z.infer<typeof SurveyStateSchema>;
export type QuestionType = z.infer<typeof QuestionTypeSchema>;
export type Choice = z.infer<typeof ChoiceSchema>;
export type MultiChoiceQuestion = z.infer<typeof MultiChoiceQuestionSchema>;
export type OpenEndedQuestion = z.infer<typeof OpenEndedQuestionSchema>;
export type Question = z.infer<typeof QuestionSchema>;
export type SurveyQuestions = z.infer<typeof SurveyQuestionsSchema>;
