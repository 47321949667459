import {
  recruitmentEntryMethod,
  RecruitmentEntryMethod,
} from "@shared/types/recruitment";

/**
 * isAutoApproval を entryMethod に変換する関数
 *
 * @param isAutoApproval - 自動承認フラグ
 * @returns RecruitmentEntryMethod.AUTO_APPROVAL または RecruitmentEntryMethod.MANUAL_SCREENING
 */
export function transformIsAutoApprovalToEntryMethod(
  isAutoApproval: boolean
): RecruitmentEntryMethod {
  return isAutoApproval
    ? recruitmentEntryMethod.AUTO_APPROVAL
    : recruitmentEntryMethod.MANUAL_SCREENING;
}
