import React, { useEffect, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { useLoginUser } from "../../../hooks/recoil/user";
import { ServiceType } from "../../../utils/types/serviceType";
import { InternalOrganization } from "src/@shared/types/organization";
import { Margin } from "../../../components/Margin";
import { toDateFormat } from "../../../utils/time";
import { useGetOrganizations } from "src/hooks/query/internal/organizations/useGetOrganizations";
import { CreateBoardEducationModal } from "./CreateBoardEducationModal";
import { useModal } from "../../../components/Modal";
import { schoolDisplayType, schoolType } from "@shared/types/organization";
import { RadioButton } from "../../../components/form/RadioButton";
import {
  hasHigherInternalRole,
  isInternalRoleAdmin,
} from "@shared/utils/internalUserRole";

export default function OrganizationListPage() {
  const history = usePolyfitHistory();

  const { organizations } = useGetOrganizations();
  const { loginUser } = useLoginUser();
  const [serviceType, setServiceType] = React.useState<ServiceType>("PTA");
  const [predictions, setPredictions] = React.useState<InternalOrganization[]>(
    []
  );
  const [isOpenCreateBEModal, { show, close }] = useModal();
  const [selectedOrganization, setSelectedOrganization] =
    useState<InternalOrganization | null>(null);
  const [sortBy, setSortBy] = useState<"createdAtDesc" | "updatedAtDesc">(
    "createdAtDesc"
  );

  const onShowCreateBEModal = (organization: InternalOrganization) => {
    setSelectedOrganization(organization);
    show();
  };

  const onCloseCreateBEModal = () => {
    close();
    setSelectedOrganization(null);
  };

  const filteredOrganizations = useMemo(() => {
    return organizations.filter(
      (organization) => organization.serviceType === serviceType
    );
  }, [organizations, serviceType]);

  const sortedOrganizations = useMemo(() => {
    if (!sortBy) return filteredOrganizations;

    const sorted = [...filteredOrganizations];
    switch (sortBy) {
      case "createdAtDesc":
        sorted.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        break;
      case "updatedAtDesc":
        sorted.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
        break;
    }
    return sorted;
  }, [filteredOrganizations, sortBy]);

  useEffect(() => {
    setPredictions(sortedOrganizations.slice(0, 200));
  }, [sortedOrganizations]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    if (searchText === "") {
      setPredictions(sortedOrganizations.slice(0, 200));
      return;
    }

    // 検索処理
    const filteredOrganization = sortedOrganizations.filter((organization) => {
      return organization.name.toLowerCase().includes(searchText.toLowerCase());
    });
    setPredictions(filteredOrganization);
  };

  const handleEditOrganization = useCallback((organizationId: string) => {
    history.push({
      to: "INTERNAL_ORGANIZATION_EDIT",
      query: { organizationId },
    });
  }, []);

  return (
    <>
      {!isInternalRoleAdmin(loginUser?.internalRole) ? (
        <>
          <div>読み込み中…</div>
          <div>画面が切り替わらない場合はお問い合わせください</div>
        </>
      ) : (
        <>
          <div>
            <button
              onClick={() => {
                setServiceType("PTA");
              }}
            >
              PTA組織一覧
            </button>
            <button
              onClick={() => {
                setServiceType("CS");
              }}
            >
              CS組織一覧
            </button>
          </div>
          <Margin marginTop={4} />
          <h2>{serviceType}組織一覧</h2>
          <a
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push({
                to: "INTERNAL_INVITATION_LIST",
              });
            }}
          >
            組織の追加、招待リンクの発行
          </a>
          {hasHigherInternalRole(loginUser?.internalRole, "INTERNAL_ADMIN") && (
            <a
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  to: "INTERNAL_ORGANIZATION_USER_LIST",
                  query: {
                    organizationId: loginUser?.account?.organizationId ?? "",
                  },
                });
              }}
            >
              自分の組織のユーザー一覧
            </a>
          )}
          <Margin marginTop={8} />
          <TextField
            id="search-text-field"
            onChange={onChange}
            type="text"
            placeholder={"組織を検索"}
          />
          <Section>
            <SectionTitle>並び順</SectionTitle>
            <SectionInputArea>
              <RadioButton
                label="作成日時が新しい順"
                checked={sortBy === "createdAtDesc"}
                onChange={() => setSortBy("createdAtDesc")}
              />
              <RadioButton
                label="編集日時が新しい順"
                checked={sortBy === "updatedAtDesc"}
                onChange={() => setSortBy("updatedAtDesc")}
              />
            </SectionInputArea>
          </Section>
          <table>
            <tr>
              <td>組織ID</td>
              <td>組織名</td>
              <td>サービス名</td>
              {serviceType === "PTA" && (
                <>
                  <td>NameListPublished</td>
                  <td>requireAdminToUpdateCareer</td>
                  <td>学校種別</td>
                  <td>学年表示形式</td>
                  <td>所属</td>
                  <td>役職</td>
                </>
              )}
              {serviceType === "CS" && (
                <>
                  <td>教育委員会連携</td>
                </>
              )}
              <td>作成日時</td>
              <td>更新日時</td>
              <td>操作</td>
            </tr>
            {predictions.map((organization, i) => {
              return (
                <tr
                  key={i}
                  style={i % 2 === 0 ? { backgroundColor: "white" } : undefined}
                >
                  <td>
                    {hasHigherInternalRole(
                      loginUser?.internalRole,
                      "INTERNAL_ADMIN"
                    ) ? (
                      <a
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push({
                            to: "INTERNAL_ORGANIZATION_USER_LIST",
                            query: { organizationId: organization.id },
                          });
                        }}
                      >
                        {organization.id}
                      </a>
                    ) : (
                      <span>{organization.id}</span>
                    )}
                  </td>
                  <td>
                    {hasHigherInternalRole(
                      loginUser?.internalRole,
                      "INTERNAL_ADMIN"
                    ) ? (
                      <a
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push({
                            to: "INTERNAL_ORGANIZATION_USER_LIST",
                            query: { organizationId: organization.id },
                          });
                        }}
                      >
                        {organization.name}
                      </a>
                    ) : (
                      <span>{organization.name}</span>
                    )}
                  </td>
                  <td>{`${organization.serviceType}`}</td>
                  {serviceType === "PTA" && (
                    <>
                      <td>{`${organization.nameListPublished}`}</td>
                      <td>{`${organization.requireAdminToUpdateCareer}`}</td>
                      <td>{`${schoolType[organization.schoolType]}`}</td>
                      <td>{`${
                        schoolDisplayType[organization.schoolDisplayType]
                      }`}</td>
                      <td>
                        <div>
                          {organization.belongs.map((belong, i) => {
                            return <div key={i}>{belong.name}</div>;
                          })}
                        </div>
                      </td>
                      <td>
                        <div>
                          {organization.parts.map((part, i) => {
                            return <div key={i}>{part.name}</div>;
                          })}
                        </div>
                      </td>
                    </>
                  )}
                  {serviceType === "CS" && (
                    <>
                      <td>
                        {organization.managingBoardEducation ? (
                          <a
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              history.push({
                                to: "INTERNAL_ORGANIZATION_BOARD_EDUCATION_USERS",
                                query: {
                                  organizationId:
                                    organization.managingBoardEducation
                                      ?.organizationId ?? "",
                                },
                              })
                            }
                          >
                            連携（詳細）
                          </a>
                        ) : (
                          <a
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => onShowCreateBEModal(organization)}
                          >
                            未連携（新規作成）
                          </a>
                        )}
                      </td>
                    </>
                  )}
                  <td>{toDateFormat(new Date(organization.createdAt))}</td>
                  <td>{toDateFormat(new Date(organization.updatedAt))}</td>
                  <td>
                    <TransitionLink
                      onClick={() => {
                        handleEditOrganization(organization.id);
                      }}
                    >
                      edit
                    </TransitionLink>
                  </td>
                </tr>
              );
            })}
          </table>
        </>
      )}
      {selectedOrganization && (
        <CreateBoardEducationModal
          isOpen={isOpenCreateBEModal}
          onClose={onCloseCreateBEModal}
          organization={selectedOrganization}
        />
      )}
    </>
  );
}

const TransitionLink = styled.a`
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
`;

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  margin-right: 10px;
`;

const SectionInputArea = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: auto;
`;
