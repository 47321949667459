import { z } from "zod";
import {
  zRequiredString,
  zPastDateString,
} from "@shared/validator/rules/string";
import { residentInfoFormSchema } from "src/validator/form/residentInfo/residentInfoFormSchema";
import { communityRolesFormSchema } from "src/validator/form/member/community/community.schema";
import { baseInfoSchema } from "@shared/validator/models/baseInfo.schema";

const baseInfoCommonFormSchema = baseInfoSchema.pick({
  userId: true,
  lastName: true,
  lastNameKana: true,
  firstName: true,
  firstNameKana: true,
  phoneNumber: true,
  gender: true,
  ageGroup: true,
  birthday: true,
  postalCode: true,
  prefecture: true,
  city: true,
  address1: true,
  address2: true,
});

/**
 * CSの基本情報のスキーマ
 */
export const CSFormValueSchema = z.object({
  baseInfo: baseInfoCommonFormSchema.extend({
    gender: z.enum(["MALE", "FEMALE", "OTHER"], {
      errorMap: () => ({ message: "入力必須項目です" }),
    }),
    birthday: zPastDateString.optional(),
    postalCode: zRequiredString,
    prefecture: zRequiredString,
    city: zRequiredString,
    address1: zRequiredString,
    address2: z.string().optional(),
  }),
  community: communityRolesFormSchema,
});

/**
 * 名簿/プロフィール
 * 管理人のみアクセス可
 */
export const CSAdminFormValueSchema = z.object({
  baseInfo: baseInfoCommonFormSchema.extend({
    userId: zRequiredString,
    gender: z.enum(["MALE", "FEMALE", "OTHER"], {
      errorMap: () => ({ message: "入力必須項目です" }),
    }),
    birthday: zPastDateString,
    postalCode: zRequiredString,
    prefecture: zRequiredString,
    city: zRequiredString,
    address1: zRequiredString,
    address2: z.string().optional(),
  }),
  community: communityRolesFormSchema,
  residentInfo: residentInfoFormSchema,
});
