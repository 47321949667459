import React, { useCallback, useMemo, useState } from "react";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import styled from "styled-components";
import { recruitmentIsOpened } from "../../../apiClients/recruitment";
import { useToast } from "../../../components/Toast";
import { BreadCrumb } from "../../../components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import CloseRecruitmentModal from "../../../features/BoardEducation/Recruitment/recruitment/CloseRecruitmentModal";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import DeleteOpenRecruitmentModal from "../../../features/BoardEducation/Recruitment/recruitment/DeleteOpenRecruitmentModal";
import { RecruitmentDetailCard } from "../../../features/BoardEducation/Recruitment/recruitment/RecruitmentDetailCard";
import { useBERecruitmentDetail } from "src/hooks/query/boardEducation/recruitment/recruitmentDetail";
import {
  closeBERecruitment,
  deleteBEOpenRecruitment,
} from "src/apiClients/boardEducation/recruitment";
import { CloseRecruitmentScheduleModal } from "src/features/BoardEducation/Recruitment/recruitment/CloseRecruitmentScheduleModal";

export default function BERecruitmentDetailPage() {
  const { id: recruitmentId } = usePolyfitLocationQuery(
    "BE_ADMIN_RECRUITMENT_DETAIL",
    { id: "" }
  );
  const history = usePolyfitHistory();
  const toast = useToast();

  const { recruitment, isLoading, refetch, isError, error } =
    useBERecruitmentDetail(recruitmentId);
  const [isCloseRecruitment, setIsCloseRecruitment] = useState<boolean>(false);
  const [isCloseRecruitmentSchedule, setIsCloseRecruitmentSchedule] =
    useState<boolean>(false);
  const [isDeleteRecruitment, setIsDeleteRecruitment] =
    useState<boolean>(false);

  const onEditRecruitment = useCallback(() => {
    history.push({
      to: "BE_ADMIN_RECRUITMENT_EDIT_OPENED",
      query: { id: recruitmentId },
    });
  }, []);

  const onOpenModalCloseRecruitment = useCallback(() => {
    setIsCloseRecruitment(true);
  }, []);

  const onOpenModalCloseRecruitmentSchedule = useCallback(() => {
    setIsCloseRecruitmentSchedule(true);
  }, []);
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmitCloseRecruitment = useCallback(async () => {
    setDoubleClickBlocked(false);
    try {
      await closeBERecruitment(recruitmentId);
      await refetch();
      toast.success("募集の掲載を終了しました");
    } catch (e) {
      toast.error("募集の掲載の終了に失敗しました");
    } finally {
      setIsCloseRecruitment(false);
      setDoubleClickBlocked(false);
    }
  }, []);

  const onCancelCloseRecruitment = useCallback(() => {
    setIsCloseRecruitment(false);
  }, []);

  const onCancelCloseRecruitmentSchedule = useCallback(() => {
    setIsCloseRecruitmentSchedule(false);
  }, []);

  const onOpenModalDeleteRecruitment = useCallback(() => {
    setIsDeleteRecruitment(true);
  }, []);

  const onSubmitDeleteRecruitment = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await deleteBEOpenRecruitment(recruitmentId);
      toast.success("募集を削除しました");
      history.push({
        to: "BE_ADMIN_RECRUITMENT_LIST",
      });
    } catch (e) {
      toast.error("募集の削除に失敗しました");
    } finally {
      setIsDeleteRecruitment(false);
    }
    setDoubleClickBlocked(false);
  }, []);

  const onCancelDeleteRecruitment = useCallback(() => {
    setIsDeleteRecruitment(false);
  }, []);

  const isRecruitmentStarted = useMemo(() => {
    if (!recruitment) return false;

    const firstDay = new Date(
      recruitment.schedule.reduce((a, b) =>
        new Date(a.date) < new Date(b.date) ? a : b
      ).date
    );
    firstDay.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return firstDay <= today;
  }, [recruitment]);

  if (isLoading) {
    return <></>;
  }

  if (isError || !recruitment) {
    return <span>{error?.message || "募集情報が見つかりません"}</span>;
  }

  return (
    <>
      <BreadCrumb>
        <Link
          to={{
            pathname: "/board-education/recruitment/list",
          }}
        >
          募集
        </Link>
        <LinkWrap>{recruitment.title}</LinkWrap>
      </BreadCrumb>
      <DetailContainer>
        {recruitment.status !== "CLOSED" && (
          <ControllerWrapper>
            {!isRecruitmentStarted && (
              <>
                <ControllerEditLabel onClick={onEditRecruitment}>
                  編集
                </ControllerEditLabel>
                <Separator />
                <ControllerEditLabel onClick={onOpenModalCloseRecruitment}>
                  掲載を終了
                </ControllerEditLabel>
                <Separator />
                <ControllerEditLabel
                  onClick={onOpenModalCloseRecruitmentSchedule}
                >
                  募集を停止
                </ControllerEditLabel>
                <Separator />
              </>
            )}
            <ControllerCloseLabel onClick={onOpenModalDeleteRecruitment}>
              募集を削除
            </ControllerCloseLabel>
          </ControllerWrapper>
        )}

        {recruitment.status === "CLOSED" && (
          <ControllerWrapper>
            <ControllerCloseLabel onClick={onOpenModalDeleteRecruitment}>
              募集を削除
            </ControllerCloseLabel>
          </ControllerWrapper>
        )}
        <RecruitmentDetailCard recruitment={recruitment} />
        <Margin marginBottom={24} />
        {recruitmentIsOpened(recruitment) && (
          <Button size="large" fill style={{ width: "100%" }} disabled={true}>
            応募中
          </Button>
        )}
        {!recruitmentIsOpened(recruitment) && (
          <Button
            size="large"
            fill
            style={{
              width: "100%",
              color: "#FFFFFF",
              backgroundColor: "#99C9Eb",
            }}
          >
            この募集は終了しました
          </Button>
        )}
      </DetailContainer>
      {!!isCloseRecruitment && (
        <CloseRecruitmentModal
          title={recruitment.title}
          onSubmit={onSubmitCloseRecruitment}
          onClose={onCancelCloseRecruitment}
        />
      )}
      {!!isCloseRecruitmentSchedule && (
        <CloseRecruitmentScheduleModal
          recruitment={recruitment}
          onClose={onCancelCloseRecruitmentSchedule}
          refetch={refetch}
        />
      )}
      {!!isDeleteRecruitment && (
        <DeleteOpenRecruitmentModal
          title={recruitment.title}
          onSubmit={onSubmitDeleteRecruitment}
          onClose={onCancelDeleteRecruitment}
          disabled={doubleClickBlocked}
        />
      )}
    </>
  );
}

const LinkWrap = styled.span`
  font-weight: bold;
`;

// TODO: 作成プレビュー側とCSS/コンポーネントを共通化
const DetailContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding-bottom: 30px;
`;

const ControllerWrapper = styled.div`
  margin: 8px;
  margin-left: auto;
  width: fit-content;
`;

const Separator = styled.span`
  border: 1px solid #d3dae6;
  margin: 16px;
`;

const ControllerEditLabel = styled.button`
  font-size: 14px;
  cursor: pointer;
  color: #005ec4;
  background-color: transparent;
  border: 0;
`;

const ControllerCloseLabel = styled.button`
  font-size: 14px;
  cursor: pointer;
  color: #bd271e;
  background-color: transparent;
  border: 0;
`;
