import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageHeader } from "../../../components/Page";
import { usePolyfitLocationQuery, usePolyfitUrl } from "../../../hooks/router";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { BreadCrumb } from "../../../components/Common/BreadCrumb";
import AppliedRecruitmentContent from "../../../features/BoardEducation/Recruitment/application/AppliedRecruitmentContent";
import { Avatar } from "../../../components/Common/Avatar";
import { Title } from "../../../components/Title";
import { toDisplayDateFormatYearMonth } from "../../../utils/time";
import {
  dayOfWeekOptions,
  getDayOfWeekText,
} from "../../../utils/types/dayOfWeek";
import { getGenderText } from "../../../utils/types/gender";
import { getAgeGroupText } from "../../../utils/types/ageGroup";
import { Spinner } from "../../../components/icons/Spinner";
import { getCommunityUserTypeText } from "../../../features/Member/Community/utils/utils";
import {
  useExtractSchools,
  useFindAdditionalCommunities,
  useFindMainCommunity,
} from "src/features/Member/Community/utils/hooks";
import { useBEResidentUserById } from "src/hooks/query/boardEducation/users/useBEResidentUserById";
import { useGetManagedOrganization } from "src/hooks/query/boardEducation/organizations/organization";
import { zIndexes } from "src/zIndex";
import { BEEditBaseInfoModal } from "src/features/BoardEducation/Member/BEEditBaseInfoModal";
import { Community } from "@shared/types/community";
import { useBEResidentUsers } from "src/hooks/query/boardEducation/users/useBEResidentUsers";
import { csRoleOptions, isCsAdminRole } from "src/utils/types/role";
import { BEEditRoleModal } from "src/features/BoardEducation/Member/BEEditRoleModal";
import { useBERecruitmentApplicantListByAccountId } from "src/hooks/query/boardEducation/recruitmentApplication/recruitmentApplicationList";
import { BELeaveModal } from "src/features/BoardEducation/Member/BELeaveModal";
import { calculateDeclinedRate } from "src/features/common/Recruitment/application/utils/calculateDeclinedRate";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");
export const SettingButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  padding-bottom: 10px;
  cursor: pointer;
`;

/**
 * BE名簿のメンバー詳細ページ
 */
export default function BEMemberPage() {
  const { accountId, communityId } = usePolyfitLocationQuery(
    "BE_ADMIN_MEMBER_DETAIL",
    {
      accountId: "",
      communityId: "",
    }
  );
  const {
    memberUser,
    getUser,
    isLoading: isMemberLoading,
    isError,
    error,
  } = useBEResidentUserById(accountId, communityId);
  const { residents } = useBEResidentUsers();

  const { organization } = useGetManagedOrganization();
  const { recruitmentList, isLoading, refetch } =
    useBERecruitmentApplicantListByAccountId(accountId);

  const userRole = memberUser
    ? memberUser.communityRoles.find((role) => role.communityId === communityId)
        ?.role.name
    : undefined;
  /**
   * 管理者が1人かどうかの判定
   */
  const isLastCSAdminUser: boolean = (() => {
    // 自身がCSAdminかどうかの判定
    if (!memberUser) return false;
    if (!userRole) return false;
    if (!isCsAdminRole(userRole)) {
      return false;
    }

    // 自身が1人のみのCSAdminかどうかの判定
    const adminUsers = residents
      .filter((user) => {
        return user.communityId === communityId;
      })
      .filter((user) => user.role.name === "CSAdmin");
    return adminUsers.length === 1;
  })();

  /**
   * 設定モーダルの表示制御
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditInfoOpen, setIsEditInfoOpen] = useState<boolean>(false);
  const [isEditRoleOpen, setIsEditRoleOpen] = useState<boolean>(false);
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);

  const communityRoles = memberUser?.communityRoles ?? [];

  const {
    elementarySchools: elementarySchoolCommunities,
    juniorHighSchools: juniorHighSchoolCommunities,
  } = useExtractSchools<Community>(organization?.communities ?? []);
  const mainElementarySchool = useFindMainCommunity(
    elementarySchoolCommunities,
    communityRoles
  );
  const mainJuniorHighSchool = useFindMainCommunity(
    juniorHighSchoolCommunities,
    communityRoles
  );
  const elementarySchools = useFindAdditionalCommunities(
    elementarySchoolCommunities,
    communityRoles
  );
  const juniorHighSchools = useFindAdditionalCommunities(
    juniorHighSchoolCommunities,
    communityRoles
  );

  const backUrl = usePolyfitUrl({ to: "BE_ADMIN_MEMBER_LIST" });

  const declineRate = useMemo(
    () => calculateDeclinedRate(recruitmentList),
    [recruitmentList]
  );

  // 現在の校区の情報を取得
  const currentSelectedCommunity =
    mainElementarySchool?.communityRole.communityId === communityId
      ? mainElementarySchool
      : mainJuniorHighSchool;

  if (isError) {
    //エラー処理
    if (!memberUser || memberUser.id == "") {
      return <>指定されたユーザーは存在しません</>;
    } else if (
      error?.message == "権限がありません" ||
      error?.message == "このメンバー情報の閲覧権限がありません"
    ) {
      return <>この画面の閲覧権限がありません</>;
    } else {
      console.error(error);
      return <>エラーが発生しました</>;
    }
  } else {
    // Loading
    if (isMemberLoading || memberUser == undefined) {
      return (
        <NameListPage>
          <BreadCrumb>
            <Link to={{ pathname: "/board-education/member/list" }}>名簿</Link>
            <LinkWrap>メンバー</LinkWrap>
          </BreadCrumb>
          <NameListHeadingRow>
            <NameListHeading>
              {/* モバイル時のみ戻るボタン表示 */}
              <MobileMemberDiv>
                <PageHeader backTo={backUrl}>
                  <Title>メンバー</Title>
                </PageHeader>
              </MobileMemberDiv>
              <PCMemberDiv>
                <Title>メンバー</Title>
              </PCMemberDiv>
            </NameListHeading>
          </NameListHeadingRow>
          <MemberWrap>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          </MemberWrap>
        </NameListPage>
      );
    } //エラーがない場合の画面
    return (
      <NameListPage>
        <BreadCrumb>
          <Link to={{ pathname: "/board-education/member/list" }}>名簿</Link>
          <LinkWrap>メンバー</LinkWrap>
        </BreadCrumb>
        <NameListHeadingRow>
          <NameListHeading>
            {/* モバイル時のみ戻るボタン表示 */}
            <MobileMemberDiv>
              <PageHeader backTo={backUrl}>
                <Title>メンバー</Title>
              </PageHeader>
            </MobileMemberDiv>
            <PCMemberDiv>
              <Title>メンバー</Title>
            </PCMemberDiv>
          </NameListHeading>
          <SettingButtonDiv>
            <div>
              <SettingButton
                onClick={() => {
                  if (isOpen) {
                    setIsEditInfoOpen(false);
                    setIsEditRoleOpen(false);
                    setIsLeaveOpen(false);
                  }
                  setIsOpen(!isOpen);
                }}
              >
                …
              </SettingButton>
            </div>
            {isOpen && organization != null && (
              <>
                <EditMenuTab>
                  <SettingEditInfo
                    onClick={() => {
                      setIsEditInfoOpen(!isEditInfoOpen);
                    }}
                  >
                    情報の編集
                    {isEditInfoOpen && (
                      <BEEditBaseInfoModal
                        member={memberUser}
                        onClose={() => {
                          setIsEditInfoOpen(false);
                        }}
                        getUser={async () => {
                          await getUser();
                        }}
                        accountId={accountId}
                        communityId={communityId}
                      />
                    )}
                  </SettingEditInfo>

                  <SettingEditInfo
                    onClick={() => {
                      setIsEditRoleOpen(!isEditRoleOpen);
                    }}
                  >
                    権限を変更
                    {isEditRoleOpen && (
                      <BEEditRoleModal
                        user={memberUser.user}
                        roleName={userRole || ""}
                        onClose={() => {
                          setIsEditRoleOpen(false);
                        }}
                        getUser={async () => {
                          await getUser();
                        }}
                        isLastCSAdminUser={isLastCSAdminUser}
                        accountId={accountId}
                        communityId={communityId}
                      />
                    )}
                  </SettingEditInfo>
                  <SettingEditInfo
                    onClick={() => {
                      setIsLeaveOpen(!isLeaveOpen);
                    }}
                  >
                    退会
                    {isLeaveOpen && (
                      <BELeaveModal
                        account={memberUser}
                        onClose={() => {
                          setIsLeaveOpen(false);
                        }}
                        isLastAdminUser={isLastCSAdminUser}
                        currentCommunityId={communityId}
                      />
                    )}
                  </SettingEditInfo>
                </EditMenuTab>
                <MenuBackGround onClick={() => setIsOpen(false)} />
              </>
            )}
          </SettingButtonDiv>
        </NameListHeadingRow>
        {/* メンバーの基本情報 */}
        <MemberWrap>
          <PreUserNameCell>
            {/* 画像 */}
            <PictureWrap>
              <Avatar
                src={memberUser?.user.picture || defaultUserImg}
                alt={memberUser?.user.name}
                size={80}
              />
              {/* 名前 */}
              <Name>
                <KanaNameDisplay>
                  {memberUser?.user.baseInfo?.lastNameKana}{" "}
                  {memberUser?.user.baseInfo?.firstNameKana}
                </KanaNameDisplay>
                <NameDisplay>
                  {memberUser?.user.baseInfo?.lastName}{" "}
                  {memberUser?.user.baseInfo?.firstName}
                </NameDisplay>
                {recruitmentList && recruitmentList.length > 0 && (
                  <DeclineRateTag>不参加率 {declineRate}％</DeclineRateTag>
                )}
              </Name>
            </PictureWrap>

            {/* 基本情報 */}
            <div style={{ padding: "15px", flex: 1 }}>
              <BaseInfoWrap>
                <BaseInfoRow>
                  <>
                    <tr>
                      <BaseInfoTh>現在の校区</BaseInfoTh>
                      <BaseInfoTd>
                        {currentSelectedCommunity?.community?.name ?? ""}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>性別</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser.user.baseInfo?.gender &&
                          getGenderText(memberUser.user.baseInfo?.gender)}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>年代</BaseInfoTh>
                      <BaseInfoTd>
                        {getAgeGroupText(memberUser.user.baseInfo?.ageGroup)}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>生年月日</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser.user.baseInfo?.birthday &&
                          toDisplayDateFormatYearMonth(
                            memberUser.user.baseInfo?.birthday
                          )}
                      </BaseInfoTd>
                    </tr>
                  </>

                  {memberUser.user.email && (
                    <tr>
                      <BaseInfoTh>メールアドレス</BaseInfoTh>
                      <BaseInfoTd>{memberUser.user.email}</BaseInfoTd>
                    </tr>
                  )}
                  {memberUser.user.baseInfo?.phoneNumber && (
                    <tr>
                      <BaseInfoTh>電話番号</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser.user.baseInfo?.phoneNumber}
                      </BaseInfoTd>
                    </tr>
                  )}
                  <tr>
                    <BaseInfoTh>権限</BaseInfoTh>
                    <BaseInfoTd>
                      {
                        csRoleOptions.find((role) => role.value === userRole)
                          ?.text
                      }
                    </BaseInfoTd>
                  </tr>

                  <>
                    <tr>
                      <BaseInfoTh>住所</BaseInfoTh>
                      <BaseInfoTd>
                        〒{memberUser.user.baseInfo?.postalCode}{" "}
                        {memberUser.user.baseInfo?.prefecture}
                        {memberUser.user.baseInfo?.city}
                        {memberUser.user.baseInfo?.address1}
                        {memberUser.user.baseInfo?.address2}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>希望活動曜日</BaseInfoTh>
                      <BaseInfoTd>
                        {!!memberUser.user.residentInfo?.preferredDays &&
                        memberUser.user.residentInfo?.preferredDays?.length > 0
                          ? memberUser.user.residentInfo?.preferredDays
                              ?.sort((a, b) => {
                                const indexA = dayOfWeekOptions.findIndex(
                                  (dayObj) => dayObj.value === a
                                );
                                const indexB = dayOfWeekOptions.findIndex(
                                  (dayObj) => dayObj.value === b
                                );
                                return indexA - indexB;
                              })
                              .map((day) => getDayOfWeekText(day) ?? "")
                              .join("、")
                          : "該当なし"}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>スキル・資格</BaseInfoTh>
                      <BaseInfoTd>
                        {[
                          ...(memberUser.user.residentInfo?.teacherLicenses ??
                            []),
                          ...(memberUser.user.residentInfo?.medicalLicenses ??
                            []),
                          ...(memberUser.user.residentInfo?.skills ?? []),
                        ].join("、")}
                        {[
                          ...(memberUser.user.residentInfo?.teacherLicenses ??
                            []),
                          ...(memberUser.user.residentInfo?.medicalLicenses ??
                            []),
                          ...(memberUser.user.residentInfo?.skills ?? []),
                        ].length === 0 && "該当なし"}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>希望する活動内容（有償・無償）</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser.user.residentInfo?.preferredPaid &&
                        memberUser.user.residentInfo.preferredPaid.includes(
                          "PAID"
                        ) &&
                        memberUser.user.residentInfo.preferredPaid.includes(
                          "FREE"
                        )
                          ? "どちらでも"
                          : memberUser.user.residentInfo?.preferredPaid &&
                            memberUser.user.residentInfo.preferredPaid.includes(
                              "PAID"
                            )
                          ? "有償"
                          : memberUser.user.residentInfo?.preferredPaid &&
                            memberUser.user.residentInfo.preferredPaid.includes(
                              "FREE"
                            )
                          ? "無償"
                          : "該当なし"}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>希望ボランティア</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser.user.residentInfo?.preferredVolunteerType &&
                          (
                            memberUser.user.residentInfo
                              .preferredVolunteerType ?? []
                          ).join("、")}
                        {(
                          memberUser.user.residentInfo
                            ?.preferredVolunteerType ?? []
                        ).length === 0 && "該当なし"}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>所属団体</BaseInfoTh>
                      <BaseInfoTd>
                        {[
                          ...(memberUser.user.residentInfo?.privateEnterprise
                            ? [memberUser.user.residentInfo?.privateEnterprise]
                            : []),
                          ...(memberUser.user.residentInfo?.university
                            ? [memberUser.user.residentInfo?.university]
                            : []),
                          ...(memberUser.user.residentInfo?.externalOrganization
                            ? [
                                memberUser.user.residentInfo
                                  ?.externalOrganization,
                              ]
                            : []),
                          ...(memberUser.user.residentInfo?.pta
                            ? [memberUser.user.residentInfo?.pta]
                            : []),
                        ].join("、")}
                        {[
                          ...(memberUser.user.residentInfo?.privateEnterprise
                            ? [memberUser.user.residentInfo?.privateEnterprise]
                            : []),
                          ...(memberUser.user.residentInfo?.university
                            ? [memberUser.user.residentInfo?.university]
                            : []),
                          ...(memberUser.user.residentInfo?.externalOrganization
                            ? [
                                memberUser.user.residentInfo
                                  ?.externalOrganization,
                              ]
                            : []),
                          ...(memberUser.user.residentInfo?.pta
                            ? [memberUser.user.residentInfo?.pta]
                            : []),
                        ].length === 0 && "該当なし"}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>最寄りの小学校区</BaseInfoTh>
                      <BaseInfoTd>
                        {mainElementarySchool?.community?.name ?? ""}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>最寄りの小学校区での役割</BaseInfoTh>
                      <BaseInfoTd>
                        {getCommunityUserTypeText(
                          mainElementarySchool?.communityRole?.type
                        )}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>最寄りの中学校区</BaseInfoTh>
                      <BaseInfoTd>
                        {mainJuniorHighSchool?.community.name ?? ""}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>最寄りの中学校区での役割</BaseInfoTh>
                      <BaseInfoTd>
                        {getCommunityUserTypeText(
                          mainJuniorHighSchool?.communityRole.type
                        )}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>追加小学校区</BaseInfoTh>
                      <BaseInfoTd>
                        {elementarySchools.length > 0 &&
                          elementarySchools
                            .map((community) => community.name)
                            .join("、")}
                      </BaseInfoTd>
                    </tr>
                    <tr>
                      <BaseInfoTh>追加中学校区</BaseInfoTh>
                      <BaseInfoTd>
                        {juniorHighSchools.length > 0 &&
                          juniorHighSchools
                            .map((community) => community.name)
                            .join("、")}
                      </BaseInfoTd>
                    </tr>
                  </>
                </BaseInfoRow>
              </BaseInfoWrap>
            </div>
          </PreUserNameCell>
        </MemberWrap>

        {/* 過去の応募状態は校区横断で表示する */}
        <>
          <Spacer />
          <AppliedRecruitmentContent
            isLoading={isLoading}
            recruitmentList={recruitmentList}
            refetch={refetch}
          />
        </>
        <Spacer />
      </NameListPage>
    );
  }
}

const MenuBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const MobileMemberDiv = styled.div`
  @media (min-width: 1279px) {
    display: none;
  }
`;

const PCMemberDiv = styled.h1`
  padding-bottom: 18px;
  padding-top: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const Spacer = styled.div`
  height: 20px;
`;

const SettingEditInfo = styled.div`
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const SettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

const EditMenuTab = styled(SettingEditTab)`
  z-index: ${zIndexes.notModalMenu};
`;

const NameListPage = styled.div`
  position: relative;
`;

const NameListHeadingRow = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const NameListHeading = styled.div``;

const Name = styled.div`
  color: #343741;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 40px 0 20px;
  @media (max-width: 1279px) {
    width: 100%;
    padding: 0;
    padding-left: 44px;
  }
`;

const NameDisplay = styled.div`
  font-weight: 700;
  font-size: 22px;
`;

const PreUserNameCell = styled.div`
  padding: 14px 0;
  display: grid;
  grid-template-columns: minmax(auto, 500px) 1fr;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const MemberWrap = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
`;

const BaseInfoWrap = styled.table`
  font-weight: normal;
  border-collapse: collapse;
  text-align: left;
`;

const BaseInfoTh = styled.th`
  padding: 5px 15px;
  color: #69707d;
  font-weight: normal;
  vertical-align: top;
  @media (max-width: 1279px) {
    min-width: 145px;
    padding: 5px 0;
    font-size: 14px;
  }
`;

const BaseInfoTd = styled.td`
  word-break: break-all;
  padding: 5px 15px;
  color: #343741;
  @media (max-width: 1279px) {
    padding: 5px 0;
    font-size: 14px;
  }
`;

const PictureWrap = styled.div`
  padding: 20px;
  display: flex;
`;

const BaseInfoRow = styled.tbody``;

const SettingButtonDiv = styled.div`
  margin-left: auto;
  text-align: right;
  position: relative;
  width: 152px;
`;

const KanaNameDisplay = styled.div`
  font-size: 14px;
`;

const DeclineRateTag = styled.div`
  display: inline-block;
  background-color: #f1d86f;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 3px;
  margin-top: 8px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
`;
