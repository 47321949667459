import styled, { css } from "styled-components";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useForm } from "react-hook-form";
import { zIndexes } from "../../../zIndex";
import { ModalSubmitButtons } from "../../../components/Modal";
import { applicationStatusOptions } from "../../../apiClients/recruitmentApplication";
import { DatePicker } from "../../../components/form/DatePicker";
import { Label } from "../../../components/form/Label";
import { Margin } from "../../../components/Margin";
import {
  approvalStatusOptions,
  participantApprovalStatusOptions,
} from "@shared/types/chatRoom";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { isCsAdminRole, isCsMemberRole } from "../../../utils/types/role";
import { TextField } from "src/components/form/TextField";
import { ChatRoomFilterType, ChatRoomType } from "@shared/types/chatRoom";
import { BREAKPOINTS } from "src/components/Responsive";

export type Props = {
  setChatRoomFilter: Dispatch<SetStateAction<ChatRoomFilterType>>;
  chatRoomFilter: ChatRoomFilterType;
  onClose: () => void;
  resetFilter: () => void;
};

export const ChatRoomFilter = ({
  onClose,
  chatRoomFilter,
  setChatRoomFilter,
  resetFilter,
}: Props) => {
  const user = useCurrentUser();
  const { handleSubmit, register, control, watch, setValue } =
    useForm<ChatRoomFilterType>({
      defaultValues: chatRoomFilter,
    });

  const watchChatRoomType = watch("type");
  const chatRoomName = watch("chatRoomName");

  const onSubmit = (data: ChatRoomFilterType) => {
    setChatRoomFilter(data);
    onClose();
  };

  const onReset = () => {
    resetFilter();
    onClose();
  };

  const onChatRoomTypeChange = () => {
    setValue("recruitmentName", "");
    setValue("recruitmentSchedule", null);
    setValue("applicationStatus", "");
    setValue("chatRoomName", "");
    setValue("approvalStatus", "");
    setValue("participantChatRoomApprovalStatus", "");
  };

  const showFilter = useCallback(
    (type: ChatRoomType) => {
      if (watchChatRoomType === "")
        return type === ChatRoomType.RECRUITMENT_APPLICATION;
      return type === watchChatRoomType;
    },
    [watchChatRoomType]
  );
  const disbledFilter = useCallback(
    (type: ChatRoomType) => {
      if (watchChatRoomType === "")
        return type !== ChatRoomType.RECRUITMENT_APPLICATION;
      return type !== watchChatRoomType;
    },
    [watchChatRoomType]
  );

  return (
    <>
      <FilterModalWrap>
        <FilterModal>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ScrollArea>
              <>
                <RadioInputContainer>
                  <Label>
                    <input
                      type="radio"
                      value={ChatRoomType.RECRUITMENT_APPLICATION}
                      defaultChecked={chatRoomFilter.type === ""}
                      {...register("type", {
                        onChange: () => onChatRoomTypeChange(),
                      })}
                    />
                    応募メッセージ
                  </Label>
                </RadioInputContainer>

                {showFilter(ChatRoomType.RECRUITMENT_APPLICATION) && (
                  <>
                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION
                        )}
                      >
                        募集名
                      </Label>
                      <StyledInput
                        {...register("recruitmentName")} // フォームコントロールと結びつける
                        placeholder="募集名を入力"
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION
                        )}
                      >
                        応募日
                      </Label>
                      <DatePicker
                        control={control}
                        name="recruitmentSchedule"
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION
                        )}
                      >
                        応募ステータス
                      </Label>
                      <InputSelect
                        {...register("applicationStatus")}
                        // @ts-expect-error todo
                        placeholder="応募ステータスを選択"
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION
                        )}
                      >
                        {[
                          { value: "", text: "すべて" },
                          ...applicationStatusOptions,
                        ].map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </InputSelect>
                    </FieldContainer>
                  </>
                )}
              </>

              <>
                <Margin marginTop={20} />
                <Divider />
                <Margin marginTop={12} />

                <RadioInputContainer>
                  <Label>
                    <input
                      type="radio"
                      value={ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION}
                      {...register("type", {
                        onChange: () => onChatRoomTypeChange(),
                      })}
                    />
                    個別メッセージ
                  </Label>
                </RadioInputContainer>

                {showFilter(ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION) &&
                  isCsAdminRole(user.role) && (
                    <>
                      <FieldContainer>
                        <Label
                          size="s"
                          marginBottom={4}
                          disabled={disbledFilter(
                            ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION
                          )}
                        >
                          承認ステータス
                        </Label>
                        <InputSelect
                          {...register("approvalStatus")}
                          // @ts-expect-error todo
                          placeholder="承認ステータス"
                          disabled={disbledFilter(
                            ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION
                          )}
                        >
                          {[
                            { value: "", text: "すべて" },
                            ...approvalStatusOptions,
                          ].map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </InputSelect>
                      </FieldContainer>
                      <FieldContainer>
                        <Label
                          size="s"
                          marginBottom={4}
                          disabled={disbledFilter(
                            ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION
                          )}
                        >
                          名前
                        </Label>
                        <TextField
                          value={chatRoomName}
                          onChange={(e) => {
                            setValue("chatRoomName", e);
                          }}
                          placeholder="名前を入力"
                          disabled={disbledFilter(
                            ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION
                          )}
                        />
                      </FieldContainer>
                    </>
                  )}
              </>

              <>
                <Margin marginTop={20} />
                <Divider />
                <Margin marginTop={12} />

                <RadioInputContainer>
                  <Label>
                    <input
                      type="radio"
                      value={ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION}
                      {...register("type", {
                        onChange: () => onChatRoomTypeChange(),
                      })}
                    />
                    グループメッセージ
                  </Label>
                </RadioInputContainer>

                {showFilter(ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION) && (
                  <>
                    {isCsMemberRole(user.role) && (
                      <FieldContainer>
                        <Label
                          size="s"
                          marginBottom={4}
                          disabled={disbledFilter(
                            ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION
                          )}
                        >
                          招待ステータス
                        </Label>
                        <InputSelect
                          {...register("participantChatRoomApprovalStatus")}
                          // @ts-expect-error todo
                          placeholder="招待ステータス"
                          disabled={disbledFilter(
                            ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION
                          )}
                        >
                          {[
                            { value: "", text: "すべて" },
                            ...participantApprovalStatusOptions,
                          ].map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </InputSelect>
                      </FieldContainer>
                    )}
                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION
                        )}
                      >
                        名前
                      </Label>
                      <TextField
                        value={chatRoomName}
                        onChange={(e) => {
                          setValue("chatRoomName", e);
                        }}
                        placeholder="グループ名を入力"
                        disabled={disbledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION
                        )}
                      />
                    </FieldContainer>
                  </>
                )}
              </>

              <>
                <Margin marginTop={20} />
                <Divider />
                <Margin marginTop={12} />

                <RadioInputContainer>
                  <Label>
                    <input
                      type="radio"
                      value={ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION}
                      {...register("type", {
                        onChange: () => onChatRoomTypeChange(),
                      })}
                    />
                    教育委員会個別メッセージ
                  </Label>
                </RadioInputContainer>
              </>

              <>
                <Margin marginTop={20} />
                <Divider />
                <Margin marginTop={12} />

                <RadioInputContainer>
                  <Label>
                    <input
                      type="radio"
                      value={ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION}
                      {...register("type", {
                        onChange: () => onChatRoomTypeChange(),
                      })}
                    />
                    教育委員会グループチャット
                  </Label>
                </RadioInputContainer>

                {showFilter(ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION) && (
                  <>
                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION
                        )}
                      >
                        招待ステータス
                      </Label>
                      <InputSelect
                        {...register("participantChatRoomApprovalStatus")}
                        // @ts-expect-error todo
                        placeholder="招待ステータス"
                        disabled={disbledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION
                        )}
                      >
                        {[
                          { value: "", text: "すべて" },
                          ...participantApprovalStatusOptions,
                        ].map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </InputSelect>
                    </FieldContainer>
                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION
                        )}
                      >
                        名前
                      </Label>
                      <TextField
                        value={chatRoomName}
                        onChange={(e) => {
                          setValue("chatRoomName", e);
                        }}
                        placeholder="グループ名を入力"
                        disabled={disbledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION
                        )}
                      />
                    </FieldContainer>
                  </>
                )}
              </>
              <>
                <Margin marginTop={20} />
                <Divider />
                <Margin marginTop={12} />

                <RadioInputContainer>
                  <Label>
                    <input
                      type="radio"
                      value={
                        ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                      }
                      {...register("type", {
                        onChange: () => onChatRoomTypeChange(),
                      })}
                    />
                    教育委員会応募メッセージ
                  </Label>
                </RadioInputContainer>

                {showFilter(
                  ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                ) && (
                  <>
                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                        )}
                      >
                        募集名
                      </Label>
                      <StyledInput
                        {...register("recruitmentName")} // フォームコントロールと結びつける
                        placeholder="募集名を入力"
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                        )}
                      >
                        応募日
                      </Label>
                      <DatePicker
                        control={control}
                        name="recruitmentSchedule"
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                        )}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <Label
                        size="s"
                        marginBottom={4}
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                        )}
                      >
                        応募ステータス
                      </Label>
                      <InputSelect
                        {...register("applicationStatus")}
                        // @ts-expect-error todo
                        placeholder="応募ステータスを選択"
                        disabled={disbledFilter(
                          ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                        )}
                      >
                        {[
                          { value: "", text: "すべて" },
                          ...applicationStatusOptions,
                        ].map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </InputSelect>
                    </FieldContainer>
                  </>
                )}
              </>
            </ScrollArea>

            <ModalSubmitButtons
              submitText="決定"
              onCancel={onReset}
              cancelText="クリア"
            />
          </form>
        </FilterModal>
      </FilterModalWrap>
      <ModalBackGround onClick={onClose}></ModalBackGround>
    </>
  );
};

export const FilterModalWrap = styled.div`
  position: relative;
  z-index: ${zIndexes.notModalMenu};
`;

export const ModalBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const FilterModal = styled.div`
  width: 340px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  padding: 14px;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const ScrollArea = styled.div`
  overflow-y: auto;
  max-height: 50vh;
  @media (max-width: ${BREAKPOINTS.SP}) {
    max-height: 40vh;
  }
`;

const FieldContainer = styled.div`
  margin-top: 12px;
`;

const RadioInputContainer = styled.div`
  color: #1a1c21;
  font-weight: bold;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 8px;
  }

  input[type="radio"] {
    margin-right: 8px;
    cursor: pointer;
  }
`;

type InputSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  value?: string;
  disabled?: boolean;
};

const InputSelect = styled.select<InputSelectProps>`
  color: ${(props) => (props.value === "" ? "#aab4c4" : "#343741")};
  ${(props) =>
    props.disabled &&
    css`
      color: #aab4c4;
      background-color: #fbfcfd;
      cursor: not-allowed;
      opacity: 0.5;
      border-color: #ddd;
    `}

  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
`;

const StyledInput = styled.input<React.InputHTMLAttributes<HTMLInputElement>>`
  background: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  display: block;
  width: 100%;
  padding: 12px;
  color: #000000;
  font-size: 14px;

  ${(props) =>
    props.disabled &&
    css`
      color: #aab4c4;
      background-color: #fbfcfd;
      cursor: not-allowed;
      opacity: 0.5;
      border-color: #ddd;

      &::placeholder {
        color: #aab4c4;
      }
    `}
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
`;
