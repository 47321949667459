import React, { JSX, memo } from "react";
import { QuestionContainer, QuestionField } from "./Question";
import { Label } from "../../../components/form/Label";
import styled from "styled-components";
import { CloseButton } from "./closeButton";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form";
import { SurveyQuestions } from "@shared/validator/features/survey.schema";
import { ToggleQuestionTypeField } from "./ToggleQuestionTypeField";

// 記述式用component
type Props = {
  index: number;
  control: Control<SurveyQuestions>;
  onDelete: (index: number) => void;
  isDeletable: boolean;
};
export const OpenEndedQuestionForm = memo(
  ({ index, control, onDelete, isDeletable }: Props): JSX.Element => {
    return (
      <QuestionContainer>
        {isDeletable && <CloseButton onClick={() => onDelete(index)} />}
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問の種類
          </Label>
          <ToggleQuestionTypeField index={index} control={control} />
        </QuestionField>{" "}
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問
          </Label>
          <Controller
            name={`questionList.${index}.text`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField
                  placeholder="例）PTAに質問があれば教えてください"
                  {...field}
                />
                {error && <ErrorText>{error.message}</ErrorText>}
              </>
            )}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            補足説明
          </Label>
          <Controller
            name={`questionList.${index}.description`}
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="例）出来るだけ全ての質問へ回答しますが..."
                {...field}
              />
            )}
          />
        </QuestionField>
      </QuestionContainer>
    );
  }
);

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  ::placeholder {
    color: #aab4c4;
    font-size: 14px;
  }
  &:disabled {
    opacity: 0.6;
  }
  -webkit-appearance: none;
`;

const ErrorText = styled.p`
  color: #bd271e;
  font-size: 14px;
`;
