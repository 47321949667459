import React from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import { toDateIntervalFormat } from "src/utils/time";

interface Props {
  applicant: Pick<RecruitmentApplication, "user" | "recruitment">;
  event: RecruitmentApplication["eventStatuses"][number];
  onSubmit: () => void;
  onClose: () => void;
  disabled: boolean;
}

export default function ApproveApplicationModal({
  applicant,
  event,
  onSubmit,
  onClose,
  disabled,
}: Props) {
  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>{applicant.user.name} を参加決定にしますか？</ModalHeader>
        <ModalBody>
          <ModalContent>
            <div>参加決定を確定すると、お相手に参加決定通知が届きます。</div>
            <InfoSection>
              <InfoTitle>応募タイトル</InfoTitle>
              <InfoText>{applicant.recruitment.title}</InfoText>
            </InfoSection>
            <InfoSection>
              <InfoTitle>開催日時</InfoTitle>
              <InfoText>
                {toDateIntervalFormat(
                  new Date(event.recruitmentSchedule.start),
                  new Date(event.recruitmentSchedule.end)
                )}
              </InfoText>
            </InfoSection>
            <Margin marginTop={4} />
            <ModalSubmitButtons
              submitText="参加決定にする"
              onSubmit={onSubmit}
              onCancel={onClose}
              disabled={disabled}
            />
          </ModalContent>
        </ModalBody>
      </ModalPortal>
    </>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoTitle = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;
