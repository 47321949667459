import { createRoute } from "..";
import AppDefaultLayout from "../../layouts/AppDefaultLayout";
import CommunityUserListPage from "../../pages/internal/community/CommunityUserListPage";
import OrganizationUserListPage from "../../pages/internal/organization/OrganizationUserListPage";
import { ServiceType } from "../../utils/types/serviceType";
/**
 * INTERNAL_ADMINのみが通れるルート
 */
export const INTERNAL_ADMIN_ROUTE_DEFS = {
  INTERNAL_ORGANIZATION_USER_LIST: createRoute<{ organizationId: string }>({
    path: "/internal/organizations/users",
    title: "組織のユーザー一覧",
    component: OrganizationUserListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
  INTERNAL_COMMUNITY_USER_LIST: createRoute<{
    serviceType: ServiceType;
  }>({
    path: "/internal/communities/users",
    title: "コミュニティのユーザー一覧",
    component: CommunityUserListPage,
    layout: AppDefaultLayout,
    route: "INTERNAL_ADMIN",
  }),
};

export const internalAdminRouters = Object.values(INTERNAL_ADMIN_ROUTE_DEFS);
