import { z } from "zod";
import { zRequiredString } from "../rules";

export const baseRequestSchema = z.object({
  currentCommunityId: z.string().optional(),
});

export const baseRestrictRequestSchema = z.object({
  currentCommunityId: zRequiredString,
});

export type BaseRestrictRequestType = z.infer<typeof baseRestrictRequestSchema>;
