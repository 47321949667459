import { z } from "zod";
import { zRequiredString } from "../rules/string";

export const linkCreateSchema = z.object({
  arg: z.object({
    title: zRequiredString,
    url: zRequiredString,
    description: z.string().nullable(),
  }),
});

export type LinkCreateSchemaType = z.infer<typeof linkCreateSchema>;

export const linkUpdateSchema = z.object({
  arg: z.object({
    id: zRequiredString,
    title: zRequiredString,
    url: zRequiredString,
    description: z.string().nullable(),
  }),
});

export type LinkUpdateSchemaType = z.infer<typeof linkUpdateSchema>;

export const linkDeleteSchema = z.object({
  linkId: zRequiredString,
});

export type LinkDeleteSchemaType = z.infer<typeof linkDeleteSchema>;
