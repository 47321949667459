import { z } from "zod";

export const classNumberSchema = z.union([
  z
    .number()
    .min(1, { message: "クラスの値が無効な値です" })
    .max(12, { message: "クラスの値が無効な値です" }),
  z.literal(100),
  z.literal(-1),
]);

export const gradeNumberSchema = z
  .number()
  .min(1, { message: "学年の値が無効な値です" })
  .max(12, { message: "学年の値が無効な値です" });
