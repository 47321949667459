// FIXME: Long lines (>1000 lines) CODE SMELLS
import React, { useEffect, useMemo, useState } from "react";
import { useCurrentUser } from "../../../hooks/recoil/user";
import * as usersApi from "../../../apiClients/users";
import { CurrentUser } from "../../../apiClients/auth";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { Child } from "../../../apiClients/child";
import { useGetChildrenByCondition } from "../../../hooks/query/childrenList";
import { Margin } from "../../../components/Margin";
import { ListPageFilterSetting } from "../../../components/ListPageFilterSetting";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { NoResults } from "../../../features/ListPage/NoResults";
import { Button } from "../../../components/Button";
import { ArrowRightIcon } from "../../../components/icons/ArrowRightIcon";
import { toInputDateFormatYearMonth } from "../../../utils/time";
import { Avatar } from "../../../components/Common/Avatar";
import { MenuModal } from "../../../features/ListPage/MenuModal";
import { ListBulkImportModal } from "../../../features/ListPage/ListBulkImportModal";
import { useFileDownload } from "../../../hooks/useFileDownload";
import { TabNavigation } from "../../../components/TabNavigation";
import * as csvAPI from "../../../apiClients/csv";
import { CSVLoadingModal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import { useChildrenFilter } from "../../../hooks/recoil/childrenFilter";
import { useSearchText } from "../../../hooks/recoil/searchText";
import { getClassName } from "../../../utils/getChildClass";
import { GroupAddIcon } from "../../../components/icons/GroupAddIcon";
import { GroupMenuModal } from "../../../features/ListPage/GroupMenuModal";
import { isCsAdminRole, isPtaAdminRole } from "../../../utils/types/role";
import { Member } from "../../../components/SearchMembersField";
import { Spinner } from "../../../components/icons/Spinner";
import { useExistPostUserGroup } from "../../../hooks/api/postUserGroup";
import { useCurrentCommunityId } from "../../../hooks/router";
import { User } from "../../../apiClients/users";
import { ErrorView } from "src/components/ErrorView";
import { splitTextByKeywords } from "src/utils/highlight";
import { TextWithHighlight } from "src/components/TextWithHighlight";
import { TextField as TextField2 } from "src/components/form/TextField";
import { SearchIcon } from "src/components/icons/SearchIcon";
import { useGetCurrentOrganization } from "../../../hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

/**
 * PTA名簿用ページ（保護者）
 */
export function PTAListParentTab({
  setTab,
}: {
  setTab: (tab: "parent" | "teacher" | "resident") => void;
}) {
  const currentUser: CurrentUser = useCurrentUser();
  const { organization } = useGetCurrentOrganization();

  const [listFilter, setListFilter] = useChildrenFilter(organization);
  const [searchText, setSearchText] = useSearchText();

  const searchCondition = useMemo(() => {
    return { q: searchText, filter: listFilter };
  }, [listFilter, searchText]);

  const { children, isLoading, error } = useGetChildrenByCondition(
    searchCondition,
    {
      enabled: !!currentUser,
    }
  );

  const [showFilter, setShowFilter] = useState(false);
  const isPtaAdmin = isPtaAdminRole(currentUser.role);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenGroupMenu, setIsOpenGroupMenu] = useState(false);
  const [isOpenListBulkImportModal, setIsOpenListBulkImportModal] =
    useState(false);
  const { isGroupExist } = useExistPostUserGroup(isPtaAdmin);

  // for CSV download
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { download } = useFileDownload();
  const toast = useToast();

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const handleClickDownload = async () => {
    setDoubleClickBlocked(true);
    try {
      setIsCSVLoading(true);
      const blob = await csvAPI.exportCsv(
        "PARENT",
        searchCondition.q,
        searchCondition.filter
      );
      const date = toInputDateFormatYearMonth(new Date());
      const fileName = `【外部流出厳禁】PTA名簿_${date}.csv`;
      download(blob, fileName);
    } catch (e) {
      toast.error("CSVのダウンロードに失敗しました");
    } finally {
      setIsCSVLoading(false);
      setDoubleClickBlocked(false);
    }
  };

  /**
   * childrenからmembersを取り出す
   *  accountIdの重複を除去
   */
  const members: Member[] =
    children
      ?.map((child) => {
        return {
          accountId: child.accountId || "",
          name: child.user?.name || "",
          firstNameKana: child.user?.baseInfo?.firstNameKana || "",
          lastNameKana: child.user?.baseInfo?.lastNameKana || "",
        };
      })
      .filter(
        (v, i, a) => a.findIndex((t) => t.accountId === v.accountId) === i
      ) || [];

  const [page, setPage] = useState(1);
  const pagedChildren = useMemo(
    () => children.slice(0, 20 * page),
    [children, page]
  );

  const loadNextPage = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    setPage(1);
  }, [searchCondition]);

  return (
    <>
      <NameListPage>
        <NameListHeadingRow>
          <NameListHeading>名簿</NameListHeading>
          {isPtaAdmin && (
            <div>
              <NameListCSVButtonWrap>
                <CSVOutputButton
                  fill
                  onClick={handleClickDownload}
                  disabled={doubleClickBlocked}
                >
                  CSV出力
                </CSVOutputButton>
                <AdminMenuButton
                  type="button"
                  onClick={() => setIsOpenMenu(!isOpenMenu)}
                >
                  ...
                </AdminMenuButton>

                <MenuModal
                  isOpen={isOpenMenu}
                  onClose={() => setIsOpenMenu(false)}
                />
              </NameListCSVButtonWrap>
              {children.length > 0 && (
                <div>
                  <GroupButton
                    fill
                    onClick={() => setIsOpenGroupMenu(!isOpenGroupMenu)}
                  >
                    <GroupAddIcon />
                    連絡グループ
                  </GroupButton>
                  {isOpenGroupMenu && (
                    <GroupMenuModal
                      isOpen={isOpenGroupMenu}
                      onClose={() => setIsOpenGroupMenu(false)}
                      members={members}
                      isGroupExist={isGroupExist}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </NameListHeadingRow>
        <TabNavigation
          elements={[
            ...(usersApi.inPTA(currentUser)
              ? [
                  {
                    isActive: true,
                    text: "保護者",
                    onclick: () => {
                      setTab("parent");
                      // reset();
                    },
                  },
                  {
                    isActive: false,
                    text: "教員",
                    onclick: () => {
                      setTab("teacher");
                      // reset();
                    },
                  },
                ]
              : []),
            ...(isCsAdminRole(currentUser.role)
              ? [
                  {
                    isActive: false,
                    text: "地域住民",
                    onclick: () => {
                      setTab("resident");
                      // reset();
                    },
                  },
                ]
              : []),
          ]}
        />
        <div style={{ display: "flex", gap: "8px" }}>
          <div style={{ flex: "auto" }}>
            <TextField2
              placeholder="氏名、所属、役職で検索"
              onChange={(text) => setSearchText(text)}
              value={searchText}
              prepend={<SearchIcon size={20} />}
            />
          </div>
          <div>
            <div style={{ height: "100%", display: "flex" }}>
              <Button
                width="112px"
                fill
                size="large"
                color="dark"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
                type="button"
              >
                表示設定
              </Button>
            </div>
            {showFilter && (
              <ListPageFilterSetting
                organization={organization}
                setListFilter={setListFilter}
                listFilter={listFilter}
                onClose={() => {
                  setShowFilter(false);
                }}
              />
            )}
          </div>
        </div>
        <Margin marginBottom={10} />

        {isLoading ? (
          <LoadingCard>
            <Spinner />
          </LoadingCard>
        ) : error ? (
          <ErrorView error={error} />
        ) : children.length === 0 ? (
          <NoResults />
        ) : (
          <NameListTable>
            <thead>
              <NameListColumnNameRow $head={true}>
                <NameListColumn>名前</NameListColumn>
                <NameListColumnWrapper>子の名前</NameListColumnWrapper>
                <NameListClassColumn $isPtaAdmin={isPtaAdmin}>
                  学年
                </NameListClassColumn>
                <NameListClassColumn $isPtaAdmin={isPtaAdmin}>
                  クラス
                </NameListClassColumn>
                {isPtaAdmin && (
                  <NameListColumnWrapper>メールアドレス</NameListColumnWrapper>
                )}
                {isPtaAdmin && (
                  <NameListColumnWrapper>電話番号</NameListColumnWrapper>
                )}
              </NameListColumnNameRow>
            </thead>
            <tbody>
              {pagedChildren.map((child: Child, i: number) => {
                return (
                  <ListColumn
                    child={child}
                    isPtaAdmin={isPtaAdmin}
                    key={child.id ?? "" + i}
                  />
                );
              })}
            </tbody>
          </NameListTable>
        )}

        {children.length > pagedChildren.length && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "16px",
            }}
          >
            <Button
              fill
              size="large"
              color="dark"
              onClick={loadNextPage}
              type="button"
            >
              次の20件を表示
            </Button>
          </div>
        )}
      </NameListPage>

      <ListBulkImportModal
        isOpenListBulkImportModal={isOpenListBulkImportModal}
        setIsOpenListBulkImportModal={setIsOpenListBulkImportModal}
        downloadCsvFile={handleClickDownload}
      />
      {isCSVLoading && <CSVLoadingModal />}
    </>
  );
}

const ListColumn = (props: { child: Child; isPtaAdmin: boolean }) => {
  const { child, isPtaAdmin } = props;
  const [searchText] = useSearchText();
  const user: User = child.user!;
  const history = usePolyfitHistory();
  const { communityId } = useCurrentCommunityId();
  const { organization } = useGetCurrentOrganization();

  const matchResult = useMemo(() => {
    const results = [
      {
        label: "子の名前",
        text: child.childLastName + " " + child.childFirstName,
        terms: splitTextByKeywords(
          child.childLastName + " " + child.childFirstName,
          [searchText]
        ),
      },
      {
        label: "保護者名（かな）",
        text: user.baseInfo?.lastNameKana + " " + user.baseInfo?.firstNameKana,
        terms: splitTextByKeywords(
          user.baseInfo?.lastNameKana + " " + user.baseInfo?.firstNameKana,
          [searchText]
        ),
      },
    ];

    // 検索テキストにmatchしているものだけを取り出す
    return results.filter((result) => result.terms.some((term) => term.hit));
  }, [child, searchText]);

  return (
    <NameListColumnNameRow
      onClick={() =>
        history.push({
          to: "PTA_LIST_MEMBER",
          query: { userId: user.id },
        })
      }
    >
      <UserPicCell>
        <PreUserPicCell>
          <Avatar src={user.picture || defaultUserImg} alt="" size={40} />
        </PreUserPicCell>
        <PreUserNameCell>
          <Name>
            <TextWithHighlight text={user.name} keywords={[searchText]} />
          </Name>
          <NameListOrganizationData>
            {/* 本部 */} {/* 会長 */}
            {user.baseInfo?.belong?.name && (
              <TextWithHighlight
                text={user.baseInfo?.belong?.name}
                keywords={[searchText]}
              />
            )}
            {user.baseInfo?.part?.name && (
              <TextWithHighlight
                text={user.baseInfo?.part?.name}
                keywords={[searchText]}
              />
            )}
          </NameListOrganizationData>
          <MatchWord>
            {matchResult.map((result) => (
              <span key={result.label}>
                {result.label}:{" "}
                <TextWithHighlight text={result.text} keywords={[searchText]} />
              </span>
            ))}
          </MatchWord>
        </PreUserNameCell>
        <NameButtonWrap>
          <Link
            to={{
              pathname: `/pta/list/member`,
              search: `userId=${user.id}&communityId=${communityId}`,
            }}
          >
            &gt;
          </Link>
        </NameButtonWrap>
      </UserPicCell>
      <Panel>
        {/* 子の名前 */}
        <TextWithHighlight
          text={child.childLastName + " " + child.childFirstName}
          keywords={[searchText]}
        />
      </Panel>
      <Panel>
        {/* 学年 */}
        {getGradeLabel(
          child.grade,
          organization?.schoolDisplayType,
          true,
          false,
          "short"
        )}
      </Panel>
      <Panel>
        {/* クラス */}
        {getClassName(child.class)}
      </Panel>
      {isPtaAdmin && (
        <Panel>
          {/* TODO: 権限ないと見れないようにする */}
          <TextWithHighlight text={user.email} keywords={[searchText]} />
        </Panel>
      )}
      {isPtaAdmin && (
        <Panel>
          {/* 電話番号 */}
          {user.baseInfo?.phoneNumber}
        </Panel>
      )}
      <Panel>
        <ArrowRightIcon size={24} />
      </Panel>
    </NameListColumnNameRow>
  );
};

const NameListPage = styled.div`
  margin: 0 auto;
`;

const NameButtonWrap = styled.div`
  margin: auto;
  margin-right: 0;
  padding-right: 10px;
  display: none;
  @media (max-width: 1279px) {
    display: block;
  }
`;

const NameListCSVButtonWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const AdminMenuButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 6px;
  border: none;
  margin-left: 12px;
  padding-bottom: 10px;
  cursor: pointer;
`;

const NameListHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
`;

const NameListHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #1a1c21;
`;

const NameListTable = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameListColumn = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
`;

const NameListColumnWrapper = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const Name = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const MatchWord = styled.span`
  @media (min-width: 1280px) {
    display: none;
  }
`;

const Panel = styled.th`
  color: #343741;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  padding-left: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const NameListColumnNameRow = styled.tr<{ $head?: boolean }>`
  background-color: #fff;
  ${(props) =>
    !props.$head &&
    `border-top: 1px solid #e3e6eb; cursor: pointer; &:hover { filter: brightness(95%);}`};
`;

const UserPicCell = styled.th`
  color: #343741;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const NameListOrganizationData = styled.div`
  display: flex;
  gap: 4px;
  color: #69707d;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  text-align: left;
`;

const PreUserPicCell = styled.div`
  padding: 14px;
`;

const PreUserNameCell = styled.div`
  padding: 14px 0;
`;

const CSVOutputButton = styled(Button)`
  padding: 9.5px 12px;
  width: 112px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const GroupButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9.5px 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;
  margin-left: auto;
`;

const NameListClassColumn = styled.th<{ $isPtaAdmin: boolean }>`
  color: #343741;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  width: ${(props) => (props.$isPtaAdmin ? "80px" : "20%")};
  @media (max-width: 1279px) {
    display: none;
  }
`;
