import * as postUserGroupApi from "../../apiClients/postUserGroup";
import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";

export type GetMyPostUserGroupsArgs = {
  accountId: string;
};
/**
 * アカウントが所属する連絡ユーザーグループ一覧取得
 */
export function useMyPostUserGroupList(
  args: GetMyPostUserGroupsArgs,
  queryOptions: QueryOptions = {},
  inPTA: boolean
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "postUserGroup", "myPostUserGroups", apiContext, args],
    queryFn: inPTA
      ? async () => {
          return await postUserGroupApi.fetchMyPostUserGroups(args);
        }
      : async () => {
          return [];
        },
    ...queryOptions,
  });

  return {
    myPostGroups: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}
