import React, { useCallback, useEffect, useState } from "react";
import { downloadApplicationListPdfByFilter } from "../../../apiClients/recruitmentApplication";
import {
  approveApplication,
  rejectApplication,
  declineApplicationByAdmin,
} from "src/apiClients/recruitmentApplicationEvent";
import ApproveApplicationModal from "src/features/common/Recruitment/application/ApproveApplicationModal";
import RejectApplicationModal from "src/features/common/Recruitment/application/RejectApplicationModal";
import DeclineApplicationModal from "src/features/common/Recruitment/application/DeclineApplicationModal";
import styled from "styled-components";
import { NoResults } from "src/features/ListPage/NoResults";
import { ApplicantTable } from "src/features/Recruitment/application/ApplicantTable";
import { ApplicantCardList } from "src/features/Recruitment/application/ApplicantCardList";
import {
  BREAKPOINTS,
  MobileViewOnly,
  PcViewOnly,
} from "src/components/Responsive";
import CountRecruitmentApplicationBar from "src/features/Recruitment/application/CountRecruitmentApplicationBar";
import { Spinner } from "src/components/icons/Spinner";
import { logger } from "src/utils/logger";
import { usePolyfitHistory } from "src/hooks/router";
import {
  ApplicationStatus,
  RecruitmentApplication,
} from "@shared/types/recruitmentApplication";
import { ResidentRecruitmentApplicationFilter } from "./ResidentRecruitmentApplicationFilter";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { getApplicationListByFilter } from "src/apiClients/recruitmentApplication";
import { Button } from "src/components/Button";
import { Title } from "src/components/Title";
import { Tooltip } from "react-tooltip";
import { useFileDownload } from "src/hooks/useFileDownload";
import { useRecruitmentApplicationCountsForFilter } from "src/hooks/query/recruitments/recruitmentApplication";
import { useModal } from "src/components/Modal";
import { AttendanceModal } from "src/features/common/Recruitment/application/AttendanceModal";
import { downloadApplicationListPdfSchema } from "@shared/validator/features/recruitment-application.schema";

export default function ResidentRecruitmentApplicationListPage() {
  // filter
  const methods = useForm<ResidentRecruitmentApplicationFilter>({
    defaultValues: {
      recruitmentId: undefined,
      name: undefined,
      applicationStatus: undefined,
    },
  });

  const history = usePolyfitHistory();
  const [applicantList, setApplicantList] = useState<
    RecruitmentApplication[] | null
  >(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");

  const getCurrentFormStatusWithDefault = (): {
    status: ApplicationStatus | "ALL";
    name: string;
    recruitmentId: string | undefined;
    recruitmentScheduleId: string | undefined;
  } => {
    const status = methods.getValues("applicationStatus") || "ALL";
    const name = methods.getValues("name") || "";
    const recruitmentId = methods.getValues("recruitmentId") || undefined;
    const recruitmentScheduleId =
      methods.getValues("recruitmentScheduleId") || undefined;
    return {
      status,
      name,
      recruitmentId,
      recruitmentScheduleId,
    };
  };

  const currentFormStatus = getCurrentFormStatusWithDefault();
  const { applicationUserTypeCounts, applicationStatusCount } =
    useRecruitmentApplicationCountsForFilter(
      {
        recruitmentId: currentFormStatus.recruitmentId,
        recruitmentScheduleId: currentFormStatus.recruitmentScheduleId,
        name: name,
        status: currentFormStatus.status,
      },
      {
        keepPreviousData: true,
      }
    );

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const currentFormStatus = getCurrentFormStatusWithDefault();
    const recruitments = await getApplicationListByFilter(
      currentFormStatus.status,
      currentFormStatus.name,
      currentFormStatus.recruitmentId,
      currentFormStatus.recruitmentScheduleId
    );
    setName(currentFormStatus.name); //nameは検索ボタン設定時に適用する
    methods.reset(methods.getValues());
    setApplicantList(recruitments);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  const [selectedApplicant, setSelectedApplicant] = useState<
    RecruitmentApplication | undefined
  >(undefined);
  const [selectedEvent, setSelectedEvent] = useState<
    RecruitmentApplication["eventStatuses"][number] | undefined
  >(undefined);

  const [confirmChangeStatus, setConfirmChangeStatus] = useState<
    "APPLIED" | "APPROVED" | "REJECTED" | "DECLINED" | undefined
  >(undefined);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false); //同時にモーダルが開くことは無いので一つのdoubleClickBlockedで十分

  const handleModalOpen = useCallback(
    (
      modalStatus: "APPROVED" | "REJECTED" | "DECLINED",
      eventId: string,
      applicant: RecruitmentApplication
    ) => {
      const event = applicant.eventStatuses.find((e) => e.id === eventId);
      if (!event) {
        logger.error("有効な日程が見つかりませんでした", {
          modalStatus,
          eventId,
          event,
          applicant,
        });
        return;
      }

      setConfirmChangeStatus(modalStatus);
      setSelectedApplicant(applicant);
      setSelectedEvent(event);
    },
    []
  );

  const [
    isShownAttendanceModal,
    { show: showAttendanceModal, close: closeAttendanceModal },
  ] = useModal();
  const [selectedAttendanceApplicant, setSelectedAttendanceApplicant] =
    useState<RecruitmentApplication | undefined>(undefined);
  const [selectedAttendanceEvent, setSelectedAttendanceEvent] = useState<
    RecruitmentApplication["eventStatuses"][number] | undefined
  >(undefined);
  const handleAttendanceModalOpen = useCallback(
    (applicant: RecruitmentApplication, eventId: string) => {
      const event = applicant.eventStatuses.find((e) => e.id === eventId);
      if (!event) {
        logger.error("有効な日程が見つかりませんでした", {
          eventId,
          event,
          applicant,
        });
        return;
      }

      setSelectedAttendanceApplicant(applicant);
      setSelectedAttendanceEvent(event);
      showAttendanceModal();
    },
    [
      setSelectedAttendanceApplicant,
      setSelectedAttendanceEvent,
      showAttendanceModal,
    ]
  );

  const handleAttendanceModalClose = useCallback(() => {
    setSelectedAttendanceApplicant(undefined);
    setSelectedAttendanceEvent(undefined);
    closeAttendanceModal();
  }, [closeAttendanceModal, setSelectedApplicant, setSelectedEvent]);

  const handleApproveSubmit = useCallback(async () => {
    if (!selectedEvent) return;
    setDoubleClickBlocked(true);
    try {
      await approveApplication(selectedEvent.id);
      await fetchRecruitment();
      setConfirmChangeStatus(undefined);
    } catch (error) {
      logger.error(new Error(`Error during approval process:` + error), {
        selectedEvent,
      });
    } finally {
      setDoubleClickBlocked(false);
    }
  }, [selectedEvent]);

  const handleRejectSubmit = useCallback(
    async (comment: string) => {
      if (!selectedEvent) return;
      setDoubleClickBlocked(true);
      try {
        await rejectApplication(selectedEvent.id, comment);
        await fetchRecruitment();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during reject process:` + error), {
          selectedEvent,
        });
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [selectedEvent]
  );

  const handleDeclineSubmit = useCallback(
    async (comment: string) => {
      if (!selectedEvent) return;
      setDoubleClickBlocked(true);
      try {
        await declineApplicationByAdmin(selectedEvent.id, comment);
        await fetchRecruitment();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during decline process:` + error), {
          selectedEvent,
        });
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [selectedEvent]
  );

  const handleClose = useCallback(() => {
    setConfirmChangeStatus(undefined);
    setSelectedApplicant(undefined);
    setSelectedEvent(undefined);
  }, []);

  const handleChangeStatus = useCallback(
    (applicant: RecruitmentApplication, eventId: string) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.target.value) {
          case "APPROVED":
            handleModalOpen(e.target.value, eventId, applicant);
            break;
          case "REJECTED":
            handleModalOpen(e.target.value, eventId, applicant);
            break;
          case "DECLINED":
            handleModalOpen(e.target.value, eventId, applicant);
            break;
          default:
            break;
        }
      },
    []
  );

  const applicantListIsEmpty = applicantList && !applicantList?.length;

  const onSubmit: SubmitHandler<ResidentRecruitmentApplicationFilter> = () => {
    fetchRecruitment();
  };

  const { download } = useFileDownload();

  const currentRecruitmentId = methods.watch("recruitmentId");
  const currentRecruitmentScheduleId = methods.watch("recruitmentScheduleId");
  const isEnabledEventFeature = (
    currentRecruitmentId: string | undefined,
    currentRecruitmentScheduleId: string | undefined
  ) => {
    if (methods.formState.isDirty) {
      return false;
    } else {
      return Boolean(currentRecruitmentId && currentRecruitmentScheduleId);
    }
  };

  return (
    <ApplicantListPage>
      <CountRecruitmentApplicationBar />
      <PageHeader>
        <Title>応募一覧</Title>
        <ButtonContainer>
          <Button
            onClick={() => {
              if (currentRecruitmentId && currentRecruitmentScheduleId) {
                history.push({
                  to: "RESIDENT_RECRUITMENT_APPLICATION_SCAN",
                  query: {
                    recruitmentId: currentRecruitmentId,
                    recruitmentScheduleId: currentRecruitmentScheduleId,
                  },
                });
              }
            }}
            fill
            size="large"
            disabled={
              !isEnabledEventFeature(
                currentRecruitmentId,
                currentRecruitmentScheduleId
              )
            }
            data-tooltip-id={"event-feature-tooltip"}
            data-tooltip-content="「募集タイトル」と「募集日程」の両方がフィルターに設定されている場合のみ、スキャンを開始できます。"
          >
            スキャンを開始
          </Button>
          <Button
            onClick={async () => {
              try {
                const currentFormStatus = getCurrentFormStatusWithDefault();
                const data = downloadApplicationListPdfSchema.parse({
                  recruitmentId: currentFormStatus.recruitmentId,
                  recruitmentScheduleId:
                    currentFormStatus.recruitmentScheduleId,
                });
                const blob = await downloadApplicationListPdfByFilter(data);
                download(blob, "participant_list.pdf");
              } catch (error) {
                logger.error(error, {
                  recruitmentId: currentFormStatus.recruitmentId,
                  recruitmentScheduleId:
                    currentFormStatus.recruitmentScheduleId,
                });
              }
            }}
            fill
            size="large"
            disabled={
              !isEnabledEventFeature(
                currentRecruitmentId,
                currentRecruitmentScheduleId
              )
            }
            data-tooltip-id={"event-feature-tooltip"}
          >
            参加者名簿をPDF出力
          </Button>
          {!isEnabledEventFeature(
            currentRecruitmentId,
            currentRecruitmentScheduleId
          ) ? (
            <Tooltip
              id="event-feature-tooltip"
              place="bottom"
              content="「募集タイトル」と「募集日程」の両方がフィルターに設定されている場合のみ、当日の参加者名簿をPDFで出力できます。"
              style={{ maxWidth: "312px" }}
            />
          ) : null}
        </ButtonContainer>
      </PageHeader>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ResidentRecruitmentApplicationFilter
            applicationUserTypeCounts={applicationUserTypeCounts}
            applicationStatusCounts={applicationStatusCount}
            isSearchWithName={name !== ""}
          />
        </form>
      </FormProvider>

      {isLoading ? (
        <SpinnerCard>
          <Spinner />
        </SpinnerCard>
      ) : applicantListIsEmpty ? (
        <NoResults />
      ) : (
        <>
          <PcViewOnly>
            <ApplicantTable
              fetchRecruitment={fetchRecruitment}
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
              handleAttendanceModalOpen={handleAttendanceModalOpen}
            />
          </PcViewOnly>
          <MobileViewOnly>
            <ApplicantCardList
              fetchRecruitment={fetchRecruitment}
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
              handleAttendanceModalOpen={handleAttendanceModalOpen}
            />
          </MobileViewOnly>
        </>
      )}
      {confirmChangeStatus === "APPROVED" &&
        selectedApplicant &&
        selectedEvent && (
          <ApproveApplicationModal
            applicant={selectedApplicant}
            event={selectedEvent}
            onSubmit={handleApproveSubmit}
            onClose={handleClose}
            disabled={doubleClickBlocked}
          />
        )}
      {confirmChangeStatus === "REJECTED" &&
        selectedApplicant &&
        selectedEvent && (
          <RejectApplicationModal
            applicant={selectedApplicant}
            event={selectedEvent}
            onSubmit={handleRejectSubmit}
            onClose={handleClose}
            disabled={doubleClickBlocked}
          />
        )}
      {confirmChangeStatus === "DECLINED" &&
        selectedApplicant &&
        selectedEvent && (
          <DeclineApplicationModal
            applicant={selectedApplicant}
            event={selectedEvent}
            onSubmit={handleDeclineSubmit}
            onClose={handleClose}
            disabled={doubleClickBlocked}
          />
        )}
      {isShownAttendanceModal &&
        selectedAttendanceEvent &&
        selectedAttendanceApplicant && (
          <AttendanceModal
            applicant={selectedAttendanceApplicant}
            event={selectedAttendanceEvent}
            onClose={handleAttendanceModalClose}
            reFetchRecruitment={fetchRecruitment}
          />
        )}
    </ApplicantListPage>
  );
}

const ApplicantListPage = styled.div`
  margin: 0 auto;
`;

const SpinnerCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding-bottom: 40px;
  padding-top: 40px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
    align-items: end;
  }
`;
