import React from "react";
import styled from "styled-components";
import { BreadCrumb } from "src/components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "src/hooks/router";
import { Button } from "src/components/Button";
import { QRScanner } from "src/features/Recruitment/application/QRScanner/QRScanner";
import { BREAKPOINTS } from "src/components/Responsive";
import { logger } from "src/utils/logger";
import { useRecruitmentDetail } from "src/hooks/query/recruitments/recruitmentDetail";
import { toDateIntervalFormat } from "src/utils/time";

export const ResidentRecruitmentApplicationScanPage = () => {
  const { communityId } = useCurrentCommunityId();
  const history = usePolyfitHistory();
  const { recruitmentId, recruitmentScheduleId } = usePolyfitLocationQuery(
    "RESIDENT_RECRUITMENT_APPLICATION_SCAN",
    { recruitmentId: "", recruitmentScheduleId: "" }
  );

  const { recruitment, isLoading, isError } =
    useRecruitmentDetail(recruitmentId);

  const validateSchedule = () => {
    if (!recruitment) {
      return { isValid: false, error: "募集情報が見つかりません" };
    }

    const schedule = recruitment.schedule.find(
      (s) => s.id === recruitmentScheduleId
    );
    if (!schedule) {
      return { isValid: false, error: "指定された開催日程が見つかりません" };
    }

    return { isValid: true, schedule };
  };

  const handleScanResult = (result: string) => {
    try {
      if (!result.startsWith("http")) {
        logger.error(new Error("URLの形式が不正です"), { result });
        return;
      }
      const url = new URL(result);

      const recruitmentApplicationEventId = url.searchParams.get(
        "recruitmentApplicationEventId"
      );

      if (recruitmentApplicationEventId) {
        history.push({
          to: "RESIDENT_RECRUITMENT_APPLICATION_SCAN_RESULT",
          query: {
            recruitmentId,
            recruitmentScheduleId,
            recruitmentApplicationEventId,
          },
        });
      } else {
        logger.error(new Error("recruitApplicationEventIdが見つかりません"), {
          result,
        });
      }
    } catch (error) {
      logger.error(error, {
        result,
        errorMessage: "QRコードの読み込みに失敗しました",
      });
    }
  };

  const validation = validateSchedule();
  const shouldShowError = isError || !validation.isValid;
  const errorMessage = isError
    ? "データの取得に失敗しました"
    : !validation.isValid
    ? validation.error
    : null;

  return (
    <>
      <BreadCrumb>
        <Link
          to={{
            pathname: "/resident/recruitment/application_list",
            search: `communityId=${communityId}`,
          }}
        >
          応募一覧
        </Link>
        <LinkWrap>QRコードスキャン</LinkWrap>
      </BreadCrumb>

      <ScanContainer>
        <ContentContainer>
          {isLoading ? (
            <div>読み込み中...</div>
          ) : shouldShowError ? (
            <ErrorContainer>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <Button
                color="subPrimary"
                onClick={() =>
                  history.push({
                    to: "RESIDENT_RECRUITMENT_APPLICATION_LIST",
                    query: { communityId },
                  })
                }
              >
                応募一覧に戻る
              </Button>
            </ErrorContainer>
          ) : recruitment && validation.isValid && validation.schedule ? (
            <QRScanner
              onScanResult={handleScanResult}
              header={
                recruitment && validation.isValid && validation.schedule ? (
                  <Box>
                    <RecruitmentInfo>
                      <InfoSection>
                        <InfoTitle>応募タイトル</InfoTitle>
                        <InfoText>{recruitment.title}</InfoText>
                      </InfoSection>
                      <InfoSection>
                        <InfoTitle>開催日時</InfoTitle>
                        <InfoText>
                          {toDateIntervalFormat(
                            new Date(validation.schedule.start),
                            new Date(validation.schedule.end)
                          )}
                        </InfoText>
                      </InfoSection>
                    </RecruitmentInfo>
                  </Box>
                ) : null
              }
            />
          ) : null}
        </ContentContainer>
      </ScanContainer>
    </>
  );
};

const LinkWrap = styled.span`
  font-weight: bold;
`;

const ScanContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 30px;
`;

const ContentContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: ${BREAKPOINTS.SP}) {
    padding: 16px;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
`;

const ErrorMessage = styled.div`
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  color: #d32f2f;
  padding: 16px;
  text-align: center;
  margin: 20px 0;
`;

const Box = styled.div`
  text-align: center;
  margin-top: 4px;
`;

const RecruitmentInfo = styled.div`
  margin-bottom: 24px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & + & {
    margin-top: 16px;
  }
`;

const InfoTitle = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;
