import React from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../../components/Modal";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Margin } from "../../../components/Margin";
import { ModalLabel } from "../../Post/CreatePostModal";
import { Recruitment } from "@shared/types/recruitment";
import {
  declineByRecruitmentApplicationEventBySelfSchema,
  DeclineByRecruitmentApplicationEventBySelfSchema,
} from "@shared/validator/features/recruitmentApplicationEvent.schema";
import { CheckBox } from "src/components/form/CheckBox";
import { Label } from "src/components/form/Label";
import { zodResolver } from "@hookform/resolvers/zod";
import { toDateIntervalFormat } from "src/utils/time";
import {
  canCancelSchedule,
  getDateStatus,
} from "./utils/recruitmentScheduleStatus";

interface Props {
  recruitment: Recruitment;
  onSubmit: (values: DeclineByRecruitmentApplicationEventBySelfSchema) => void;
  onClose: () => void;
}

export default function SelfDeclineApplicationModal({
  recruitment,
  onSubmit,
  onClose,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<DeclineByRecruitmentApplicationEventBySelfSchema>({
    resolver: zodResolver(declineByRecruitmentApplicationEventBySelfSchema),
    defaultValues: {
      recruitmentId: recruitment.id,
      selectedScheduleIds: [],
      comment: "",
    },
    mode: "onChange",
  });

  const hasCancellableSchedules = recruitment.schedule.some((schedule) => {
    const status = getDateStatus(new Date(schedule.date));
    return canCancelSchedule(status);
  });

  const selectedEventScheduleIds = watch("selectedScheduleIds");

  const handleScheduleChange = (scheduleId: string, checked: boolean) => {
    const newSelectedEventScheduleIds = checked
      ? [...selectedEventScheduleIds, scheduleId]
      : selectedEventScheduleIds.filter((id) => id !== scheduleId);
    setValue("selectedScheduleIds", newSelectedEventScheduleIds, {
      shouldValidate: true,
    });
  };

  if (!hasCancellableSchedules) {
    return (
      <ModalPortal onClose={onClose}>
        <ModalHeader>参加を不参加</ModalHeader>
        <ModalBody>
          <div>
            当日のため不参加に変更できません。管理者に直接ご連絡ください。
          </div>
          <ModalSubmitButtons submitText="OK" onSubmit={onClose} />
        </ModalBody>
      </ModalPortal>
    );
  }

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>参加を不参加</ModalHeader>
      <ModalBody>
        <div>
          「{recruitment.title}」への参加を不参加にします。
          一度不参加した募集には再度応募することはできません。
        </div>
        <Margin marginTop={15} />

        <ModalLabel>
          <Label required>不参加にする日程</Label>
        </ModalLabel>
        <ScheduleCheckboxContainer>
          {recruitment.schedule.map((schedule) => {
            const status = getDateStatus(new Date(schedule.date));
            const canDecline = canCancelSchedule(status);

            return (
              <CheckboxLabel key={schedule.id}>
                <CheckBox
                  {...register("selectedScheduleIds")}
                  onChange={(e) =>
                    handleScheduleChange(schedule.id ?? "", e.target.checked)
                  }
                  disabled={!canDecline}
                />
                <ScheduleText $disabled={!canDecline}>
                  {toDateIntervalFormat(
                    new Date(schedule.start),
                    new Date(schedule.end)
                  )}
                  {!canDecline && (
                    <StatusText>
                      {status === "Today" &&
                        "※当日のため不参加に変更できません。管理者に直接ご連絡ください。"}
                    </StatusText>
                  )}
                </ScheduleText>
              </CheckboxLabel>
            );
          })}
        </ScheduleCheckboxContainer>
        {errors.selectedScheduleIds && (
          <ErrorMessage>{errors.selectedScheduleIds.message}</ErrorMessage>
        )}

        <Margin marginTop={15} />
        <ModalLabel>
          <Label required>不参加理由</Label>
        </ModalLabel>
        <TextArea
          {...register("comment")}
          cols={30}
          rows={10}
          placeholder="入力してください（3000文字以内）"
        />
        {errors.comment && (
          <ErrorMessage>{errors.comment.message}</ErrorMessage>
        )}

        <ModalSubmitButtons
          submitColor="danger"
          submitText="参加を不参加にする"
          onSubmit={handleSubmit(onSubmit)}
          disabled={!isValid}
          onCancel={onClose}
        />
      </ModalBody>
    </ModalPortal>
  );
}
const TextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;

const ScheduleCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ScheduleText = styled.span<{ $disabled: boolean }>`
  color: ${(props) => (props.$disabled ? "#69707d" : "inherit")};
`;

const StatusText = styled.p`
  font-size: 12px;
  color: #bd271e;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
