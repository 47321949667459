import { RecruitmentScheduleCloseBodyType } from "@shared/validator/features/recruitment-schedule";
import * as client from "../client";

export async function closeRecruitmentSchedule(
  args: RecruitmentScheduleCloseBodyType
): Promise<void> {
  await client.post<
    { args: RecruitmentScheduleCloseBodyType },
    { result: string }
  >("/boardEducation/recruitment-schedule/close", { args });
}
