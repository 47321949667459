import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { TextField } from "../../../components/Form";
import { usePolyfitHistory } from "../../../hooks/router";
import { useCommunityById } from "../../../hooks/recoil/community";
import { useCurrentUser } from "../../../hooks/recoil/user";
import useGoogleGeocoding from "../../../hooks/useGoogleGeocoding";
import { useSearchParams } from "react-router-dom";
import { logger } from "src/utils/logger";
import { isInternalRoleAdmin } from "@shared/utils/internalUserRole";

export default function CommunityEditPage() {
  const [searchParams] = useSearchParams();
  const editCommunityId = searchParams.get("communityId");
  const history = usePolyfitHistory();

  const currentUser = useCurrentUser();
  const [
    { community: editCommunity, isLoadingCommunity },
    { fetchCommunity, writeCommunity },
  ] = useCommunityById(editCommunityId ?? "");

  /**
   * 変更系
   */
  const [communityName, setCommunityName] = useState<string>("");
  const [communityCode, setCommunityCode] = useState<string>("");
  const [communityPostalCode, setCommunityPostalCode] = useState<string>("");
  const [communityAddress, setCommunityAddress] = useState<string>("");

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCommunityName(e.target.value);
  }, []);

  const onCodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCommunityCode(e.target.value);
  }, []);

  const onPostalCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCommunityPostalCode(e.target.value);
    },
    []
  );
  const onAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCommunityAddress(e.target.value);
    },
    []
  );

  const onSubmit = async () => {
    try {
      await writeCommunity({
        communityName: communityName,
        communityCode: communityCode,
        communityAddress: communityAddress,
        communityPostalCode: communityPostalCode,
        communityNameListPublished: undefined,
        communityRequireAdminToUpdateCareer: undefined,
        communityId: editCommunity?.id ?? "",
      });

      alert("更新しました");
      fetchCommunity();
    } catch (e) {
      logger.error(e);
      alert("更新に失敗しました");
    }
  };

  const { getLocationAddressByAddress } = useGoogleGeocoding();

  const onGeographySubmit = async () => {
    try {
      const res = await getLocationAddressByAddress(communityAddress);

      await writeCommunity({
        communityName: communityName,
        communityCode: communityCode,
        communityAddress: communityAddress,
        communityPostalCode: communityPostalCode,
        communityNameListPublished: undefined,
        communityRequireAdminToUpdateCareer: undefined,
        communityId: editCommunity?.id ?? "",
        prefecture: res?.prefecture,
        city: res?.city,
        address1: res?.address1,
        address2: res?.address2,
        latitude: res?.latitude,
        longitude: res?.longitude,
      });

      fetchCommunity();
      alert("更新しました");
    } catch (e) {
      logger.error(e);
      alert("更新に失敗しました");
    }
  };

  /**
   * 画面初期化時
   */
  useEffect(() => {
    (async () => {
      const editCommunity = await fetchCommunity();
      setCommunityName(editCommunity?.name ?? "");
      setCommunityAddress(editCommunity?.address ?? "");
      setCommunityCode(editCommunity?.schoolCode ?? "");
      setCommunityPostalCode(editCommunity?.postalCode ?? "");
    })();
  }, []);

  /**
   * 表示に関して
   */
  if (!editCommunityId || !isInternalRoleAdmin(currentUser.internalRole)) {
    return <></>;
  }

  if (isLoadingCommunity) {
    return (
      <>
        <div>読み込み中…</div>
        <div>画面が切り替わらない場合はお問い合わせください</div>
      </>
    );
  }

  return (
    <>
      <TransitionLink
        onClick={() => {
          history.push({ to: "INTERNAL_ORGANIZATION_LIST" });
        }}
      >
        組織一覧に戻る
      </TransitionLink>{" "}
      <TransitionLink
        onClick={() => {
          history.push({ to: "INTERNAL_COMMUNITY_LIST" });
        }}
      >
        コミュニティ一覧に戻る
      </TransitionLink>{" "}
      <br />
      <h3>コミュニティ({editCommunityId})の情報の編集</h3>
      {/* 組織情報の編集 */}
      <FormArea>
        <div>
          <TextField
            label="コミュニティ名"
            value={communityName}
            onChange={onNameChange}
          />
          <TextField
            label="学校コード"
            value={communityCode}
            onChange={onCodeChange}
          />
          <TextField
            label="郵便番号"
            value={communityPostalCode}
            onChange={onPostalCodeChange}
          />
          <TextField
            label="住所"
            value={communityAddress}
            onChange={onAddressChange}
          />
          <br />
          作成日時: {editCommunity?.createdAt}
          <br />
          更新日時: {editCommunity?.updatedAt}
        </div>
        <Button
          size="large"
          color="secondary"
          style={{ marginLeft: "8px" }}
          onClick={onSubmit}
        >
          更新する
        </Button>
      </FormArea>
      {/* 緯度経度の更新 */}
      <FormArea>
        緯度: {editCommunity?.latitude}
        <br />
        軽度: {editCommunity?.longitude}
        <br />
        {editCommunity?.latitude && editCommunity?.longitude && (
          <>
            googleMapURL:{" "}
            <a
              href={`https://www.google.com/maps?q=${editCommunity.latitude},${editCommunity.longitude}`}
              target="_blank"
              rel="noreferrer"
            >{`https://www.google.com/maps?q=${editCommunity.latitude},${editCommunity.longitude}`}</a>
            <br />
            募集デフォルト住所: 〒{editCommunity.postalCode}{" "}
            {editCommunity?.prefecture}
            {editCommunity?.city}
            {editCommunity?.address1}
            {editCommunity?.address2}
          </>
        )}
        <Button
          size="large"
          color="secondary"
          style={{ marginLeft: "8px" }}
          onClick={onGeographySubmit}
        >
          緯度経度を更新
        </Button>
      </FormArea>
    </>
  );
}

const FormArea = styled.div`
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;

  h4 {
    margin-bottom: 8px;
  }
`;

const TransitionLink = styled.a`
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;
