// post上に持つアンケート回答に関わる情報

import {
  MultiChoiceQuestion,
  OpenEndedQuestion,
} from "@shared/validator/features/survey.schema";
import { get, post } from "./client";
import { isMultiChoiceQuestion, isOpenEndedQuestion } from "./survey";

// 各回答を表すデータ型
export type Answer = MultiChoiceAnswer | OpenEndedAnswer;
// 各選択式回答を表すデータ型
export interface MultiChoiceAnswer {
  id: string;
  // 対応する質問
  question: MultiChoiceQuestion;
  // 選択式なので選択された選択肢を配列で持つ
  // NOTE: Set型がベストだがJSON.StringifyできないのでListで持っている
  selectedChoices: string[];
}
// 各記述式回答を表すデータ型
export interface OpenEndedAnswer {
  id: string;
  // 対応する質問
  question: OpenEndedQuestion;
  // 記述式なのでテキストを持つ
  text: string;
}

// 種別判定用の関数、回答用
// 質問用の判定関数を呼ぶだけ
export function isMultiChoiceAnswer(
  answer: Answer
): answer is MultiChoiceAnswer {
  return isMultiChoiceQuestion(answer.question);
}
export function isOpenEndedAnswer(answer: Answer): answer is OpenEndedAnswer {
  return isOpenEndedQuestion(answer.question);
}

// サーバーサイドとの受け渡しを担当する型
export interface SurveyAnswers {
  // この質問に回答しているユーザーのID
  // メインの質問群
  answerList: Answer[];
  // すでにこのユーザーに回答されているかのフラグ
  // 「アンケートに回答」と「回答済みアンケートを編集」の区別に用いる
  isAlreadyAnswered: boolean;
}

// アンケート回答をサーバーから受信する関数
// ユーザーと質問から該当する回答を探す
export async function fetchSurveyAnswers(
  surveyQuestionsId: string
): Promise<SurveyAnswers> {
  const query = {
    surveyQuestionsId: surveyQuestionsId,
  };
  return await get<typeof query, SurveyAnswers>("/surveyAnswers/fetch", query);
}

// アンケート回答をサーバーに送信する関数
// TODO: 質問も一緒に送信しているが無駄かも
export async function sendSurveyAnswers(
  surveyAnswers: SurveyAnswers
): Promise<void> {
  return await post<SurveyAnswers, void>(
    "/surveyAnswers/upsert",
    surveyAnswers
  );
}
