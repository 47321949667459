import { useCallback, useEffect, useState } from "react";
import { getDraftRecruitmentList } from "../../apiClients/recruitment";
import { Recruitment } from "@shared/types/recruitment";

export const useDraftRecruitment = () => {
  const [recruitments, setRecruitments] = useState<Recruitment[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const recruitments = await getDraftRecruitmentList();
    setRecruitments(recruitments);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  return { recruitments, isLoading, refetch: fetchRecruitment };
};
