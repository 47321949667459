import { useCallback, useEffect, useState } from "react";
import {
  getApplicationListByFilter,
  getMyApplicationByRecruitmentId,
  getApplicationListByUserId,
  countApplication,
  CountApplicationResponse,
} from "../../apiClients/recruitmentApplication";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import {
  ApplicationStatus,
  RecruitmentApplication,
  RecruitmentApplicationListOrder,
} from "@shared/types/recruitmentApplication";
import { CurrentUser } from "src/apiClients/auth";
import { isCsAdminOrCsOperator } from "src/utils/types/role";

export const useApplicantByRecruitmentId = (recruitmentId: string) => {
  const [applicant, setApplicant] = useState<RecruitmentApplication | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = async () => {
    setLoading(true);
    const recruitment = await getMyApplicationByRecruitmentId(recruitmentId);
    setApplicant(recruitment);
    setLoading(false);
  };

  useEffect(() => {
    fetchRecruitment();
  }, [recruitmentId]);

  return { applicant, isLoading, refetch: fetchRecruitment };
};

export const useApplicantList = (
  status: ApplicationStatus | "ALL",
  name: string,
  order?: RecruitmentApplicationListOrder,
  recruitmentId?: string
) => {
  const [applicantList, setApplicantList] = useState<
    RecruitmentApplication[] | null
  >(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const recruitments = await getApplicationListByFilter(
      status,
      name,
      undefined,
      undefined,
      order
    );
    setApplicantList(recruitments);
    setLoading(false);
  }, [status, name, order]);

  useEffect(() => {
    fetchRecruitment();
  }, [status, name, order]);

  return { applicantList, isLoading, refetch: fetchRecruitment };
};

export const useRecruitmentApplicantListByUserId = (
  userId: string,
  currentUser: CurrentUser
) => {
  const [recruitmentList, setRecruitmentList] = useState<
    AppliedRecruitment[] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFetchable =
    isCsAdminOrCsOperator(currentUser.role) && userId !== currentUser.id;

  const fetchRecruitmentListByUserId = useCallback(async () => {
    setRecruitmentList(null);
    setIsLoading(true);
    if (isFetchable) {
      const appliedRecruitmentList = await getApplicationListByUserId(userId);
      setRecruitmentList(appliedRecruitmentList);
    }
    setIsLoading(false);
  }, [isFetchable, userId, currentUser.id]);

  useEffect(() => {
    fetchRecruitmentListByUserId();
  }, [userId]);

  return { recruitmentList, isLoading, refetch: fetchRecruitmentListByUserId };
};

export const useCountRecruitmentApplicant = () => {
  const [countApplicant, setApplicant] =
    useState<CountApplicationResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchCountRecruitmentApplication = useCallback(async () => {
    setApplicant(null);
    setIsLoading(true);
    const countApplicantList = await countApplication();
    setApplicant(countApplicantList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchCountRecruitmentApplication();
  }, []);

  return {
    countRecruitmentApplicant: countApplicant,
    isLoading,
    refetch: countApplication,
  };
};
