import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../Responsive";

type Props = {
  labels: string[];
  onSelect: (index: number) => void;
  selectedIndex: number;
};

export const SwitchBar = ({ labels, onSelect, selectedIndex }: Props) => {
  const onClick = (index: number) => {
    onSelect(index);
  };

  return (
    <SwitchArea>
      {labels.map((label, index) => {
        return (
          <SwitchItem
            key={label + index}
            $clicked={index === selectedIndex}
            $isFirst={index === 0}
            $isEnd={index === labels.length - 1}
            onClick={() => onClick(index)}
          >
            {label}
          </SwitchItem>
        );
      })}
    </SwitchArea>
  );
};

const SwitchArea = styled.div`
  display: flex;
`;

const SwitchItem = styled.div<{
  $clicked: boolean;
  $isFirst: boolean;
  $isEnd: boolean;
}>`
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  width: 100%;
  padding: 7px 4px;
  border-radius: ${(props) =>
    props.$isFirst ? "6px 0 0 6px" : props.$isEnd ? "0 6px 6px 0" : ""};
  ${(props) => (props.$clicked ? null : `border: 1px solid #d3dae6`)};
  background-color: ${(props) => (props.$clicked ? "#0077CC" : "#FFFFFF")};
  color: ${(props) => (props.$clicked ? "#fff" : "#000")};

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 14px;
  }
`;
