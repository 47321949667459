import React, { useCallback, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { toDateIntervalFormat } from "src/utils/time";

interface Props {
  applicant: Pick<RecruitmentApplication, "user" | "recruitment">;
  event: RecruitmentApplication["eventStatuses"][number];
  onSubmit: (comment: string) => void;
  onClose: () => void;
  disabled: boolean;
}

export default function DeclineApplicationModal({
  applicant,
  event,
  onSubmit,
  onClose,
  disabled,
}: Props) {
  const [text, setText] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= 3000) {
        setText(e.target.value);
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (showErrorMessage) return;
    onSubmit(text);
  }, [showErrorMessage, text, onSubmit]);

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>{applicant.user.name} を不参加にしますか？</ModalHeader>
        <ModalBody>
          <ModalContent>
            <div>不参加を確定すると、「不参加」のステータスに移動します。</div>
            <InfoSection>
              <Label>応募タイトル</Label>
              <InfoText>{applicant.recruitment.title}</InfoText>
            </InfoSection>
            <InfoSection>
              <Label>開催日時</Label>
              <InfoText>
                {toDateIntervalFormat(
                  new Date(event.recruitmentSchedule.start),
                  new Date(event.recruitmentSchedule.end)
                )}
              </InfoText>
            </InfoSection>
            <Margin marginTop={2} />
            <Label>コメント</Label>
            <TextArea
              name=""
              id=""
              cols={30}
              rows={10}
              value={text}
              onChange={onChangeText}
              placeholder="入力してください（3000文字以内）"
            />
            <ModalSubmitButtons
              submitColor="danger"
              submitText="不参加にする"
              onSubmit={handleSubmit}
              disabled={showErrorMessage || disabled}
              onCancel={onClose}
            />
          </ModalContent>
        </ModalBody>
      </ModalPortal>
    </>
  );
}

const TextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;
