import { z } from "zod";
import { residentInfoSchema } from "../models/residentInfo.schema";
import { accountCommunityRolesRequestSchema } from "../models/accountCommunityRole.schema";
import { zRequiredString } from "../rules/string";
import { UserType } from "../../types/userType";
import { baseInfoSchema } from "../models/baseInfo.schema";
import { teacherInfoSchema } from "../models/teacherInfo.schema";
import { baseRequestSchema } from "./common.schema";

export const residentInfoBodySchema = z.object({
  data: residentInfoSchema,
});

export type ResidentInfoBodySchemaType = z.infer<typeof residentInfoBodySchema>;

export const residentInfoBodyForCSSchema = residentInfoBodySchema.extend({
  userId: z.string().min(1),
});

export type ResidentInfoBodyForCSSchemaType = z.infer<
  typeof residentInfoBodyForCSSchema
>;

export const residentInfoBodyForBESchema = residentInfoBodySchema.extend({
  accountId: z.string().min(1),
});

export type ResidentInfoBodyForBESchemaType = z.infer<
  typeof residentInfoBodyForBESchema
>;

export const updateAccountCommunityRolesRequestSchema = z.object({
  userId: z.string(),
  data: z.array(accountCommunityRolesRequestSchema),
  skipValidate: z.boolean().optional(),
});

export type UpdateAccountCommunityRolesRequestSchemaType = z.infer<
  typeof updateAccountCommunityRolesRequestSchema
>;

export const signupWithInvitationSchema = z.object({
  token: zRequiredString,
  isGuest: z.boolean().optional(),
});

export type SignupWithInvitationSchemaType = z.infer<
  typeof signupWithInvitationSchema
>;

export const resendEmailVerificationMailSchema = z.object({
  token: zRequiredString,
});

export type ResendEmailVerificationMailSchemaType = z.infer<
  typeof resendEmailVerificationMailSchema
>;

export const firebaseStateSchema = z.object({
  userId: zRequiredString,
});

export type FirebaseStateSchemaType = z.infer<typeof firebaseStateSchema>;

export const getUserByIdSchemaForClient = z.object({
  userId: zRequiredString,
});

export const getUserByIdSchema = getUserByIdSchemaForClient.extend({
  currentCommunityId: zRequiredString,
});

export type GetUserByIdSchemaType = z.infer<typeof getUserByIdSchema>;
export type GetUserByIdSchemaForClientType = z.infer<
  typeof getUserByIdSchemaForClient
>;

export const changeEmailByInternalAdminSchema = z.object({
  userId: zRequiredString,
  email: zRequiredString,
});

export type ChangeEmailByInternalAdminSchemaType = z.infer<
  typeof changeEmailByInternalAdminSchema
>;

export const isExistsOrganizationUserSchema = z.object({
  token: zRequiredString,
});

export type IsExistsOrganizationUserSchemaType = z.infer<
  typeof isExistsOrganizationUserSchema
>;

export const registerUserTypeSchema = z.object({
  userType: z.nativeEnum(UserType),
});

export type RegisterUserTypeSchemaType = z.infer<typeof registerUserTypeSchema>;

export const registerInfoSchema = z.object({
  data: baseInfoSchema,
});

export type RegisterInfoSchemaType = z.infer<typeof registerInfoSchema>;

export const registerFaceImgPathSchema = z.object({
  data: z.object({
    faceImgPath: z.string().min(1, "画像のアップロードに失敗しました"),
  }),
});

export type RegisterFaceImgPathSchemaType = z.infer<
  typeof registerFaceImgPathSchema
>;

export const registerFaceImgPathForBESchema = z.object({
  accountId: zRequiredString,
  faceImgPath: z.string().min(1, "画像のアップロードに失敗しました"),
});

export type RegisterFaceImgPathForBESchemaType = z.infer<
  typeof registerFaceImgPathForBESchema
>;

export const getBaseInfoSchema = baseRequestSchema.extend({
  userId: z.string().optional(),
});

export type GetBaseInfoSchemaType = z.infer<typeof getBaseInfoSchema>;

export const getResidentBaseInfoSchema = baseRequestSchema.extend({
  userId: z.string().optional(),
});

export type GetResidentBaseInfoSchemaType = z.infer<
  typeof getResidentBaseInfoSchema
>;

export const updateTeacherInfoSchema = z.object({
  data: teacherInfoSchema,
});

export type UpdateTeacherInfoSchemaType = z.infer<
  typeof updateTeacherInfoSchema
>;

export const updateTeacherInfoByAdminSchema = z.object({
  userId: zRequiredString,
  data: teacherInfoSchema,
});

export type UpdateTeacherInfoByAdminSchemaType = z.infer<
  typeof updateTeacherInfoByAdminSchema
>;

export const deleteAccountSchema = z.object({
  data: z.object({
    targetAccountId: zRequiredString,
  }),
});

export type DeleteAccountSchemaType = z.infer<typeof deleteAccountSchema>;

export const deleteUnApprovedAccountSchema = z.object({
  data: z.object({
    targetAccountId: zRequiredString,
  }),
});

export type DeleteUnApprovedAccountSchemaType = z.infer<
  typeof deleteUnApprovedAccountSchema
>;

export const registerResidentBaseInfoSchema = z.object({
  data: baseInfoSchema
    .pick({
      lastName: true,
      firstName: true,
      lastNameKana: true,
      firstNameKana: true,
    })
    .extend({
      gender: z.enum(["MALE", "FEMALE", "OTHER"], {
        errorMap: () => ({ message: "入力必須項目です" }),
      }),
      ageGroup: z.number(),
    }),
});

export type RegisterResidentBaseInfoSchemaType = z.infer<
  typeof registerResidentBaseInfoSchema
>;

export const registerResidentContactInfoSchema = z.object({
  data: baseInfoSchema
    .pick({
      address2: true,
      phoneNumber: true,
    })
    .extend({
      postalCode: zRequiredString,
      prefecture: zRequiredString,
      city: zRequiredString,
      address1: zRequiredString,
    }),
});

export type RegisterResidentContactInfoSchemaType = z.infer<
  typeof registerResidentContactInfoSchema
>;

export const approveUsersSchema = z.object({
  data: z.array(
    z.object({
      userId: zRequiredString,
      accountId: zRequiredString,
    })
  ),
});

export type ApproveUsersSchemaType = z.infer<typeof approveUsersSchema>;

export const updateBelongAndPartSchema = z.object({
  data: z.object({
    userId: zRequiredString,
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
  }),
});

export type UpdateBelongAndPartSchemaType = z.infer<
  typeof updateBelongAndPartSchema
>;

export const setCurrentAccountSchema = z.object({
  userId: zRequiredString,
  organizationId: zRequiredString,
});

export type SetCurrentAccountSchemaType = z.infer<
  typeof setCurrentAccountSchema
>;

export const updateCommunityRolesByTokenRequestSchema = z.object({
  token: zRequiredString,
  roleName: zRequiredString,
});

export type UpdateCommunityRolesByTokenRequestSchemaType = z.infer<
  typeof updateCommunityRolesByTokenRequestSchema
>;

export const updateRoleSchema = z.object({
  userId: zRequiredString,
  role: zRequiredString,
});

export type UpdateRoleSchemaType = z.infer<typeof updateRoleSchema>;

export const updateAccountByInternalAdminSchema = z.object({
  data: z.object({
    userId: zRequiredString,
    userType: z.nativeEnum(UserType),
    able: z.boolean(),
    isApprover: z.boolean(),
    signUpState: z.boolean(),
    role: zRequiredString,
  }),
  organizationId: zRequiredString,
});

export type UpdateAccountByInternalAdminSchemaType = z.infer<
  typeof updateAccountByInternalAdminSchema
>;

export const canDeleteCommunityRoleSchema = z.object({
  userId: zRequiredString,
  communityId: zRequiredString,
});

export type CanDeleteCommunityRoleSchemaType = z.infer<
  typeof canDeleteCommunityRoleSchema
>;

export const checkUserInThisCommunitySchema = baseRequestSchema.extend({
  userId: zRequiredString,
});

export type CheckUserInThisCommunitySchemaType = z.infer<
  typeof checkUserInThisCommunitySchema
>;
