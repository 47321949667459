import React, { useState } from "react";
import styled from "styled-components";
import { applicationStatusOptions } from "../../../apiClients/recruitmentApplication";
import { Avatar } from "../../../components/Common/Avatar";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { toDateIntervalFormat } from "src/utils/time";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { Link } from "src/components/Link";
import { RandomSelection } from "./RandomSelection";
import {
  approveApplication,
  declineApplicationByAdmin,
  rejectApplication,
} from "src/apiClients/recruitmentApplicationEvent";
import { MemberDetailLink } from "./MemberDetailLink";
import {
  applicationAttendanceStatus,
  ApplicationAttendanceStatus,
} from "@shared/types/recruitmentApplicationEvent";
import { getCommunityUserTypeText } from "src/features/Member/Community/utils/utils";
import { ApplicationChildInfoPopup } from "./ApplicationChildInfoPopup";
import { CommunityUser, CommunityUserType } from "@shared/types/userType";
import { colorsPallet } from "src/theme";

type ApplicantCardListProps = {
  applicantList: RecruitmentApplication[] | null;
  handleChangeStatus: (
    applicant: RecruitmentApplication,
    eventId: string
  ) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleAttendanceModalOpen: (
    applicant: RecruitmentApplication,
    eventId: string
  ) => void;
  fetchRecruitment: () => void;
};

export const ApplicantCardList = ({
  applicantList,
  handleChangeStatus,
  handleAttendanceModalOpen,
  fetchRecruitment,
}: ApplicantCardListProps) => {
  const [randomSelectedApplicants, setRandomSelectedApplicants] = useState<
    RecruitmentApplication[]
  >([]);
  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

  const filteredApplicationStatusOptions = (
    event: RecruitmentApplication["eventStatuses"][number]
  ) => {
    if (event.selectionStatus === "APPROVED") {
      return applicationStatusOptions.filter((opt) => opt.value !== "APPLIED");
    }
    return applicationStatusOptions;
  };

  const onClickRandomSelection = (count: number) => {
    if (!applicantList) return;
    const selectedIndices = new Set<number>();
    while (selectedIndices.size < count) {
      const randomIndex = Math.floor(Math.random() * applicantList.length);
      selectedIndices.add(randomIndex);
    }
    const selectedApplicants = Array.from(selectedIndices).map(
      (index) => applicantList[index]
    );

    setRandomSelectedApplicants(selectedApplicants);
  };

  const onClickExecute = async (status: string, comment: string) => {
    for (const applicant of randomSelectedApplicants) {
      for (const event of applicant.eventStatuses) {
        if (
          event.selectionStatus !== "REJECTED" &&
          event.selectionStatus !== "DECLINED"
        ) {
          switch (status) {
            case "APPROVED":
              if (event.selectionStatus !== "APPROVED") {
                await approveApplication(event.id);
              }
              break;
            case "REJECTED":
              await rejectApplication(event.id, comment);
              break;
            case "DECLINED":
              await declineApplicationByAdmin(event.id, comment);
              break;
            default:
              break;
          }
        }
      }
    }
    await fetchRecruitment();
  };

  return (
    <>
      {applicantList && (
        <RandomSelection
          applicants={applicantList}
          randomSelectedApplicants={randomSelectedApplicants}
          onClickRandomSelection={onClickRandomSelection}
          onClickExecute={onClickExecute}
        />
      )}
      <List>
        {applicantList?.map((applicant) => {
          return (
            <Card key={applicant.id}>
              <CardHeader>
                <Avatar
                  src={applicant.user.picture || defaultUserImg}
                  alt="ユーザーのアイコン"
                  size={40}
                />
                <ChildNameWrapper>
                  <Name>{applicant.user.name}</Name>
                  {applicant.cSChildren && applicant.cSChildren.length > 0 && (
                    <ApplicationChildInfoPopup applicant={applicant} />
                  )}
                </ChildNameWrapper>
                <CommunityUserTypeLabel $type={applicant.communityUserType}>
                  {getCommunityUserTypeText(applicant.communityUserType || "")}
                </CommunityUserTypeLabel>
              </CardHeader>
              <CardBody>
                <div>
                  <Title>応募タイトル</Title>
                  <Text>
                    <LinkText
                      to={{
                        to: "RESIDENT_RECRUITMENT_DETAIL",
                        query: { id: applicant.recruitment.id },
                      }}
                    >
                      {applicant.recruitment.title}
                    </LinkText>
                  </Text>
                </div>
                <div>
                  <Title>コメント</Title>
                  <CommentText
                    title={applicant.coverLetterChatMessage?.content}
                    $isEmpty={!applicant.coverLetterChatMessage?.content}
                  >
                    {applicant.coverLetterChatMessage?.content || "なし"}
                  </CommentText>
                </div>
                <div>
                  <Title>選考</Title>
                  <SelectionList>
                    {applicant.eventStatuses.map((event) => (
                      <SelectionItem key={event.id}>
                        <DateText>
                          {toDateIntervalFormat(
                            new Date(event.recruitmentSchedule.start),
                            new Date(event.recruitmentSchedule.end)
                          )}
                        </DateText>
                        <InputSelect
                          value={
                            event.rejectedAt
                              ? "REJECTED"
                              : event.selectionStatus
                          }
                          onChange={handleChangeStatus(applicant, event.id)}
                          disabled={
                            !!event.rejectedAt ||
                            event.selectionStatus === "REJECTED" ||
                            event.selectionStatus === "DECLINED"
                          }
                        >
                          {filteredApplicationStatusOptions(event).map(
                            (opt) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            )
                          )}
                        </InputSelect>
                        <AttendanceSection>
                          {event.selectionStatus === "APPROVED" ? (
                            <EventSection>
                              <Title>出欠席</Title>
                              <AttendanceStatusButton
                                onClick={() =>
                                  handleAttendanceModalOpen(applicant, event.id)
                                }
                              >
                                <StatusBadge $status={event.attendanceStatus}>
                                  {event.attendanceStatus ===
                                  applicationAttendanceStatus.ATTENDED
                                    ? "出席"
                                    : event.attendanceStatus ===
                                      applicationAttendanceStatus.ABSENT
                                    ? "欠席"
                                    : "未出席"}
                                </StatusBadge>
                              </AttendanceStatusButton>
                            </EventSection>
                          ) : null}
                        </AttendanceSection>
                      </SelectionItem>
                    ))}
                  </SelectionList>
                </div>
              </CardBody>
              <CardFooter>
                <MemberDetailLink userId={applicant.user.id} />
              </CardFooter>
            </Card>
          );
        })}
      </List>
    </>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Card = styled.div`
  border-radius: 6px;
  border: 1px solid #e4e6f3;
  background: #fff;
  padding: 24px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardFooter = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const Name = styled.p`
  color: #343741;
  font-size: 16px;
`;

const ChildNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.p`
  color: #69707d;
  font-size: 12px;
  margin-bottom: 4px;
`;

const Text = styled.p`
  color: #343741;
  font-size: 16px;
`;

const LinkText = styled(Link)`
  cursor: pointer;
  color: #07c;
`;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const CommentText = styled(Text)<{ $isEmpty: boolean }>`
  color: ${(props) => (props.$isEmpty ? "grey" : "inherit")};
  max-height: 200px;
  overflow-y: auto;
  white-space: normal;
  padding-right: 10px;
`;

const SelectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SelectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DateText = styled.p`
  font-size: 14px;
  color: #343741;
`;

const EventSection = styled.div`
  margin-top: 2px;
  margin-bottom: 16px;
`;

const AttendanceSection = styled.div`
  display: flex;
  width: 100px;
`;

const AttendanceStatusButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StatusBadge = styled.span<{
  $status: ApplicationAttendanceStatus | null;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  background-color: ${(props) => {
    switch (props.$status) {
      case applicationAttendanceStatus.ATTENDED:
        return "#0077cc";
      case applicationAttendanceStatus.ABSENT:
        return "#e65c5c";
      default:
        return "#969696";
    }
  }};
`;

const CommunityUserTypeLabel = styled.div<{ $type: CommunityUserType | null }>`
  display: inline-block;
  padding: 2px 8px;
  border-radius: 40px;
  background-color: ${
    (props) =>
      props.$type === CommunityUser.PARENT
        ? colorsPallet.primary // 保護者
        : props.$type === CommunityUser.TEACHER
        ? colorsPallet.accent // 教職員
        : colorsPallet.success // 地域住民
  };
  color: #ffffff;
  font-size: 10px;
`;
