import React from "react";
import styled from "styled-components";
import { applicationStatusOptions } from "src/apiClients/recruitmentApplication";
import { Avatar } from "src/components/Common/Avatar";
import { toDateIntervalFormat } from "src/utils/time";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { BERecruitmentApplication } from "@shared/types/boardEducation/recruitmentApplication";

type ApplicantTableProps = {
  applicantList: BERecruitmentApplication[] | null;
  handleChangeStatus: (
    applicant: BERecruitmentApplication,
    eventId: string
  ) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onApplicantDetailClick: (accountId: string, communityId: string) => void;
};

export const ApplicantTable = ({
  applicantList,
  handleChangeStatus,
  onApplicantDetailClick,
}: ApplicantTableProps) => {
  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

  const filteredApplicationStatusOptions = (
    event: BERecruitmentApplication["eventStatuses"][number]
  ) => {
    if (event.selectionStatus === "APPROVED") {
      return applicationStatusOptions.filter((opt) => opt.value !== "APPLIED");
    }
    return applicationStatusOptions;
  };

  return (
    <Table>
      <thead>
        <TheadTr>
          <TheadTh first>名前</TheadTh>
          <TheadTh>応募タイトル</TheadTh>
          <TheadTh>応募者の学校</TheadTh>
          <TheadTh>コメント</TheadTh>
          <TheadTh>選考</TheadTh>
          <TheadTh last></TheadTh>
        </TheadTr>
      </thead>

      {applicantList?.map((applicant, i) => (
        <Tbody key={i}>
          <TbodyTr>
            <TbodyTd>
              <UserPicCell>
                <Avatar
                  src={applicant.user.picture || defaultUserImg}
                  alt="ユーザーのアイコン"
                  size={40}
                />
                <Name>{applicant.user.name}</Name>
              </UserPicCell>
            </TbodyTd>
            <TbodyTd>{applicant.recruitment.title}</TbodyTd>
            <TbodyTd>
              {" "}
              {applicant.communityId
                ? applicant?.community.name
                : applicant.account.communityRoles
                    .map((communityRole) => communityRole.community.name)
                    .join(", ")}
            </TbodyTd>
            <TbodyTd>
              <CommentText
                title={applicant.coverLetterChatMessage?.content}
                isEmpty={!applicant.coverLetterChatMessage?.content}
              >
                {applicant.coverLetterChatMessage?.content || "なし"}
              </CommentText>
            </TbodyTd>
            <TbodyTd>
              <StatusColumn>
                {applicant.eventStatuses.map((event) => (
                  <StatusRow key={event.id}>
                    <DateText>
                      {toDateIntervalFormat(
                        new Date(event.recruitmentSchedule.start),
                        new Date(event.recruitmentSchedule.end)
                      )}
                    </DateText>
                    <InputSelect
                      value={
                        event.rejectedAt ? "REJECTED" : event.selectionStatus
                      }
                      onChange={handleChangeStatus(applicant, event.id)}
                      disabled={
                        !!event.rejectedAt ||
                        event.selectionStatus === "REJECTED" ||
                        event.selectionStatus === "DECLINED"
                      }
                    >
                      {filteredApplicationStatusOptions(event).map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      ))}
                    </InputSelect>
                  </StatusRow>
                ))}
              </StatusColumn>
            </TbodyTd>
            <TbodyTd>
              <Link
                onClick={() =>
                  onApplicantDetailClick(
                    applicant.accountId,
                    applicant.communityId
                      ? applicant?.community.id
                      : applicant.account.communityRoles.find(
                          (communityRole) => communityRole.community.id
                        )?.community.id ?? ""
                  )
                }
              >
                応募者の詳細確認
              </Link>
            </TbodyTd>
          </TbodyTr>
        </Tbody>
      ))}
    </Table>
  );
};

const Table = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const TheadTr = styled.tr`
  background-color: #fff;
`;

const TheadTh = styled.th<{
  first?: boolean;
  last?: boolean;
}>`
  color: #343741;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding: 8px;
  ${(props) => props.first && `padding-left: 24px;`}
  ${(props) => props.last && `padding-right: 16px;`}
`;

const Tbody = styled.tbody`
  padding: 0 16px;
`;

const TbodyTr = styled.tr`
  background-color: #fff;
  border-top: 1px solid #e3e6eb;
`;

const TbodyTd = styled.td`
  color: #343741;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
  max-width: 200px;
`;

const UserPicCell = styled.div`
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

const Name = styled.div`
  color: #343741;
  font-size: 16px;
  font-weight: normal;
`;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const Link = styled.a`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: end;
  color: #07c;
`;

const CommentText = styled.div<{ isEmpty: boolean }>`
  color: ${(props) => (props.isEmpty ? "grey" : "inherit")};
  max-height: 100px;
  overflow-y: auto;
  white-space: normal;
  padding-right: 10px;
`;

const StatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DateText = styled.div`
  font-size: 14px;
  color: #343741;
`;
