import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title } from "src/components/Title";
import { BreadCrumb } from "src/components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "src/hooks/router";
import { useGetRecruitApplicationEvent } from "src/hooks/query/recruitments/recruitmentApplicationByEventId";
import { Button } from "src/components/Button";
import { BREAKPOINTS } from "src/components/Responsive";
import { ConfirmModal } from "src/components/ConfirmModal";
import { useToast } from "src/components/Toast";
import { updateAttendanceStatus } from "src/apiClients/recruitmentApplicationEvent";
import { applicationAttendanceStatus } from "@shared/types/recruitmentApplicationEvent";
import { toDateIntervalFormat } from "src/utils/time";
import { useModal } from "src/components/Modal";
import { logger } from "src/utils/logger";

export const ResidentRecruitmentApplicationScanResultPage = () => {
  const history = usePolyfitHistory();
  const toast = useToast();
  const { communityId } = useCurrentCommunityId();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShown, { show, close }] = useModal();
  const {
    recruitmentId,
    recruitmentScheduleId,
    recruitmentApplicationEventId,
  } = usePolyfitLocationQuery("RESIDENT_RECRUITMENT_APPLICATION_SCAN_RESULT", {
    recruitmentId: "",
    recruitmentScheduleId: "",
    recruitmentApplicationEventId: "",
  });

  const { application, isLoading, isError } = useGetRecruitApplicationEvent(
    recruitmentId,
    recruitmentApplicationEventId,
    {
      cacheTime: 0,
    }
  );

  const handleUpdateAttendance = async () => {
    if (!recruitmentApplicationEventId) return;

    try {
      await updateAttendanceStatus({
        attendanceStatus: applicationAttendanceStatus.ATTENDED,
        recruitApplicationEventId: recruitmentApplicationEventId,
      });
      toast.success("出席更新に成功しました");
      setIsSubmitting(true);
      handleBackToScanPage();
    } catch (error) {
      logger.error("出席更新に失敗しました", {
        error,
        application,
        recruitmentId,
        recruitmentScheduleId,
        recruitmentApplicationEventId,
      });
      toast.error("出席更新に失敗しました。再度お試しください。");
    }
  };

  const handleConfirmBackToScanPage = () => {
    show();
  };

  const handleBackToScanPage = () => {
    history.push({
      to: "RESIDENT_RECRUITMENT_APPLICATION_SCAN",
      query: {
        recruitmentId,
        recruitmentScheduleId,
      },
    });
  };

  const modalTexts = {
    MISMATCH: {
      title: "読み込みエラー",
      message:
        "指定された開催日程または募集IDが一致しません。出席変更できません。スキャン画面に戻りますか？",
    },
    ALREADY_ATTENDED: {
      title: "既に出席済みです",
      message: "このユーザーは既に出席済みです。スキャン画面に戻りますか？",
    },
    NOT_UPDATED: {
      title: "出席変更を破棄",
      message:
        "出席変更が反映されていません。出席変更せずにスキャン画面に戻りますか？",
    },
  };

  const isMismatch =
    application &&
    (application.recruitment.id !== recruitmentId ||
      application.eventStatus.recruitmentSchedule.id !== recruitmentScheduleId);

  const isAlreadyAttended =
    application?.eventStatus.attendanceStatus ===
    applicationAttendanceStatus.ATTENDED;

  const status: "MISMATCH" | "ALREADY_ATTENDED" | "NOT_UPDATED" = isMismatch
    ? "MISMATCH"
    : isAlreadyAttended
    ? "ALREADY_ATTENDED"
    : "NOT_UPDATED";

  const { title, message } = modalTexts[status];

  useEffect(() => {
    if (status === "MISMATCH" || status === "ALREADY_ATTENDED") {
      show();
    } else {
      close();
    }
  }, [status, show, close]);

  return (
    <>
      <BreadCrumb>
        <Link
          to={{
            pathname: "/resident/recruitment/application_list",
            search: `communityId=${communityId}`,
          }}
        >
          応募一覧
        </Link>
        <LinkWrap>スキャン結果</LinkWrap>
      </BreadCrumb>

      <Container>
        <ContentContainer>
          <Box>
            <Title>スキャン結果</Title>
          </Box>
          {isLoading ? (
            <LoadingText>読み込み中...</LoadingText>
          ) : isError || !application ? (
            <>
              <ErrorMessage>ユーザー情報の取得に失敗しました</ErrorMessage>
              <BackButtonContainer>
                <Button color="subPrimary" onClick={handleBackToScanPage}>
                  スキャンに戻る
                </Button>
              </BackButtonContainer>
            </>
          ) : (
            <>
              <Box>
                <RecruitmentInfo>
                  <InfoSection>
                    <InfoTitle>応募タイトル</InfoTitle>
                    <InfoText>{application?.recruitment.title}</InfoText>
                  </InfoSection>
                  <InfoSection>
                    <InfoTitle>開催日時</InfoTitle>
                    <InfoText>
                      {toDateIntervalFormat(
                        new Date(
                          application.eventStatus.recruitmentSchedule.start
                        ),
                        new Date(
                          application.eventStatus.recruitmentSchedule.end
                        )
                      )}
                    </InfoText>
                  </InfoSection>
                </RecruitmentInfo>
              </Box>
              <UserInfoCard>
                <UserInfo>
                  <UserImage
                    src={application.user.picture ?? ""}
                    alt={application.user.name}
                  />
                  <UserDetails>
                    <UserName>{application.user.name}</UserName>
                  </UserDetails>
                </UserInfo>
              </UserInfoCard>

              <ActionsContainer>
                <Button onClick={handleConfirmBackToScanPage}>
                  スキャンに戻る
                </Button>
                <Button
                  color="subPrimary"
                  onClick={handleUpdateAttendance}
                  disabled={isSubmitting || status !== "NOT_UPDATED"}
                >
                  出席済に変更
                </Button>
              </ActionsContainer>
            </>
          )}
        </ContentContainer>
      </Container>

      <ConfirmModal
        isOpen={isShown}
        onClose={() => close()}
        onConfirm={handleBackToScanPage}
        title={title}
        message={message}
        confirmText="スキャンに戻る"
        cancelText="キャンセル"
      />
    </>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 30px;
`;

const Box = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ContentContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: ${BREAKPOINTS.SP}) {
    padding: 16px;
  }
`;

const LoadingText = styled.div`
  text-align: center;
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const ErrorMessage = styled.div`
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  color: #d32f2f;
  padding: 16px;
  text-align: center;
  margin: 20px 0;
`;

const BackButtonContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const UserInfoCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin: 20px 0;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 0 0 4px 0;
`;

const RecruitmentInfo = styled.div`
  margin-bottom: 24px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & + & {
    margin-top: 16px;
  }
`;

const InfoTitle = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
`;
