import { AppliedRecruitment } from "@shared/types/appliedRecruitment";

/**
 * 辞退率を計算する関数
 * 辞退率 = (不参加になったイベント数 / 全イベント数) × 100%
 *   全イベント数: recruitmentListに含まれるイベントの総数
 *   不参加イベント数: selectionStatusがDECLINED(不参加)のイベント数
 */
export const calculateDeclinedRate = (
  recruitmentList: AppliedRecruitment[] | null
): number | null => {
  if (!recruitmentList || recruitmentList.length === 0) {
    return null;
  }

  const totalEvents = recruitmentList.reduce((acc, recruitment) => {
    return acc + recruitment.eventStatuses.length;
  }, 0);

  if (totalEvents === 0) {
    return null;
  }

  const declinedEvents = recruitmentList.reduce((acc, recruitment) => {
    return (
      acc +
      recruitment.eventStatuses.filter(
        (event) => event.selectionStatus === "DECLINED"
      ).length
    );
  }, 0);

  const rate = (declinedEvents / totalEvents) * 100;
  return Math.round(rate * 10) / 10;
};
