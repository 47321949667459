import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { formatDateTime } from "src/features/common/ChatMessage/utils/formatRecruitmentSchedule";
import { ChatRoomRecruitmentCard } from "./ChatRoomCard/ChatRoomRecruitmentCard";
import { ChatRoomIndividualCard } from "./ChatRoomCard/ChatRoomIndividualCard";
import { ChatRoomIndividualFromQRCard } from "./ChatRoomCard/ChatRoomIndividualFromQRCard";
import {
  ApprovalStatus,
  ChatRoomEntity,
  ChatRoomOrigin,
  ChatRoomType,
} from "@shared/types/chatRoom";
import { ChatRoomGroupCard } from "./ChatRoomCard/ChatRoomGroupCard";
import { ChatRoomIndividualBoardEducationCard } from "./ChatRoomCard/ChatRoomIndividualBoardEducationCard";
import { ChatRoomGroupBoardEducationCard } from "./ChatRoomCard/ChatRoomGroupBoardEducationCard";
import { ChatRoomRecruitmentBoardEducationCard } from "./ChatRoomCard/ChatRoomRecruitmentBoardEducationCard";
import { Role } from "@shared/types/role";
import { useCurrentUser } from "src/hooks/recoil/user";
import { Spinner } from "src/components/icons/Spinner";

type ChatRoomListProps = {
  chatRooms: ChatRoomEntity[];
  participantId: string;
  isCsAdmin: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
};

export const ChatRoomList = (props: ChatRoomListProps) => {
  const history = usePolyfitHistory();
  const { tab, chatRoomId } = usePolyfitLocationQuery("RESIDENT_CHAT", {});
  const user = useCurrentUser();
  const isAdmin = props.participantId !== user.id;
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const onChatRoomClick = (roomId: string) => {
    history.replace({
      to: "RESIDENT_CHAT",
      query: { chatRoomId: roomId, tab },
    });
  };

  useEffect(() => {
    if (!props.hasNextPage || props.isFetchingNextPage) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          props.fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "50px",
        threshold: 0.1,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
      observer.disconnect();
    };
  }, [props.hasNextPage, props.isFetchingNextPage, props.fetchNextPage]);

  return (
    <List>
      {props.chatRooms.map((room) => {
        if (
          room.chatRoom.type ===
            ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION &&
          room.individualChatWithOrganization
        ) {
          // CSGuest は名前登録していないので、一律ゲスト
          const userName =
            room.individualChatWithOrganization.account.role === Role.CSGuest
              ? "ゲスト"
              : room.individualChatWithOrganization.account.name;

          /*
            chatRoom が csAdmin から作られた場合 or QRコードから連絡されて Approve された場合
          */
          if (
            room.chatRoom.origin === ChatRoomOrigin.CSADMIN ||
            (room.chatRoom.origin === ChatRoomOrigin.QRCODE &&
              room.chatRoom.approvalStatus === ApprovalStatus.APPROVED)
          ) {
            return (
              <ChatRoomIndividualCard
                key={room.chatRoom.id}
                onClick={() => onChatRoomClick(room.chatRoom.id)}
                isActive={room.chatRoom.id === chatRoomId}
                isUnAnswered={
                  room.chatRoom.lastMessageCreatedBy != undefined &&
                  room.chatRoom.lastMessageCreatedBy !== props.participantId
                }
                chatRoomName={room.chatRoom.name}
                userName={userName}
                userIcon={room.individualChatWithOrganization.account.picture}
                isCsAdmin={props.isCsAdmin}
                createdAt={formatDateTime(room.chatRoom.createdAt)}
                communityId={room.chatRoom.communityId}
              />
            );
            /*
            QRコードから連絡されたが、まだ Approve されていない場合
          */
          } else {
            return (
              <ChatRoomIndividualFromQRCard
                key={room.chatRoom.id}
                onClick={() => onChatRoomClick(room.chatRoom.id)}
                isActive={room.chatRoom.id === chatRoomId}
                isUnAnswered={
                  room.chatRoom.lastMessageCreatedBy != undefined &&
                  room.chatRoom.lastMessageCreatedBy !== props.participantId
                }
                chatRoomName={room.chatRoom.name}
                userName={userName}
                userIcon={room.individualChatWithOrganization.account.picture}
                createdAt={formatDateTime(room.chatRoom.createdAt)}
              />
            );
          }
        } else if (
          room.chatRoom.type ===
            ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION &&
          room.individualChatWithBoardEducation
        ) {
          return (
            <ChatRoomIndividualBoardEducationCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              chatRoomName={
                room.individualChatWithBoardEducation.boardEducation?.name ?? ""
              }
              userIcon={room.individualChatWithBoardEducation.account.picture}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
            />
          );
        } else if (
          room.chatRoom.type === ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION &&
          room.groupChatWithOrganization
        ) {
          return (
            <ChatRoomGroupCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              isPending={
                room.chatRoom.ParticipantChatRooms.find(
                  (participantChatRoom) =>
                    participantChatRoom.participantId === props.participantId
                )?.approvalStatus === ApprovalStatus.PENDING
              }
              chatRoomName={room.chatRoom.name}
              iconFilePath={room.chatRoom.iconFilePath}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
              communityId={room.chatRoom.communityId}
              isAdmin={isAdmin}
            />
          );
        } else if (
          room.chatRoom.type === ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION &&
          room.groupChatWithBoardEducation
        ) {
          return (
            <ChatRoomGroupBoardEducationCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              isPending={
                room.chatRoom.ParticipantChatRooms.find(
                  (participantChatRoom) =>
                    participantChatRoom.participantId === props.participantId
                )?.approvalStatus === ApprovalStatus.PENDING
              }
              chatRoomName={room.chatRoom.name}
              iconFilePath={room.chatRoom.iconFilePath}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
            />
          );
        } else if (
          room.chatRoom.type === ChatRoomType.RECRUITMENT_APPLICATION &&
          room.recruitmentApplication
        ) {
          return (
            <ChatRoomRecruitmentCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              recruitmentApplication={room.recruitmentApplication}
              userName={room.recruitmentApplication.user.name}
              userIcon={room.recruitmentApplication.user.picture}
              chatRoom={room.chatRoom}
              isAdmin={isAdmin}
            />
          );
        } else if (
          room.chatRoom.type ===
            ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION &&
          room.recruitmentApplication
        ) {
          return (
            <ChatRoomRecruitmentBoardEducationCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              recruitmentApplication={room.recruitmentApplication}
              userName={room.recruitmentApplication.user.name}
              userIcon={room.recruitmentApplication.user.picture}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
              isAdmin={isAdmin}
            />
          );
        }
        return <></>;
      })}
      <ScrollMargin>
        <LoadMoreContainer ref={loadMoreRef}>
          {props.isFetchingNextPage && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
        </LoadMoreContainer>
      </ScrollMargin>
    </List>
  );
};

const List = styled.div`
  height: calc(100% - 121px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding: 0 24px;
`;

const ScrollMargin = styled.div`
  min-height: 80px;
  display: flex;
  align-items: flex-start;
  padding-top: 16px;
`;

const LoadMoreContainer = styled.div`
  padding: 24px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
