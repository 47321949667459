import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v4 as uuidv4 } from "uuid";
import { User } from "../../../apiClients/users";
import {
  Choice,
  MultiChoiceQuestion,
  OpenEndedQuestion,
  SurveyQuestions,
  SurveyQuestionsSchema,
} from "@shared/validator/features/survey.schema";
import { SURVEY_DEFAULT_DEADLINE } from "src/apiClients/survey";

export function createNewMultiChoiceQuestion(
  text: string = "",
  description: string = ""
): MultiChoiceQuestion {
  // 選択肢列、初めから二つ与える
  const choiceList: Choice[] = [createNewChoice(), createNewChoice()];
  const newQuestion: MultiChoiceQuestion = {
    id: uuidv4(),
    text,
    description,
    questionType: "MULTI_CHOICE",
    choiceList: choiceList,
    weightsNumberOfChildren: false,
    // TOOD: デフォルトでは唯一回答とする、要確認
    allowMultipleAnswers: false,
  };
  return newQuestion;
}
export function createNewOpenEndedQuestion(
  text: string = "",
  description: string = ""
): OpenEndedQuestion {
  const newQuestion: OpenEndedQuestion = {
    id: uuidv4(),
    text,
    description,
    questionType: "OPEN_ENDED",
  };
  return newQuestion;
}
// 新規選択肢作成のカーネル
export function createNewChoice(): Choice {
  const id: string = uuidv4();
  const newChoice: Choice = {
    id: id,
    text: "",
  };
  return newChoice;
}

export function getInitialSurveyQuestions(userId: string): SurveyQuestions {
  return {
    id: uuidv4(),
    userId: userId,
    state: "DRAFTED",
    questionList: [createNewMultiChoiceQuestion()],
    deadLine: new Date(
      new Date().getTime() + SURVEY_DEFAULT_DEADLINE
    ).toISOString(),
  };
}

export function useCreateSurvey(
  user: User,
  givenSurveyQuestions: SurveyQuestions | undefined
) {
  const methods = useForm<SurveyQuestions>({
    resolver: zodResolver(SurveyQuestionsSchema),
    defaultValues: givenSurveyQuestions ?? getInitialSurveyQuestions(user.id),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "questionList",
  });

  // 質問追加
  const handleAddQuestion = () => {
    append(createNewMultiChoiceQuestion());
  };

  // 質問削除
  const handleDeleteQuestion = (index: number) => {
    remove(index);
  };

  return {
    ...methods,
    fields,
    handleAddQuestion,
    handleDeleteQuestion,
  };
}
