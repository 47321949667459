import * as client from "./client";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import {
  ApplicationCountsForFilter,
  ApplicationStatusWithAll,
  RecruitmentApplicationListOrder,
  RecruitmentApplicationWithSingleEventStatus,
} from "@shared/types/recruitmentApplication";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import {
  ApplicationAddingScheduleBodySchema,
  ApplicationCountsForFilterType,
  ApplicationListFilterSchema,
  DownloadApplicationListPdfSchema,
  RecruitApplicationByEventIdQuerySchema,
} from "@shared/validator/features/recruitment-application.schema";

export const applicationStatusOptions = [
  { value: "APPLIED", text: "選考中" },
  { value: "APPROVED", text: "参加決定" },
  { value: "REJECTED", text: "見送り" },
  { value: "DECLINED", text: "不参加" },
] as const;

export async function getMyApplicationByRecruitmentId(
  recruitmentId: string
): Promise<RecruitmentApplication> {
  return await client.get<{}, RecruitmentApplication>(
    "/recruitment-application/getByRecruitmentId",
    {
      recruitmentId,
    }
  );
}

export async function getRecruitApplicationByEventId(
  data: RecruitApplicationByEventIdQuerySchema
): Promise<RecruitmentApplicationWithSingleEventStatus> {
  return client.get<
    RecruitApplicationByEventIdQuerySchema,
    RecruitmentApplicationWithSingleEventStatus
  >("/recruitment-application/getByEventId", data);
}

export async function getApplicationListByUserId(
  userId: string
): Promise<AppliedRecruitment[]> {
  return await client.get<{}, AppliedRecruitment[]>(
    "/recruitment-application/getListByUserId",
    {
      userId,
    }
  );
}

export async function getApplicationListByFilter(
  status: ApplicationStatusWithAll,
  name: string,
  recruitmentId: string | undefined,
  recruitmentScheduleId: string | undefined,
  order?: RecruitmentApplicationListOrder
): Promise<RecruitmentApplication[]> {
  return await client.get<
    ApplicationListFilterSchema,
    RecruitmentApplication[]
  >("/recruitment-application/getListByFilter", {
    status,
    name,
    recruitmentId,
    recruitmentScheduleId,
    order,
  });
}

/**
 * Filter用のデータ取得。UserTypeごとのCountとStatusごとのCountを取得
 */
export async function getApplicationCountsForFilter({
  name,
  status,
  recruitmentId,
  recruitmentScheduleId,
}: ApplicationCountsForFilterType): Promise<ApplicationCountsForFilter> {
  return await client.get<
    ApplicationCountsForFilterType,
    ApplicationCountsForFilter
  >("/recruitment-application/getApplicationCountsForFilter", {
    name,
    status,
    recruitmentId,
    recruitmentScheduleId,
  });
}

export async function downloadApplicationListPdfByFilter(
  data: DownloadApplicationListPdfSchema
): Promise<Blob> {
  return await client.getBlob<DownloadApplicationListPdfSchema, Blob>(
    "/recruitment-application/downloadListPdfByFilter",
    data
  );
}

export type CountApplicationResponse = {
  count: number;
};
export async function countApplication(): Promise<CountApplicationResponse> {
  return await client.get<{}, CountApplicationResponse>(
    "/recruitment-application/count",
    {}
  );
}

export async function updateAddingScheduleToExistApplication(
  body: ApplicationAddingScheduleBodySchema
): Promise<void> {
  await client.post<ApplicationAddingScheduleBodySchema, { result: string }>(
    "/recruitment-application/update-adding-schedule",
    body
  );
}
