import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useToast } from "../../../../components/Toast";
import { useCurrentUser } from "../../../../hooks/recoil/user";
import { ModalSubmitButtons } from "../../../../components/Modal";
import { Margin } from "../../../../components/Margin";
import { getStaticImageUrl } from "../../../../utils/getStaticImageUrl";
import { CurrentUser } from "src/apiClients/auth";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { Spinner } from "src/components/icons/Spinner";
import { Avatar } from "src/components/Common/Avatar";
import { createGroupChatRoomByCsAdmin } from "src/apiClients/chatRoom";
import { ChatRoomEntity } from "@shared/types/chatRoom";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { useGetResidentUsers } from "src/hooks/query/residentUsers";
import { SwitchBar } from "src/components/Common/SwitchBar";
import { useApplicantList } from "src/hooks/api/recruitmentApplication";
import { useRecruitmentList } from "src/hooks/query/recruitments/recruitmentList";
import { toDisplayDateFormatYearMonth } from "src/utils/time";
import { SearchIcon } from "src/components/icons/SearchIcon";
import { Heading4 } from "src/components/Typo";
import {
  Picture,
  UploadImagesContainer,
  UploadImgButton,
  TextField as TextField2,
} from "src/pages/pta/admin/ProfilePage";
import { CameraIcon } from "src/components/icons/CameraIcon";
import { TextField } from "src/components/form/TextField";
import { ResidentUser } from "src/apiClients/users";
import { logger } from "src/utils/logger";
import { Recruitment } from "@shared/types/recruitment";
import { ReFetchChatRoomList } from "src/hooks/query/chatRoomList";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export type ChatRoomAppenderFormValues = {
  name: string;
  accountIds: string[];
  file: File | undefined;
};

type Props = {
  close: () => void;
  chatRooms: ChatRoomEntity[] | undefined;
  refetch: ReFetchChatRoomList;
};

export const ChatRoomAppenderForGroup = ({
  close,
  chatRooms,
  refetch,
}: Props) => {
  const history = usePolyfitHistory();
  const toast = useToast();
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [isSelectionCompleted, setIsSelectionCompleted] = useState(false);
  const [selectedFilterLabelIndex, setSelectedFilterLabelIndex] =
    useState<number>(0);
  const filterLabels = [
    { label: "名簿", value: "ALL" },
    { label: "応募者", value: "APPLIED" },
    { label: "参加者", value: "APPROVED" },
  ] as const;
  const [selectedRecruitmentOption, setSelectedRecruitmentOption] =
    useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [selectAll, setSelectAll] = useState(false);
  const { applicantList } = useApplicantList(
    filterLabels[selectedFilterLabelIndex].value,
    ""
  );

  // TODO: デフォルト値として設定しているため、条件が変わった場合は変更する
  const filterCondition = {
    volunteerType: undefined,
    isPaidVolunteer: undefined,
    date: undefined,
    isOpen: false,
    origin: "CS",
  } as const;

  const { recruitments } = useRecruitmentList(filterCondition, {
    refetchOnMount: "always",
  });

  const currentUser: CurrentUser = useCurrentUser();

  const { isLoading: isResidentLoading, residents } = useGetResidentUsers(
    { roles: ["CSMember"] },
    {
      enabled: !!currentUser,
    }
  );

  // 自身を除いたユーザー
  const residentsWithoutCurrentUser = useMemo(
    () => residents?.filter((resident) => resident.id !== currentUser.id),
    [residents]
  );

  const filterResidentsBySearchText = (resident: ResidentUser) => {
    const nameKana =
      (resident.baseInfo?.firstNameKana || "") +
      (resident.baseInfo?.lastNameKana || "");
    return resident.name?.includes(searchText) || nameKana.includes(searchText);
  };

  const displayResidents = useMemo(() => {
    if (!residentsWithoutCurrentUser) return [];

    // 名簿 かつ 募集が選択されていない場合
    if (selectedFilterLabelIndex === 0 && selectedRecruitmentOption === "") {
      if (searchText === "") {
        return residentsWithoutCurrentUser;
      } else {
        return residentsWithoutCurrentUser.filter((resident) =>
          filterResidentsBySearchText(resident)
        );
      }
    }

    // 名簿、応募者、参加者の切り替え
    const applicantListUser = residentsWithoutCurrentUser.filter((resident) =>
      applicantList?.some(
        (applicant) => applicant.accountId === resident.account?.id
      )
    );
    if (selectedRecruitmentOption === "" && searchText === "")
      return applicantListUser;

    // 募集を選択した場合
    const selectedRecruitment = recruitments?.find(
      (recruitment) => recruitment.id === selectedRecruitmentOption
    );
    const recruitmentApplicants = applicantList?.filter(
      (applicant) =>
        !selectedRecruitment ||
        applicant.recruitment.id === selectedRecruitment?.id
    );
    const selectedRecruitmentUser = applicantListUser.filter((resident) =>
      recruitmentApplicants?.some(
        (applicant) => applicant.accountId === resident.account?.id
      )
    );

    if (searchText === "") return selectedRecruitmentUser;
    // 検索した場合
    const searchedUsers = selectedRecruitmentUser.filter((resident) => {
      return filterResidentsBySearchText(resident);
    });

    return searchedUsers;
  }, [
    applicantList,
    residentsWithoutCurrentUser,
    selectedRecruitmentOption,
    searchText,
  ]);

  const handleSelectAll = () => {
    const displayedIds = displayResidents
      .filter((resident) => !!resident.account)
      .map((resident) => resident.account!.id);
    if (selectAll) {
      //全選択を解除
      const newSelectedIds = selectedMemberIds.filter(
        (id) => !displayedIds.includes(id)
      );

      setSelectedMemberIds(newSelectedIds);
      setValue("accountIds", newSelectedIds);
    } else {
      //全選択
      displayedIds.map((displayedId) => {
        setSelectedMemberIds((prev) =>
          prev.includes(displayedId) ? prev : [...prev, displayedId]
        );
      });
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const allDisplayedSelected = displayResidents
      .filter((resident) => !!resident.account)
      .every((resident) => selectedMemberIds.includes(resident.account!.id));
    setSelectAll(allDisplayedSelected);
  }, [selectedMemberIds, displayResidents]);

  const defaultValues = {
    name: "",
    accountIds: [],
    file: undefined,
  };
  const { tab } = usePolyfitLocationQuery("RESIDENT_CHAT", {});
  const onChatRoomTransition = (roomId: string) => {
    history.push({
      to: "RESIDENT_CHAT",
      query: { chatRoomId: roomId, tab },
    });
  };

  const { register, setValue, getValues, handleSubmit, reset, watch } =
    useForm<ChatRoomAppenderFormValues>({
      defaultValues,
      mode: "onBlur",
    });
  const [imgPreviewUrl, setImgPreviewUrl] = useState<string | null>(null);
  const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("file", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const selectedAccountIds = getValues("accountIds");
  watch("accountIds");
  const name = watch("name");
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: ChatRoomAppenderFormValues) => {
    setDoubleClickBlocked(true);
    try {
      const chatRoom = await createGroupChatRoomByCsAdmin(
        data.name,
        data.accountIds,
        data.file
      );
      toast.success(
        "チャットルームの準備が整いました、さっそく会話を始めてみましょう！"
      );
      close();
      await refetch();
      onChatRoomTransition(chatRoom.id);
    } catch (err) {
      logger.error(err);
      toast.error("チャットルームの作成に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };
  useEffect(() => {
    if (selectedMemberIds.length !== 0) {
      setValue("accountIds", selectedMemberIds);
    }
  }, [selectedMemberIds]);

  const onChangeSearchText = (_searchText: string) => {
    setSearchText(_searchText);
  };

  const recruitmentOptions = recruitments?.map((recruitment: Recruitment) => {
    return {
      id: recruitment.id,
      value: recruitment.title,
      date: toDisplayDateFormatYearMonth(recruitment.schedule[0].start),
    };
  });

  const addAccountIdToSelectedMemberIds = (accountId: string) => {
    setSelectedMemberIds((prev) =>
      prev.includes(accountId)
        ? prev.filter((id) => id !== accountId)
        : [...prev, accountId]
    );
    setValue(
      "accountIds",
      getValues("accountIds").includes(accountId)
        ? getValues("accountIds").filter((id) => id !== accountId)
        : [...getValues("accountIds"), accountId]
    );
    const allDisplayedResidentsSelected = displayResidents
      .filter((resident) => !!resident.account)
      .every((resident) => selectedMemberIds.includes(resident.account!.id));
    setSelectAll(allDisplayedResidentsSelected);
  };

  const truncateString = (str: string): string => {
    if (str.length <= 5) {
      return str;
    }
    return str.slice(0, 5) + "...";
  };
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onClickFileInput = () => {
    inputFileRef.current?.click();
  };

  if (isResidentLoading) {
    return (
      <LoadingCard>
        <Spinner />
      </LoadingCard>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isSelectionCompleted && (
          <>
            <ModalHeader>グループメッセージ作成</ModalHeader>
            <TextField
              value={searchText}
              onChange={onChangeSearchText}
              placeholder="名前で検索"
              prepend={<SearchIcon size={20} />}
            />
            <Margin marginTop={12} />
            <InputSelect
              defaultValue=""
              onChange={(e) => {
                setSelectedRecruitmentOption(e.target.value);
              }}
            >
              <option value="">募集を選択</option>
              {recruitmentOptions.map((opt) => {
                return (
                  <option key={opt.id} value={opt.id}>
                    {`${opt.date}_${opt.value}`}
                  </option>
                );
              })}
            </InputSelect>
            <Margin marginTop={12} />
            <SwitchBar
              labels={filterLabels.map((l) => l.label)}
              onSelect={setSelectedFilterLabelIndex}
              selectedIndex={selectedFilterLabelIndex}
            ></SwitchBar>
            <Margin marginTop={12} />
            <NameListTable>
              <NameListColumnNameRow head={true}>
                <NameListColumn>
                  <span>名簿一覧</span>
                  <SelectAllCheckBox onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    <label onClick={handleSelectAll}>
                      全選択（{displayResidents.length}名）
                    </label>
                  </SelectAllCheckBox>
                </NameListColumn>
              </NameListColumnNameRow>
              <ScrollWrapper>
                {displayResidents?.length === 0 && (
                  <NonUserNameListColumnNameRow>
                    <Name>該当するユーザーがいません</Name>
                  </NonUserNameListColumnNameRow>
                )}
                {displayResidents
                  ?.filter((resident) => !!resident.account)
                  .map((resident) => {
                    return (
                      <NameListColumnNameRow
                        key={resident.id}
                        onClick={() => {
                          addAccountIdToSelectedMemberIds(resident.account!.id);
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedAccountIds.includes(
                            resident.account!.id
                          )}
                        />
                        <Avatar
                          src={resident.picture || defaultUserImg}
                          alt={resident.name}
                          size={40}
                        />
                        <Name>{resident.name}</Name>
                      </NameListColumnNameRow>
                    );
                  })}
              </ScrollWrapper>
            </NameListTable>
            {selectedAccountIds.length != 0 && (
              <SelectedAccountView>
                <p>選択中のメンバー</p>
                <SelectedAccountsList>
                  {selectedMemberIds.map((accountId) => {
                    const selectedResident = residentsWithoutCurrentUser.find(
                      (resident) => resident.account?.id === accountId
                    );

                    return (
                      <SelectedResident key={accountId}>
                        <AvatarWrapper>
                          <Avatar
                            src={selectedResident?.picture || defaultUserImg}
                            alt={selectedResident?.name || ""}
                            size={30}
                          />
                          <RemoveButton
                            onClick={() => {
                              addAccountIdToSelectedMemberIds(accountId);
                            }}
                          >
                            <CloseIcon />
                          </RemoveButton>
                        </AvatarWrapper>
                        <p>{truncateString(selectedResident!.name)}</p>
                      </SelectedResident>
                    );
                  })}
                </SelectedAccountsList>
              </SelectedAccountView>
            )}
            <Margin marginTop={20} />
            <ModalSubmitButtons
              submitText="次へ"
              onCancel={() => {
                reset(defaultValues);
                close();
              }}
              onSubmit={() => setIsSelectionCompleted(true)}
              disabled={selectedAccountIds.length == 0}
            />
          </>
        )}

        {isSelectionCompleted && (
          <>
            <ModalHeader>グループメッセージ作成</ModalHeader>
            <CreateChatRoomForms>
              <UploadImagesContainer>
                <Picture
                  src={imgPreviewUrl || defaultUserImg} //暫定でdefaultUserImg
                  alt="image"
                />
                <UploadImgButton type="button" onClick={onClickFileInput}>
                  <CameraIcon />
                </UploadImgButton>
                <input
                  ref={inputFileRef}
                  hidden
                  type="file"
                  name="image"
                  readOnly={true}
                  accept="image/jpeg, image/png"
                  onChange={handleIconChange}
                  id="file"
                />
              </UploadImagesContainer>
              <TextField2 {...register("name")} placeholder="ルーム名" />
            </CreateChatRoomForms>
            <SelectedAccountView>
              <Heading4>選択中のメンバー</Heading4>
              <SelectedAccountsList>
                {selectedMemberIds.map((accountId) => {
                  const selectedResident = residentsWithoutCurrentUser.find(
                    (resident) => resident.account?.id === accountId
                  );

                  return (
                    <SelectedResident key={accountId}>
                      <AvatarWrapper>
                        <Avatar
                          src={selectedResident?.picture || defaultUserImg}
                          alt={selectedResident?.name || ""}
                          size={30}
                        />
                      </AvatarWrapper>
                      <p>{truncateString(selectedResident!.name)}</p>
                    </SelectedResident>
                  );
                })}
              </SelectedAccountsList>
            </SelectedAccountView>
            <ModalSubmitButtons
              submitText="チャットを始める"
              cancelText="戻る"
              onCancel={() => setIsSelectionCompleted(false)}
              disabled={
                selectedAccountIds.length == 0 || !name || doubleClickBlocked
              }
            />
          </>
        )}
      </form>
    );
  }
};

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const NameListTable = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const NameListColumn = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  background-color: #f5f7fa;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const NonUserNameListColumnNameRow = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;
  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
`;

const NameListColumnNameRow = styled.div<{
  head?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;

  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
  ${(props) =>
    props.head &&
    `
    padding: 0px;
    border: none;
    background-color: #f5f7fa;
    font-weight: bold;
    color: #343741;
    cursor: default;
    &:hover {
      filter: brightness(100%);
    }
      span{
       pointer-events: none;
      }
    `};
`;

const ScrollWrapper = styled.div`
  max-height: 180px;
  overflow-y: auto;
`;

const Name = styled.p`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
`;

const ModalHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1a1c21;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectedAccountView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  p {
    font-size: 12px;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  width: fit-content;
`;

const RemoveButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: -8px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aab4c4;
  border-radius: 50%;
  background-color: #343741;
  padding: 3px;
`;

const SelectedResident = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  // justify-content:center;
  align-items: center;
  flex-shrink: 0;
`;

const SelectedAccountsList = styled.div`
  display: flex;
  gap: 12px;
  max-width: 100%;
  overflow-x: auto;
`;

const SelectAllCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  gap: 2px;
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`;

const CreateChatRoomForms = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
`;
