// post上に持つアンケート作成に関わる情報

import { SurveyQuestions } from "@shared/validator/features/survey.schema";

// アンケートが定義されている(true)か否(false)かを返す
export function isSurveyDefined(
  surveyQuestions: SurveyQuestions | undefined
): boolean {
  if (!surveyQuestions) {
    // アンケート実装前のポストはそもそもこのメンバーを持たない
    // falsyな値はすべて蹴るようにしておく
    return false;
  }
  return true;
}
