import React, { useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { SearchIcon } from "src/components/icons/SearchIcon";
import styled from "styled-components";
import { FormValues } from "./DestinationForm";
import { CheckBox } from "src/components/form/CheckBox";
import { sortByOrder } from "src/utils/sortByOrder";

type props = {
  targetBelongs: { id: string; name: string; order: number | null }[];
  setValue: UseFormSetValue<FormValues>;
  selectedIsAll: boolean;
  selectedBelongIds: string[];
};
function DestinationBelongTab({
  targetBelongs,
  setValue,
  selectedIsAll,
  selectedBelongIds,
}: props) {
  const [predictions, setPredictions] = useState<string[]>([]);
  const sortedTargetBelongs = sortByOrder(targetBelongs);
  const filterTargets = (searchText: string): string[] => {
    return [
      ...sortedTargetBelongs
        .filter(
          (target) =>
            target.name.includes(searchText) || searchText.includes(target.name)
        )
        .map((target) => target.id),
    ];
  };

  const onFocusSelectedTargetInput = () => {
    if (selectedIsAll) return setPredictions([]);
    setPredictions([
      ...sortedTargetBelongs
        .filter((target) => !selectedBelongIds.includes(target.id))
        .map((target) => target.id),
    ]);
  };

  const onChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPredictions(filterTargets(event.target.value));
  };

  const getLabel = (target: string) => {
    return sortedTargetBelongs.find((belong) => belong.id === target)?.name;
  };

  const handleChange = (target: string) => {
    const newSelectedBelongIds = selectedBelongIds.includes(target)
      ? selectedBelongIds.filter((id) => id !== target)
      : [...selectedBelongIds, target];
    setValue("targetBelongIds", newSelectedBelongIds);
  };

  return (
    <Tab>
      <SearchSection>
        <SearchBox>
          <SearchIcon size={16} />
          <SearchInput
            placeholder="検索"
            onFocus={onFocusSelectedTargetInput}
            onChange={onChangeSearchText}
          />
        </SearchBox>
        {predictions.length > 0 && (
          <>
            <PredictionList>
              {predictions.map((prediction) => (
                <PredictionListItem key={prediction}>
                  <CheckBox
                    checked={selectedBelongIds.includes(prediction)}
                    onChange={() => handleChange(prediction)}
                    label={getLabel(prediction)}
                  />
                </PredictionListItem>
              ))}
            </PredictionList>
            <Background onClick={() => setPredictions([])} />
          </>
        )}
      </SearchSection>
      <SelectBoxes>
        {sortedTargetBelongs.map((target) => (
          <SelectBox key={target.id}>
            <CheckBox
              checked={selectedBelongIds.includes(target.id)}
              onChange={() => handleChange(target.id)}
              label={target.name}
            />
          </SelectBox>
        ))}
      </SelectBoxes>
    </Tab>
  );
}

export default DestinationBelongTab;

const Tab = styled.div`
  position: relative;
`;

const SearchSection = styled.section`
  padding: 8px 12px;
  border-bottom: 1px solid #d3dae6;
`;

const SearchBox = styled.div`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 8px;
`;

const SearchInput = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: #fbfcfd;
`;

const PredictionList = styled.ul`
  position: absolute;
  z-index: 4;
  width: calc(100% - 24px);
  background: white;
  list-style: none;
  top: 44px;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const PredictionListItem = styled.li`
  border-bottom: 1px solid #d3dae6;
  padding: 8px 12px;
  display: flex;
  gap: 4px;
  font-weight: bold;
  font-size: 12px;
`;

const SelectBoxes = styled.section`
  max-height: 360px;
  overflow-y: auto;
`;

const SelectBox = styled.div`
  border-bottom: 1px solid #d3dae6;
  padding: 8px 12px;
  display: flex;
  gap: 4px;
  font-weight: bold;
  font-size: 12px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
`;
