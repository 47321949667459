import { ApplicationSelectionStatus } from "@shared/types/recruitmentApplicationEvent";

type EventStatus = {
  selectionStatus: ApplicationSelectionStatus;
};

export const getApplicationStatus = (
  eventStatuses: EventStatus[]
): ApplicationSelectionStatus => {
  // データ不整合への対処
  if (eventStatuses.length === 0) {
    return "APPLIED";
  }

  if (eventStatuses.length === 1) {
    // イベントが1つだけの場合、そのイベントの selectionStatus を返す
    return eventStatuses[0].selectionStatus;
  }

  // 全てのステータスが同じなら、そのステータスを返す
  if (
    eventStatuses.every(
      (event) => event.selectionStatus === eventStatuses[0].selectionStatus
    )
  ) {
    return eventStatuses[0].selectionStatus;
  }

  // 1つ以上のイベントが APPROVED の場合、APPROVED を返す
  if (eventStatuses.some((event) => event.selectionStatus === "APPROVED")) {
    return "APPROVED";
  }

  // それ以外の場合は APPLIED を返す
  return "APPLIED";
};
