import React, { useState, useMemo } from "react";
import { useCurrentUser, userState } from "../../../hooks/recoil/user";
import { Button } from "../../../components/Button";
import { ImgField } from "../../../components/Form";
import { PdfViewer } from "../../../components/PdfViewer";
import { entityUrl } from "../../../apiClients/storage";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import { StepAdmin } from "../../../components/icons/StepAdmin";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { useCustomReactHookForm } from "../../../components/form/ReactHookForm";
import { FilePlusIcon } from "../../../components/icons/FilePlusIcon";
import { useRecoilState } from "recoil";
import { completePTARegistration } from "../../../apiClients/auth";
import { isPtaAdminRole } from "../../../utils/types/role";
import { useSignatureTemplate } from "src/hooks/query/signatureTemplate";

export default function SignatureSettingPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { Form } = useCustomReactHookForm<{}>();
  const { query, mutation } = useSignatureTemplate({
    type: "PTA",
  });
  const signatureTemplate = useMemo(() => query.data, [query.data]);

  const [file, setFile] = useState<File>();
  const [, setUser] = useRecoilState(userState);

  const pdfFilePathForPreview = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else if (signatureTemplate && signatureTemplate.pdfFilePath)
      return entityUrl(signatureTemplate.pdfFilePath);
    else return null;
  }, [file, signatureTemplate]);

  const isValid = useMemo(() => {
    if (!signatureTemplate) {
      return file;
    } else if (file) {
      return file;
    }
  }, [file, signatureTemplate]);

  const onSave = () => {
    if (file) {
      mutation.mutate(file, { onSuccess: () => setFile(undefined) });
    }
  };

  const onSubmit = async () => {
    await completePTARegistration();
    setUser({ ...user, signUpState: true });
    if (isPtaAdminRole(user.role)) {
      history.push({ to: "LOADING", query: {} });
    } else {
      history.push({ to: "PTA_INDEX" });
    }
  };

  return (
    <div>
      <Margin marginTop={20} />
      <StepAdmin stepNum={5} />
      <Form onSubmit={onSubmit}>
        <StyledContainer>
          <Margin marginTop={20} />
          <Typo.Heading3>入会規約の設定</Typo.Heading3>
          <Responsive.Row>
            <Responsive.Col>
              <Margin marginTop={20} />
              <Text>PTAの目的や運営ルールを記載した規約を設定できます</Text>
              <Margin marginTop={20} />
              <ImgField accept="application/pdf" onChange={setFile}>
                {pdfFilePathForPreview ? (
                  <PdfViewer file={pdfFilePathForPreview} />
                ) : (
                  <FilePlusIcon />
                )}
              </ImgField>
              {!mutation.isPending && (
                <Button
                  size="large"
                  type="button"
                  disabled={!isValid}
                  fill
                  style={{ marginLeft: "8px" }}
                  onClick={onSave}
                >
                  保存する
                </Button>
              )}
              {mutation.isPending && (
                <Button
                  size="large"
                  disabled
                  aria-busy
                  fill
                  style={{ marginLeft: "8px" }}
                >
                  保存中
                </Button>
              )}
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter></RegistrationFooter>
      </Form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;
const Text = styled(Typo.Paragraph)`
  color: gray;
`;
