import * as client from "./client";
import {
  DeclineByRecruitmentApplicationEventBySelfSchema,
  UpdateApplicationEventSchema,
  UpdateEventAttendanceStatusSchema,
} from "@shared/validator/features/recruitmentApplicationEvent.schema";

/**
 * 応募系APIクライアント 更新系
 */
export async function approveApplication(
  recruitApplicationEventId: string
): Promise<void> {
  await client.post<UpdateApplicationEventSchema, { result: string }>(
    "/recruitment-application-event/approve",
    { recruitApplicationEventId }
  );
}

export async function rejectApplication(
  recruitApplicationEventId: string,
  comment: string
): Promise<void> {
  await client.post<UpdateApplicationEventSchema, { result: string }>(
    "/recruitment-application-event/reject",
    {
      recruitApplicationEventId,
      comment,
    }
  );
}

export async function declineApplicationByAdmin(
  recruitApplicationEventId: string,
  comment: string
): Promise<void> {
  await client.post<UpdateApplicationEventSchema, { result: string }>(
    "/recruitment-application-event/declineByAdmin",
    {
      recruitApplicationEventId,
      comment,
    }
  );
}

export async function declineApplicationBySelf(
  data: DeclineByRecruitmentApplicationEventBySelfSchema
): Promise<void> {
  await client.post<
    DeclineByRecruitmentApplicationEventBySelfSchema,
    { result: string }
  >("/recruitment-application-event/declineBySelf", data);
}

export async function updateAttendanceStatus(
  data: UpdateEventAttendanceStatusSchema
): Promise<void> {
  await client.post<UpdateEventAttendanceStatusSchema, { result: string }>(
    "/recruitment-application-event/updateAttendanceStatus",
    data
  );
}
