import React, { useEffect } from "react";
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormTrigger,
} from "react-hook-form";
import { TrashIcon } from "../../../components/icons/TrashIcon2";
import styled from "styled-components";
import { RecruitmentFormValue } from "./useRecruitmentForm";
import { BREAKPOINTS } from "../../../components/Responsive";
import { DatePicker } from "../../../components/form/DatePicker";
import { TimePicker } from "../../../components/form/TimePicker";

interface Props {
  control: Control<RecruitmentFormValue>;
  errors: FieldErrors<RecruitmentFormValue>;
  trigger: UseFormTrigger<RecruitmentFormValue>;
}

export default function ResidentRecruitmentFormScheduleInput({
  control,
  errors,
  trigger,
}: Props) {
  const { fields, append, remove, replace } = useFieldArray({
    name: "schedule",
    control,
  });

  const handleAddSchedule = () => {
    append({ date: "", startTime: "", endTime: "" }, { shouldFocus: false });
  };

  useEffect(() => {
    if (fields.length === 0) {
      replace([{ date: "", startTime: "", endTime: "" }]);
    }
  }, [replace]);
  return (
    <>
      {fields.map((field, index) => (
        <FieldWrapper key={field.id}>
          <DatePicker
            control={control}
            name={`schedule.${index}.date`}
            errorMessage={errors.schedule?.[index]?.date?.message}
            trigger={trigger}
            triggeredValue={"schedule"}
          />
          <StartEndInputWrapper>
            <TimePicker
              control={control}
              name={`schedule.${index}.startTime`}
              errorMessage={errors.schedule?.[index]?.startTime?.message}
              trigger={trigger}
              triggeredValue={"schedule"}
            />
            <StartToEnd>〜</StartToEnd>
            <TimePicker
              control={control}
              name={`schedule.${index}.endTime`}
              errorMessage={errors.schedule?.[index]?.endTime?.message}
              trigger={trigger}
              triggeredValue={"schedule"}
            />
          </StartEndInputWrapper>
          {fields.length > 1 && (
            <TrashIconWrapper onClick={() => remove(index)}>
              <TrashIcon />
            </TrashIconWrapper>
          )}
        </FieldWrapper>
      ))}
      {errors.schedule?.message && !errors.schedule?.root?.message && (
        <span className="error-message">{errors.schedule.message}</span>
      )}
      {errors.schedule?.root?.message && (
        <span className="error-message">{errors.schedule.root.message}</span>
      )}
      <TextButton onClick={handleAddSchedule}>+時間を追加</TextButton>
    </>
  );
}

const FieldWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;

  @media (max-width: ${BREAKPOINTS.PC}) {
    flex-direction: column;
    gap: 8px;
  }

  @media (min-width: ${BREAKPOINTS.PC}) {
    width: 100%;
    gap: 20px;
  }
`;

const StartEndInputWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const StartToEnd = styled.p`
  padding: 10px 6px;
`;

const TrashIconWrapper = styled.p`
  cursor: pointer;
  padding: 14px 0;
  @media (max-width: ${BREAKPOINTS.PC}) {
    padding: 0;
  }
  margin-left: auto;
  width: fit-content;
`;

export const TextButton = styled.div`
  margin-left: auto;
  color: #005ec4;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
`;
