import { z } from "zod";
import { zRequiredString } from "../rules";
import { applicationAttendanceStatus } from "../../types/recruitmentApplicationEvent";

export const updateApplicationEventSchema = z.object({
  recruitApplicationEventId: z.string(),
  comment: z.string().optional(),
});

export type UpdateApplicationEventSchema = z.infer<
  typeof updateApplicationEventSchema
>;

export const updateApplicationEventWithCommentSchema =
  updateApplicationEventSchema.extend({
    comment: z.string(),
  });

export type UpdateApplicationEventWithCommentSchema = z.infer<
  typeof updateApplicationEventWithCommentSchema
>;

export const declineByRecruitmentApplicationEventBySelfSchema = z.object({
  recruitmentId: z.string(),
  selectedScheduleIds: z
    .array(z.string())
    .min(1, "少なくとも1つの日程を選択してください"),
  comment: z.string().max(3000, "3000文字以内で入力してください"),
});

export type DeclineByRecruitmentApplicationEventBySelfSchema = z.infer<
  typeof declineByRecruitmentApplicationEventBySelfSchema
>;

export const updateEventAttendanceStatusSchema = z.object({
  attendanceStatus: z.union([
    z.literal(applicationAttendanceStatus.ATTENDED),
    z.literal(applicationAttendanceStatus.ABSENT),
    z.literal(null),
  ]),
  recruitApplicationEventId: zRequiredString,
});

export type UpdateEventAttendanceStatusSchema = z.infer<
  typeof updateEventAttendanceStatusSchema
>;
