import React from "react";
import {
  Path,
  Controller,
  ControllerProps,
  FieldValues,
  FieldPath,
  Control,
  UseFormTrigger,
} from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { toInputDateFormatYearMonth } from "../../utils/time";
import styled, { css } from "styled-components";

type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  minDate?: Date | null;
  maxDate?: Date | null;
  control: Control<TFieldValues>;
  errorMessage?: string | undefined;
  trigger?: UseFormTrigger<TFieldValues>;
  triggeredValue?: Path<TFieldValues> | Path<TFieldValues>[];
};

export const DatePicker = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      render={({ field: { onBlur, onChange, value } }) => (
        <DateInputWrapper disabled={props.disabled}>
          <ReactDatePicker
            disabled={props.disabled}
            dateFormat="yyyy/MM/dd"
            onChange={(date) => {
              onChange(toInputDateFormatYearMonth(date ?? undefined));
              props.trigger?.(props.triggeredValue);
            }}
            isClearable
            onBlur={onBlur}
            selected={value ? new Date(value) : undefined}
            placeholderText="2000/01/01"
            minDate={props.minDate ? props.minDate : new Date("1900-01-01")}
            maxDate={props.maxDate ? props.maxDate : new Date("2100-12-31")}
          />
          {props.errorMessage && (
            <span className="error-message">{props.errorMessage}</span>
          )}
        </DateInputWrapper>
      )}
      {...props}
      control={props.control}
    />
  );
};

const DateInputWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  flex: 1;

  ${(props) =>
    props.disabled &&
    css`
      color: #aab4c4;
      background-color: #fbfcfd;
      cursor: not-allowed;
      opacity: 0.5;
      border-color: #ddd;

      input {
        color: #aab4c4;
        cursor: not-allowed;
        opacity: 0.5;
        border-color: #ddd;

        &::placeholder {
          color: #aab4c4;
        }
      }
    `}
`;
