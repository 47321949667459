import { Recruitment } from "@shared/types/recruitment";
import { RecruitmentSchedule } from "@shared/types/recruitmentSchedule";

export type RecruitmentDateStatus = "Today" | "Future" | "Past";

export const getDateStatus = (date: Date): RecruitmentDateStatus => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const targetDate = new Date(date);
  targetDate.setHours(0, 0, 0, 0);

  if (targetDate.getTime() === today.getTime()) return "Today";
  if (targetDate > today) return "Future";
  return "Past";
};

export const getRecruitmentStatus = (
  recruitment: Recruitment
): RecruitmentDateStatus => {
  const statuses = recruitment.schedule.map((schedule) =>
    getDateStatus(new Date(schedule.date))
  );

  if (statuses.includes("Future")) return "Future";
  if (statuses.includes("Today")) return "Today";
  return "Past";
};

export const canCancelSchedule = (status: RecruitmentDateStatus): boolean => {
  return status === "Future";
};

export const isScheduleEnded = (schedule: RecruitmentSchedule) => {
  if (schedule.end === null) return false;
  const today = new Date();
  return new Date(schedule.end) < today;
};
