import React, { useEffect } from "react";
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN ?? "";
const sentryIsEnabled = !!SENTRY_DSN;

import * as Sentry from "@sentry/react";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useCurrentCommunityId } from "src/hooks/router";
import { useApiContext } from "src/apiClients/client";

export const logger = {
  debug(message: string) {
    console.debug(message);
  },

  info(message: string) {
    console.info(message);
  },

  warn(message: string) {
    console.warn(message);
  },

  error(err: Error | unknown, extra?: { [key: string]: any }) {
    if (sentryIsEnabled) {
      if (extra) {
        Sentry.captureException(err, { extra });
      } else {
        Sentry.captureException(err);
      }
    }
    console.error(err, extra);
  },
};

export const initializeLogger = () => {
  if (!sentryIsEnabled) return;
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [import.meta.env.VITE_SERVER_URL],
        maxReplayDuration: 300 * 1000,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const SetSentryScope = () => {
  const { apiContext } = useApiContext();
  const user = useCurrentUser();
  const communityId = useCurrentCommunityId();
  useEffect(() => {
    setUserToCurrentScope(user);
    // account
    setTagToCurrentScope("communityId", communityId.communityId);
    if (user.account) {
      setTagToCurrentScope("accountId", user.account.id);
      setTagToCurrentScope("organizationId", user.account.organizationId);
    }
  }, [apiContext]);
  return null;
};

function setUserToCurrentScope(user: { id?: string; email?: string }) {
  if (!sentryIsEnabled) return;
  const scope = Sentry.getCurrentScope();
  scope.setUser({
    id: user.id,
    email: user.email,
  });
}

function setTagToCurrentScope(
  key: "communityId" | "accountId" | "organizationId",
  value: string
) {
  if (!sentryIsEnabled) return;

  const scope = Sentry.getCurrentScope();
  scope.setTag(key, value);
}
