import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useToast } from "../../../../components/Toast";
import { useCurrentUser } from "../../../../hooks/recoil/user";
import { ModalSubmitButtons } from "../../../../components/Modal";
import { Margin } from "../../../../components/Margin";
import { getStaticImageUrl } from "../../../../utils/getStaticImageUrl";
import { CurrentUser } from "src/apiClients/auth";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { Spinner } from "src/components/icons/Spinner";
import { Avatar } from "src/components/Common/Avatar";
import { getOrCreateChatRoomByCsAdmin } from "src/apiClients/chatRoom";
import { ChatRoomEntity } from "@shared/types/chatRoom";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { colorsPallet } from "src/theme";
import { useGetResidentUsers } from "src/hooks/query/residentUsers";
import { SwitchBar } from "src/components/Common/SwitchBar";
import { useApplicantList } from "src/hooks/api/recruitmentApplication";
import { useRecruitmentList } from "src/hooks/query/recruitments/recruitmentList";
import { toDisplayDateFormatYearMonth } from "src/utils/time";
import { SearchIcon } from "src/components/icons/SearchIcon";
import { TextField } from "src/components/form/TextField";
import { ResidentUser } from "src/apiClients/users";
import { logger } from "src/utils/logger";
import { Recruitment } from "@shared/types/recruitment";
import { ReFetchChatRoomList } from "src/hooks/query/chatRoomList";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export type ChatRoomAppenderFormValues = {
  accountId: string;
};

type Props = {
  close: () => void;
  chatRooms: ChatRoomEntity[] | undefined;
  refetch: ReFetchChatRoomList;
};

export const ChatRoomAppenderForIndividual = ({
  close,
  chatRooms,
  refetch,
}: Props) => {
  const history = usePolyfitHistory();
  const toast = useToast();
  const [selectedMemberId, setSelectedMemberId] = useState<string>("");
  const [selectedFilterLabelIndex, setSelectedFilterLabelIndex] =
    useState<number>(0);
  const filterLabels = [
    { label: "名簿", value: "ALL" },
    { label: "応募者", value: "APPLIED" },
    { label: "参加者", value: "APPROVED" },
  ] as const;
  const [selectedRecruitmentOption, setSelectedRecruitmentOption] =
    useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const { applicantList } = useApplicantList(
    filterLabels[selectedFilterLabelIndex].value,
    ""
  );

  // TODO: デフォルト値として設定しているため、条件が変わった場合は変更する
  const filterCondition = {
    volunteerType: undefined,
    isPaidVolunteer: undefined,
    date: undefined,
    isOpen: false,
    origin: "CS",
  } as const;

  const { recruitments } = useRecruitmentList(filterCondition, {
    refetchOnMount: "always",
  });

  const currentUser: CurrentUser = useCurrentUser();

  const { isLoading: isResidentLoading, residents } = useGetResidentUsers(
    { roles: ["CSMember"] },
    {
      enabled: !!currentUser,
    }
  );

  // 自身を除いたユーザー
  const residentsWithoutCurrentUser = useMemo(
    () => residents?.filter((resident) => resident.id !== currentUser.id),
    [residents]
  );

  const filterResidentsBySearchText = (resident: ResidentUser) => {
    const nameKana =
      (resident.baseInfo?.firstNameKana || "") +
      (resident.baseInfo?.lastNameKana || "");
    return resident.name?.includes(searchText) || nameKana.includes(searchText);
  };

  const displayResidents = useMemo(() => {
    if (!residentsWithoutCurrentUser) return [];

    // 名簿 かつ 募集が選択されていない場合
    if (selectedFilterLabelIndex === 0 && selectedRecruitmentOption === "") {
      if (searchText === "") {
        return residentsWithoutCurrentUser;
      } else {
        return residentsWithoutCurrentUser.filter((resident) =>
          filterResidentsBySearchText(resident)
        );
      }
    }

    // 名簿、応募者、参加者の切り替え
    const applicantListUser = residentsWithoutCurrentUser.filter((resident) =>
      applicantList?.some(
        (applicant) => applicant.accountId === resident.account?.id
      )
    );
    if (selectedRecruitmentOption === "" && searchText === "")
      return applicantListUser;

    // 募集を選択した場合
    const selectedRecruitment = recruitments?.find(
      (recruitment) => recruitment.id === selectedRecruitmentOption
    );
    const recruitmentApplicants = applicantList?.filter(
      (applicant) =>
        !selectedRecruitment ||
        applicant.recruitment.id === selectedRecruitment?.id
    );
    const selectedRecruitmentUser = applicantListUser.filter((resident) =>
      recruitmentApplicants?.some(
        (applicant) => applicant.accountId === resident.account?.id
      )
    );

    if (searchText === "") return selectedRecruitmentUser;
    // 検索した場合
    const searchedUsers = selectedRecruitmentUser.filter((resident) => {
      return filterResidentsBySearchText(resident);
    });

    return searchedUsers;
  }, [
    applicantList,
    residentsWithoutCurrentUser,
    selectedRecruitmentOption,
    searchText,
  ]);

  const defaultValues = {
    accountId: undefined,
  };

  const { tab } = usePolyfitLocationQuery("RESIDENT_CHAT", {});
  const onChatRoomTransition = (roomId: string) => {
    history.push({
      to: "RESIDENT_CHAT",
      query: { chatRoomId: roomId, tab },
    });
  };

  const { setValue, getValues, handleSubmit, reset, watch } =
    useForm<ChatRoomAppenderFormValues>({
      defaultValues,
      mode: "onBlur",
    });

  const selectedAccountId = getValues("accountId");
  watch("accountId");
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: ChatRoomAppenderFormValues) => {
    setDoubleClickBlocked(true);
    try {
      const chatRoom = await getOrCreateChatRoomByCsAdmin(data.accountId);
      toast.success(
        "チャットルームの準備が整いました、さっそく会話を始めてみましょう！"
      );
      close();
      await refetch();
      onChatRoomTransition(chatRoom.id);
    } catch (err) {
      logger.error(err);
      toast.error("チャットルームの作成に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  useEffect(() => {
    if (selectedMemberId !== "") {
      setValue("accountId", selectedMemberId);
    }
  }, [selectedMemberId]);

  const onChangeSearchText = (_searchText: string) => {
    setSearchText(_searchText);
  };

  const isAlreadyCreatedChatRoomUserAccountIds = chatRooms?.map(
    (chatRoom) => chatRoom.individualChatWithOrganization?.account.id
  );

  const recruitmentOptions = recruitments?.map((recruitment: Recruitment) => {
    return {
      id: recruitment.id,
      value: recruitment.title,
      date: toDisplayDateFormatYearMonth(recruitment.schedule[0].start),
    };
  });

  if (isResidentLoading) {
    return (
      <LoadingCard>
        <Spinner />
      </LoadingCard>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>個別メッセージ作成</ModalHeader>
        <TextField
          value={searchText}
          onChange={onChangeSearchText}
          placeholder="名前で検索"
          prepend={<SearchIcon size={20} />}
        />
        <Margin marginTop={12} />
        <InputSelect
          defaultValue=""
          onChange={(e) => {
            setSelectedRecruitmentOption(e.target.value);
          }}
        >
          <option value="">募集を選択</option>
          {recruitmentOptions.map((opt) => {
            return (
              <option key={opt.id} value={opt.id}>
                {`${opt.date}_${opt.value}`}
              </option>
            );
          })}
        </InputSelect>
        <Margin marginTop={12} />
        <SwitchBar
          labels={filterLabels.map((l) => l.label)}
          onSelect={setSelectedFilterLabelIndex}
          selectedIndex={selectedFilterLabelIndex}
        ></SwitchBar>
        <Margin marginTop={12} />
        <NameListTable>
          <NameListColumnNameRow head={true}>
            <NameListColumn>名簿一覧</NameListColumn>
          </NameListColumnNameRow>
          <ScrollWrapper>
            {displayResidents?.length === 0 && (
              <NonUserNameListColumnNameRow>
                <Name>該当するユーザーがいません</Name>
              </NonUserNameListColumnNameRow>
            )}
            {displayResidents
              ?.filter((resident) => !!resident.account)
              .map((resident) => {
                return (
                  <NameListColumnNameRow
                    key={resident.id}
                    onClick={() => {
                      setValue("accountId", resident.account!.id);
                    }}
                    disabled={isAlreadyCreatedChatRoomUserAccountIds?.includes(
                      resident.account!.id
                    )}
                  >
                    <input
                      type="radio"
                      checked={selectedAccountId === resident.account!.id}
                    />
                    <Avatar
                      src={resident.picture || defaultUserImg}
                      alt={resident.name}
                      size={40}
                    />
                    <Name>
                      {resident.name}
                      {isAlreadyCreatedChatRoomUserAccountIds?.includes(
                        resident.account!.id
                      ) && (
                        <IsAlreadyCreatedText> ※作成済み</IsAlreadyCreatedText>
                      )}
                    </Name>
                  </NameListColumnNameRow>
                );
              })}
          </ScrollWrapper>
        </NameListTable>
        {selectedAccountId && (
          <SelectedAccountView>
            <p>選択中のメンバー</p>
            <AvatarWrapper>
              <Avatar
                src={
                  residentsWithoutCurrentUser.find(
                    (resident) => resident.account?.id === selectedAccountId
                  )?.picture || defaultUserImg
                }
                alt={
                  residentsWithoutCurrentUser.find(
                    (resident) => resident.account?.id === selectedAccountId
                  )?.name || ""
                }
                size={30}
              />
              <RemoveButton
                onClick={() => {
                  setValue("accountId", "");
                  setSelectedMemberId("");
                }}
              >
                <CloseIcon />
              </RemoveButton>
            </AvatarWrapper>

            <p>
              {
                residentsWithoutCurrentUser.find(
                  (resident) => resident.account?.id === selectedAccountId
                )?.name
              }
            </p>
          </SelectedAccountView>
        )}

        <Margin marginTop={20} />
        <ModalSubmitButtons
          submitText="チャットを始める"
          onCancel={() => {
            reset(defaultValues);
            close();
          }}
          disabled={!selectedAccountId || doubleClickBlocked}
        />
      </form>
    );
  }
};

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const NameListTable = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const NameListColumn = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  background-color: #f5f7fa;
`;

const NonUserNameListColumnNameRow = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;
  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
`;

const NameListColumnNameRow = styled.div<{
  head?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;

  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
  ${(props) =>
    props.head &&
    `
    padding: 0px;
    pointer-events: none;
    border: none;
    background-color: #f5f7fa;
    font-weight: bold;
    color: #343741;
    &:hover {
      filter: brightness(100%);
    }
    `};

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.6;
    pointer-events: none;
    `};
`;

const ScrollWrapper = styled.div`
  max-height: 180px;
  overflow-y: auto;
`;

const Name = styled.p`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
`;

const IsAlreadyCreatedText = styled.span`
  font-size: 12px;
  color: ${colorsPallet.danger};
`;

const ModalHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1a1c21;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectedAccountView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  p {
    font-size: 12px;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  width: fit-content;
`;

const RemoveButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: -8px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aab4c4;
  border-radius: 50%;
  background-color: #343741;
  padding: 3px;
`;
