import { atom, useRecoilState } from "recoil";
import { ChildrenFilterType } from "../api/child";
import { recoilPersist } from "recoil-persist";
import { Organization } from "@shared/types/organization";
import { useEffect } from "react";

const { persistAtom } = recoilPersist();

const childrenFilterState = atom<ChildrenFilterType>({
  key: "ChildrenFilter",
  default: {
    grades: [1, 2, 3, 4, 5, 6],
    classes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 100, -1],
    parentsAreUnique: undefined,
    includeAdmin: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useChildrenFilter = (organization: Organization | undefined) => {
  const [childrenFilter, setChildrenFilter] =
    useRecoilState(childrenFilterState);

  useEffect(() => {
    if (!organization) return;
    setChildrenFilter((prev) => {
      return {
        ...prev,
      };
    });
  }, [organization]);

  return [childrenFilter, setChildrenFilter] as const;
};
