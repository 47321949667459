import { RecruitmentStatus } from "./recruitment";
import { CommunityUserType } from "./userType";
import {
  ApplicationAttendanceStatus,
  ApplicationSelectionStatus,
} from "./recruitmentApplicationEvent";
import { CSChild } from "./csChild";

export const ApplicationStatus = {
  APPLIED: "APPLIED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DECLINED: "DECLINED",
} as const;

export type ApplicationStatus =
  (typeof ApplicationStatus)[keyof typeof ApplicationStatus];

export const ApplicationStatusWithAll = {
  ...ApplicationStatus,
  ALL: "ALL",
} as const;

export type ApplicationStatusWithAll =
  (typeof ApplicationStatusWithAll)[keyof typeof ApplicationStatusWithAll];

type EventStatus = {
  id: string;
  selectionStatus: ApplicationSelectionStatus;
  rejectedAt: Date | null;
  attendanceStatus: ApplicationAttendanceStatus | null;
  recruitmentSchedule: {
    id: string;
    start: Date;
    end: Date;
    date: Date;
  };
};

/**
 * 応募系APIクライアント 取得系
 */
export type RecruitmentApplication = {
  id: string;
  accountId: string;
  user: {
    id: string;
    email: string;
    name: string;
    picture: string | null;
  };
  coverLetterChatMessage?: {
    content: string;
  };
  communityUserType: CommunityUserType | null;
  recruitment: {
    id: string;
    picture: string | null;
    title: string | null;
    status: RecruitmentStatus;
    createdBy?: string;
  };
  eventStatuses: EventStatus[];
  cSChildren?: CSChild[];
};

export type RecruitmentApplicationWithSingleEventStatus = Omit<
  RecruitmentApplication,
  "cSChildren" | "eventStatuses"
> & {
  eventStatus: EventStatus;
};

export type ApplicationStatusCount = {
  status: ApplicationStatus;
  count: number;
};

export type ApplicationUserTypeCounts = {
  type: CommunityUserType;
  count: number;
};

export type ApplicationCountsForFilter = {
  statusCounts: ApplicationStatusCount[];
  userTypeCounts: ApplicationUserTypeCounts[];
};

export const RecruitmentApplicationListOrder = {
  USER_NAME: "USER_NAME",
  RECRUITMENT_TITLE: "RECRUITMENT_TITLE",
  RECRUITMENT_START_DATE: "RECRUITMENT_START_DATE",
} as const;

export type RecruitmentApplicationListOrder =
  (typeof RecruitmentApplicationListOrder)[keyof typeof RecruitmentApplicationListOrder];
