import { CSChild } from "@shared/types/csChild";
import * as client from "./client";
import { CsChildUpdateQueryType } from "@shared/validator/features/csChild.schema";

export async function getChildrenByAccountId(accountId: string) {
  return await client.get<{}, CSChild[]>("/csChild/getChildrenByAccountId", {
    accountId,
  });
}

export async function updateChildren(data: CsChildUpdateQueryType) {
  return await client.post<{}, CsChildUpdateQueryType>(
    "/csChild/updateChildren",
    data
  );
}

export async function deleteChildren(accountId: string) {
  return await client.post<{}, {}>("/csChild/deleteChildren", { accountId });
}

export async function getChildrenByOrganizationId() {
  return await client.get<{}, CSChild[]>(
    "/csChild/getChildrenByOrganizationId",
    {}
  );
}
