import { Recruitment } from "@shared/types/recruitment";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";

export const hasNotAppliedSchedule = (
  schedules: Recruitment["schedule"],
  application:
    | Pick<Required<RecruitmentApplication>, "eventStatuses">
    | undefined
): boolean => {
  if (!application) return false;

  const now = new Date();

  return schedules.some((schedule) => {
    const isApplied = application.eventStatuses.some(
      (status) => status.recruitmentSchedule.id === schedule.id
    );
    const isFuture = new Date(schedule.start) > now;
    const isAvailable =
      schedule.status === "OPENED" && schedule.closedAt === null;
    return !isApplied && isFuture && isAvailable;
  });
};
