import styled from "styled-components";
import React, { Dispatch, SetStateAction } from "react";
import { Margin } from "./Margin";
import { useForm } from "react-hook-form";
import { ChildrenFilterType } from "../hooks/api/child";
import { CheckBox, CheckBoxRHF } from "./form/CheckBox";
import { Switch } from "./form/Switch";
import { zIndexes } from "../zIndex";
import { ChildClass, ChildGrade } from "../apiClients/child";
import { getClassName } from "../utils/getChildClass";
import { Organization } from "@shared/types/organization";
import { getGradesBySchoolType } from "@shared/utils/getGradesBySchoolType";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

export type Props = {
  organization: Organization | undefined;
  setListFilter: Dispatch<SetStateAction<ChildrenFilterType>>;
  listFilter: ChildrenFilterType;
  onClose: () => void;
};

export const ListPageFilterSetting = ({
  organization,
  setListFilter,
  onClose,
  listFilter,
}: Props) => {
  if (!organization) return <></>;

  const { register, watch, setValue, control } = useForm<ChildrenFilterType>({
    defaultValues: listFilter,
  });

  /**
   * 学年一覧
   */
  const grades: ChildGrade[] = getGradesBySchoolType(organization.schoolType);

  /**
   * クラス一覧
   * 100はその他を表す
   * -1は未設定を表す
   */
  const classes: ChildClass[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 100, -1,
  ];

  watch((value, { name, type }) => {
    setListFilter(value);
  });

  const checkAllGrades = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setValue("grades", grades);
    } else {
      setValue("grades", []);
    }
  };

  const checkAllClasses = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setValue("classes", classes);
    } else {
      setValue("classes", []);
    }
  };

  const COLUMN_NUM = 3;

  return (
    <>
      <FilterModalWrap>
        <FilterModal>
          <form action="">
            <Label>
              <CheckBox defaultChecked onChange={checkAllGrades} label="学年" />
            </Label>
            {Array.from({ length: Math.ceil(grades.length / COLUMN_NUM) }).map(
              (_, i) => {
                return (
                  <CheckBoxRow key={i}>
                    {grades
                      .slice(i * COLUMN_NUM, i * COLUMN_NUM + COLUMN_NUM)
                      .map((grade) => {
                        return (
                          <CheckBoxWrapper key={grade}>
                            <CheckBoxRHF
                              label={`${getGradeLabel(
                                grade,
                                organization.schoolDisplayType,
                                true,
                                true,
                                "short",
                                organization.schoolDisplayType === "CONTINUOUS"
                                  ? "年生"
                                  : ""
                              )}`}
                              control={control}
                              value={grade}
                              name="grades"
                            />
                          </CheckBoxWrapper>
                        );
                      })}
                  </CheckBoxRow>
                );
              }
            )}

            <Margin marginTop={15} />
            <Label>
              <CheckBox
                defaultChecked
                onChange={checkAllClasses}
                label="クラス"
              />
            </Label>
            {Array.from({ length: Math.ceil(classes.length / COLUMN_NUM) }).map(
              (_, i) => {
                return (
                  <CheckBoxRow key={i}>
                    {classes
                      .slice(i * COLUMN_NUM, i * COLUMN_NUM + COLUMN_NUM)
                      .map((classNumber) => {
                        return (
                          <CheckBoxWrapper key={classNumber}>
                            <CheckBoxRHF
                              label={getClassName(classNumber, true)}
                              control={control}
                              value={classNumber}
                              name="classes"
                            />
                          </CheckBoxWrapper>
                        );
                      })}
                  </CheckBoxRow>
                );
              }
            )}
            <Margin marginTop={22} />
            <Switch
              label="親の重複を削除する"
              register={register("parentsAreUnique")}
              value={1}
            />
            <Margin marginTop={10} />
            <Switch
              label="管理者のみを表示"
              register={register("includeAdmin")}
              value={1}
            />
          </form>
        </FilterModal>
      </FilterModalWrap>
      <ModalBackGround onClick={onClose}></ModalBackGround>
    </>
  );
};
const CheckBoxRow = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const FilterModalWrap = styled.div`
  position: relative;
  z-index: ${zIndexes.notModalMenu};
`;

export const ModalBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const FilterModal = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  padding: 14px;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const Label = styled.div`
  width: fit-content;
  color: #1a1c21;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
`;

const CheckBoxWrapper = styled.div`
  width: 75px;
`;
