import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import {
  createOrUpdateGradeSizes,
  getGradeSizes,
} from "src/apiClients/gradeSize";

export const GRADE_SIZES_QUERY_KEY = [
  "api",
  "gradeSize",
  "getGradeSizes",
] as const;

export const useGetGradeSizes = (queryOptions: QueryOptions = {}) => {
  const query = useQuery({
    queryKey: GRADE_SIZES_QUERY_KEY,
    queryFn: async () => {
      return await getGradeSizes();
    },
    ...queryOptions,
  });

  return {
    gradeSizes: useMemo(() => query.data ?? undefined, [query.data]),
    ...query,
  };
};

export const useCreateOrUpdateGradeSizes = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createOrUpdateGradeSizes,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GRADE_SIZES_QUERY_KEY });
    },
  });
};
