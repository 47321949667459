import React from "react";
import { useApplyRecruitmentList } from "src/hooks/query/recruitments/recruitmentApplyList";
import { Title } from "../../../components/Title";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { NotFoundRecruitment } from "../../../features/Recruitment/recruitment/NotFoundRecruitment";
import { RecruitmentCard } from "../../../features/Recruitment/recruitment/RecruitmentCard";
import { RecruitmentApplicationsFilter } from "../../../features/Recruitment/recruitment/RecruitmentApplicationsFilter";
import { useRecruitmentApplyListFilter } from "../../../hooks/recoil/recruitmentApplyListFilter";
import { PromoteResidentInfoModal } from "../../../features/Profile/ResidentInfo/PromoteResidentInfoModal";

export default function ResidentRecruitmentApplyListPage() {
  const [recruitmentApplyListFilter, setRecruitmentApplyListFilter] =
    useRecruitmentApplyListFilter();
  const { recruitments } = useApplyRecruitmentList(recruitmentApplyListFilter);
  const history = usePolyfitHistory();

  return (
    <>
      <RecruitmentApplicationsFilter
        recruitmentFilter={recruitmentApplyListFilter}
        setRecruitmentFilter={setRecruitmentApplyListFilter}
      />
      <Title>応募した募集一覧</Title>

      {recruitments?.length === 0 ? (
        <NotFoundRecruitment
          title="応募した募集はありません"
          description="応募した募集が作成されるとこちらの画面に表示されます"
        />
      ) : (
        <CardContainer>
          {recruitments?.map((recruitment, i) => (
            <RecruitmentCard
              key={recruitment.id}
              recruitment={recruitment}
              primaryButtonLabel="詳細を確認する"
              onClickPrimaryButton={() =>
                history.push({
                  to: "RESIDENT_RECRUITMENT_DETAIL",
                  query: { id: recruitment.id },
                })
              }
            />
          ))}
        </CardContainer>
      )}
      <PromoteResidentInfoModal />
    </>
  );
}

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  margin: 2em auto;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
