import React, { useState } from "react";
import { ArrowDropDownIcon } from "src/components/icons/ArrowDropDown";
import styled from "styled-components";
import * as theme from "src/theme";
import DestinationGradeTab from "./DestinationGradeTab";
import DestinationBelongTab from "./DestinationBelongTab";
import DestinationUserGroupTab from "./DestinationUserGroupTab";
import {
  TargetClassesType,
  TargetGradeAndClass,
  TargetGradesType,
} from "@shared/types/post/api";
import { UseFormSetValue } from "react-hook-form";
import { RemoveIcon } from "src/components/icons/RemoveIcon";
import { getClassName } from "src/utils/getChildClass";
import { Button } from "src/components/Button";
import { useGetCurrentOrganization } from "../../../hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

type Tabs = "GRADE" | "BELONG" | "USER_GROUP";

export type FormValues = {
  title: string;
  text: string;
  targetAll: boolean;
  targetGrades: TargetGradesType[];
  targetGradeAndClasses: TargetGradeAndClass[];
  targetBelongIds: string[];
  targetPostUserGroupIds: string[];
  file?: File;
  status: "UNAPPROVED" | "DRAFT"; // "REMANDED" | "APPROVED" もあるが使わないためUNIONに含めない。
};

type Props = {
  targetGrades: { id: TargetGradesType; name: string }[];
  targetBelongs: { id: string; name: string; order: number | null }[];
  targetPostUserGroups: { id: string; name: string }[];
  selectedIsAll: boolean;
  selectedGradeAndClasses: TargetGradeAndClass[];
  selectedBelongIds: string[];
  selectedPostUserGroupIds: string[];
  setValue: UseFormSetValue<FormValues>;
  classOptions: { id: TargetClassesType; name: string }[];
};

function DestinationForm({
  targetGrades,
  targetBelongs,
  targetPostUserGroups,
  selectedIsAll,
  selectedGradeAndClasses,
  selectedBelongIds,
  selectedPostUserGroupIds,
  setValue,
  classOptions,
}: Props) {
  const { organization } = useGetCurrentOrganization();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tabs>("GRADE");

  const removeSelectedAllValue = () => {
    setValue("targetAll", false);
    setValue("targetBelongIds", []);
    setValue("targetGradeAndClasses", []);
    setValue("targetPostUserGroupIds", []);

    setSelectedTab("GRADE");
  };

  const removeSelectedValue = (value: TargetGradesType | string) => {
    if (typeof value === "number") {
      // 学年の場合
      const filteredValues = selectedGradeAndClasses.filter(
        (gradeAndClass) => gradeAndClass.grade !== value
      );
      setValue("targetGradeAndClasses", filteredValues);
    } else {
      // 所属の場合
      const filteredValues = selectedBelongIds.filter((sv) => sv !== value);
      setValue("targetBelongIds", filteredValues);

      // 連絡グループの場合
      const filteredPostUserGroupValues = selectedPostUserGroupIds.filter(
        (sv) => sv !== value
      );
      setValue("targetPostUserGroupIds", filteredPostUserGroupValues);
    }
  };

  const removeSelectedClassValue = (grade: number, classItem: number) => {
    //指定された学年のclassesから指定されたクラスを削除
    const newValues = selectedGradeAndClasses.map((value) => {
      if (value.grade === grade) {
        return {
          grade: value.grade,
          classes: value.classes.filter((c) => c !== classItem),
        };
      } else {
        return value;
      }
    });
    setValue("targetGradeAndClasses", newValues);
  };

  const getLabel = (type: Tabs, target: string) => {
    if (type === "BELONG") {
      return targetBelongs.find((belong) => belong.id === target)?.name;
    } else if (type === "USER_GROUP") {
      return targetPostUserGroups.find((userGroup) => userGroup.id === target)
        ?.name;
    }
  };

  const setTargetAll = (isAll: boolean) => {
    setValue("targetAll", isAll);
    if (isAll) {
      setValue("targetBelongIds", []);
      setValue("targetPostUserGroupIds", []);
    } else {
      setSelectedTab("GRADE");
    }
  };

  return (
    <Container>
      <DestinationButtonAndLabels onClick={() => setIsOpen(true)}>
        {!selectedIsAll &&
        selectedBelongIds.length === 0 &&
        selectedGradeAndClasses.length === 0 &&
        selectedPostUserGroupIds.length === 0 ? (
          <div>宛先を選択</div>
        ) : (
          <SelectedValuesList>
            {selectedIsAll && (
              <SelectedValueItem>
                <TargetName>全学年</TargetName>
                <RemoveButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedAllValue();
                  }}
                >
                  <RemoveIcon />
                </RemoveButton>
              </SelectedValueItem>
            )}
            {!selectedIsAll &&
              selectedGradeAndClasses.map((value, index) => {
                if (value.classes.length === classOptions.length) {
                  return (
                    <SelectedValueItem key={index}>
                      <TargetName>
                        {getGradeLabel(
                          value.grade,
                          organization?.schoolDisplayType,
                          true,
                          true,
                          "full"
                        )}
                      </TargetName>
                      <RemoveButton
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeSelectedValue(value.grade);
                        }}
                      >
                        <RemoveIcon />
                      </RemoveButton>
                    </SelectedValueItem>
                  );
                } else {
                  return value.classes.map((classItem, classIndex) => (
                    <SelectedValueItem key={`${index}-${classIndex}`}>
                      <TargetName>
                        {getGradeLabel(
                          value.grade,
                          organization?.schoolDisplayType,
                          true,
                          true,
                          "full"
                        )}
                        {getClassName(classItem, true, true)}
                      </TargetName>
                      <RemoveButton
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeSelectedClassValue(value.grade, classItem);
                        }}
                      >
                        <RemoveIcon />
                      </RemoveButton>
                    </SelectedValueItem>
                  ));
                }
              })}
            {!selectedIsAll &&
              selectedBelongIds.map((value, index) => {
                return (
                  <SelectedValueItem key={index}>
                    <TargetName>{getLabel("BELONG", value)}</TargetName>
                    <RemoveButton
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSelectedValue(value);
                      }}
                    >
                      <RemoveIcon />
                    </RemoveButton>
                  </SelectedValueItem>
                );
              })}
            {!selectedIsAll &&
              selectedPostUserGroupIds.map((value, index) => {
                return (
                  <SelectedValueItem key={index}>
                    <TargetName>{getLabel("USER_GROUP", value)}</TargetName>
                    <RemoveButton
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSelectedValue(value);
                      }}
                    >
                      <RemoveIcon />
                    </RemoveButton>
                  </SelectedValueItem>
                );
              })}
          </SelectedValuesList>
        )}
        <RotatingIcon isOpen={isOpen}>
          <ArrowDropDownIcon />
        </RotatingIcon>
      </DestinationButtonAndLabels>
      {isOpen && (
        <>
          <DestinationSelectTabs>
            <DestinationSelectTabHeader>
              <DestinationTabLists>
                <DestinationTabList
                  onClick={() => {
                    setSelectedTab("GRADE");
                  }}
                  $isActive={selectedTab == "GRADE"}
                  isAll={false}
                >
                  学年・クラス
                </DestinationTabList>
                <DestinationTabList
                  onClick={() => {
                    if (!selectedIsAll) setSelectedTab("BELONG");
                  }}
                  $isActive={selectedTab == "BELONG"}
                  isAll={selectedIsAll}
                >
                  所属
                </DestinationTabList>
                <DestinationTabList
                  onClick={() => {
                    if (!selectedIsAll) setSelectedTab("USER_GROUP");
                  }}
                  $isActive={selectedTab == "USER_GROUP"}
                  isAll={selectedIsAll}
                >
                  ユーザーグループ
                </DestinationTabList>
              </DestinationTabLists>
            </DestinationSelectTabHeader>
            <DestinationTabPanel>
              {selectedTab == "GRADE" && (
                <DestinationGradeTab
                  targetGrades={targetGrades}
                  targetClasses={classOptions}
                  setValue={setValue}
                  selectedIsAll={selectedIsAll}
                  selectedGradeAndClasses={selectedGradeAndClasses}
                  selectTargetAll={setTargetAll}
                  getClassName={(
                    classItem: TargetClassesType | null | undefined
                  ) => getClassName(classItem, true, true)}
                />
              )}
              {selectedTab == "BELONG" && (
                <DestinationBelongTab
                  targetBelongs={targetBelongs}
                  setValue={setValue}
                  selectedIsAll={selectedIsAll}
                  selectedBelongIds={selectedBelongIds}
                />
              )}
              {selectedTab == "USER_GROUP" && (
                <DestinationUserGroupTab
                  targetPostUserGroups={targetPostUserGroups}
                  setValue={setValue}
                  selectedIsAll={selectedIsAll}
                  selectedPostUserGroupIds={selectedPostUserGroupIds}
                />
              )}
            </DestinationTabPanel>
            <DestinationButtons>
              <Button
                type="button"
                color="primary"
                fill={true}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                宛先を選択
              </Button>
            </DestinationButtons>
          </DestinationSelectTabs>
          <BackGround
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </>
      )}
    </Container>
  );
}

export default DestinationForm;

const Container = styled.div`
  position: relative;
`;
const DestinationButtonAndLabels = styled.div`
  width: 100%;
  background-color: #fbfcfd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  padding: 8px;
  &:has(> ul > li) {
    padding: 6px 8px; //hasをうまく使えて嬉しい
  }
  font-size: 14px;
  position: relative;
  z-index: 5;
`;

const SelectedValuesList = styled.ul`
  list-style: none;
  display: flex;
  overflow-y: auto;
  row-gap: 8px;
`;

const SelectedValueItem = styled.li`
  border: 1px solid #0071c2;
  border-radius: 100px;
  background: #e6f1fa;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  font-size: 12px;
  gap: 8px;
`;

const TargetName = styled.span`
  font-family: "Noto Sans";
  font-style: normal;
  font-size: 12px;
  color: #0071c2;
  white-space: nowrap;
`;

const RemoveButton = styled.button`
  border: none;
  background: #e6f1fa;
  cursor: pointer;
  padding-top: 2px; //レイアウト調整用、苦肉padding
`;

const RotatingIcon = styled.div<{ isOpen: boolean }>`
  transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DestinationSelectTabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3dae6;
  padding: 0 8px;
  margin-top: 8px;
`;

const DestinationSelectTabs = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 6px;
  background: white;
  z-index: 4;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
`;

const DestinationTabLists = styled.ul`
  display: flex;
  gap: 16px;
  font-weight: bold;
  list-style: none;
`;

const DestinationTabList = styled.li<{ $isActive: boolean; isAll: boolean }>`
  cursor: ${(props) => (props.isAll ? "not-allowed" : "pointer")};
  border-bottom: 2px solid
    ${(props) => (props.$isActive ? theme.colorsPallet.primary : "transparent")};
  color: ${(props) =>
    //isAllの場合は灰色
    props.isAll
      ? "gray"
      : props.$isActive
      ? theme.colorsPallet.primary
      : theme.colorTokens.title};
  padding: 8px 4px;
  line-height: 16px;
  font-size: 14px;
`;

const DestinationTabPanel = styled.div`
  max-height: 280px;
  overflow-y: auto;
`;

const DestinationButtons = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const BackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
`;
