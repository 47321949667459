import { z } from "zod";
import {
  transformStringOrBooleanToBoolean,
  transformStringOrNumberToNumber,
} from "../rules/transform";
import { ApplicationStatus } from "../../types/recruitmentApplication";

export const recruitmentListQuerySchema = z.object({
  volunteerType: z.string().optional(),
  isPaidVolunteer: z.enum(["PAID", "FREE"]).optional(),
  date: z.string().optional(),
  isOpen: transformStringOrBooleanToBoolean().optional(),
  origin: z.enum(["CS", "BOARD_EDUCATION"]).optional(),
  page: transformStringOrNumberToNumber().optional(),
  take: transformStringOrNumberToNumber().optional(),
  currentCommunityId: z.string().optional(),
});

export type RecruitmentListQueryType = z.infer<
  typeof recruitmentListQuerySchema
>;

/**
 * 応募作成APIのリクエストボディのスキーマ
 */
export const recruitmentApplyBodySchema = z.object({
  recruitmentId: z.string(),
  comment: z
    .string()
    .max(3000, {
      message: "コメントは3000文字以内で入力してください",
    })
    .optional(),
  selectedScheduleIds: z.array(z.string()).min(1, {
    message: "少なくとも1つの日程を選択してください",
  }),
});

export type RecruitmentApplyBodyType = z.infer<
  typeof recruitmentApplyBodySchema
>;

/**
 * 応募した募集一覧取得APIのリクエストクエリーのスキーマ
 */
export const recruitmentApplyListQuerySchema = z.object({
  status: z.nativeEnum(ApplicationStatus).optional(),
  currentCommunityId: z.string().optional(),
});

export type RecruitmentApplyListQuerySchema = z.infer<
  typeof recruitmentApplyListQuerySchema
>;
