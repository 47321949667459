import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import React, { useState } from "react";
import { SchoolIcon } from "src/components/icons/Lucide/SchoolIcon";
import { UserIcon } from "src/components/icons/Lucide/UserIcon";
import { UsersIcon } from "src/components/icons/Lucide/UsersIcon";
import { buttons, colorsPallet, shadow } from "src/theme";
import styled from "styled-components";

type Props = {
  applicant: RecruitmentApplication;
};

export const ApplicationChildInfoPopup = ({ applicant }: Props) => {
  const [isChildInfoPopupOpen, setIsChildInfoPopupOpen] =
    useState<boolean>(false);
  const [selectedChildId, setSelectedChildId] = useState<string | null>(
    applicant.cSChildren?.[0]?.id ?? null
  );

  if (!applicant.cSChildren) return null;
  const childInfo = applicant.cSChildren;
  const selectedChild = childInfo.find((child) => child.id === selectedChildId);
  return (
    <Container>
      <PopupButton
        onClick={() => {
          setIsChildInfoPopupOpen(!isChildInfoPopupOpen);
        }}
      >
        <UsersIcon color={colorsPallet.primary} size={16} />
        子どもの情報({applicant.cSChildren.length})
      </PopupButton>
      {isChildInfoPopupOpen && (
        <div>
          <PopupContainer>
            <ChildList>
              {childInfo.map((child) => (
                <div key={child.id}>
                  <ChildName
                    $isSelected={selectedChildId === child.id}
                    onClick={() => {
                      setSelectedChildId(child.id);
                    }}
                  >
                    {child.childFirstName}
                  </ChildName>
                </div>
              ))}
            </ChildList>
            {selectedChildId && (
              <SelectedChildInfo>
                <Name>
                  {selectedChild?.childLastName}
                  {selectedChild?.childFirstName}
                </Name>
                {selectedChild?.community?.name && (
                  <SubText>
                    <SchoolIcon color={colorsPallet.primary} size={16} />
                    {selectedChild.community.name}
                  </SubText>
                )}
                {(selectedChild?.grade || selectedChild?.class) && (
                  <SubText>
                    <UserIcon color={colorsPallet.primary} size={16} />
                    {selectedChild?.grade && `${selectedChild.grade}年`}
                    {selectedChild?.class && `${selectedChild.class}組`}
                  </SubText>
                )}
              </SelectedChildInfo>
            )}
          </PopupContainer>
          <Overlay onClick={() => setIsChildInfoPopupOpen(false)} />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const PopupButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #fff;
  padding: 4px;
  outline: none;
  border: none;
  color: ${colorsPallet.primary};
  white-space: nowrap;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 99;
`;

const PopupContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 4;
  left: 0;
  border-radius: 4px;
  box-shadow: ${shadow.bottomSmall};
  min-width: 200px;
  max-width: 60vw;
  overflow: hidden;
`;

const ChildList = styled.div`
  display: flex;
  gap: 4px;
  background-color: ${colorsPallet.lightShade};
  padding: 4px;
  overflow-x: auto;
`;

const ChildName = styled.p<{ $isSelected: boolean }>`
  font-size: 14px;
  font-weight: ${(props) => (props.$isSelected ? "bold" : "normal")};
  background-color: ${(props) =>
    props.$isSelected ? "#fff" : colorsPallet.lightShade};
  color: ${(props) => (props.$isSelected ? "#000" : "gray")};
  padding: 4px 12px;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
  cursor: ${(props) => (props.$isSelected ? "default" : "pointer")};
  transition: 0.3s;
  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "#fff" : buttons.ghost.bg};
  }
`;

const SelectedChildInfo = styled.div`
  background-color: #fff;
  padding: 4px;
`;

const Name = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
`;

const SubText = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  width: fit-content;
  white-space: nowrap;
`;
