import React, { useCallback, useState } from "react";
import {
  useApplyRecruitmentList,
  useDeclineApplicantEvents,
} from "src/hooks/query/recruitments/recruitmentApplyList";
import { Title } from "../../../components/Title";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import SelfDeclineApplicationModal from "../../../features/Recruitment/application/SelfDeclineApplicationModal";
import { NotFoundRecruitment } from "../../../features/Recruitment/recruitment/NotFoundRecruitment";
import { RecruitmentCard } from "../../../features/Recruitment/recruitment/RecruitmentCard";
import { Recruitment } from "@shared/types/recruitment";
import {
  getRecruitmentStatus,
  isScheduleEnded,
} from "src/features/Recruitment/application/utils/recruitmentScheduleStatus";
import { useToast } from "src/components/Toast";
import { DeclineByRecruitmentApplicationEventBySelfSchema } from "@shared/validator/features/recruitmentApplicationEvent.schema";
import { logger } from "src/utils/logger";

export default function ResidentRecruitmentApproveListPage() {
  const { recruitments } = useApplyRecruitmentList("APPROVED");
  const history = usePolyfitHistory();
  const toast = useToast();
  const [selectedRecruitment, setSelectedRecruitment] = useState<
    Recruitment | undefined
  >();
  const { mutateAsync: declineApplicationBySelf } = useDeclineApplicantEvents();

  const handleOpenModal = useCallback((recruitment: Recruitment) => {
    setSelectedRecruitment(recruitment);
  }, []);

  const handleDeclineSubmit = useCallback(
    async (values: DeclineByRecruitmentApplicationEventBySelfSchema) => {
      try {
        await declineApplicationBySelf(values);
        toast.success("不参加への変更に成功しました");
        setSelectedRecruitment(undefined);
      } catch (error) {
        toast.error("不参加への変更に失敗しました");
        logger.error(error, {
          selectedRecruitment,
        });
      }
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setSelectedRecruitment(undefined);
  }, []);

  // 参加予定の募集（終了していないスケジュールを含む募集）
  const upcomingRecruitments = recruitments
    ?.map((recruitment) => ({
      ...recruitment,
      schedule: recruitment.schedule.filter(
        (schedule) => !isScheduleEnded(schedule)
      ),
    }))
    .filter((recruitment) => recruitment.schedule.length > 0);

  // 参加済みの募集（終了したスケジュールを含む募集）
  const pastRecruitments = recruitments
    ?.map((recruitment) => ({
      ...recruitment,
      schedule: recruitment.schedule.filter((schedule) =>
        isScheduleEnded(schedule)
      ),
    }))
    .filter((recruitment) => recruitment.schedule.length > 0);

  return (
    <>
      <NotEndedRecruitments>
        <Title>参加する募集一覧</Title>

        {/* TODO: カード部分を共通化 */}
        {upcomingRecruitments?.length === 0 ? (
          <NotFoundRecruitment
            title="参加する募集はありません"
            description="参加する募集が作成されるとこちらの画面に表示されます"
          />
        ) : (
          <CardContainer>
            {upcomingRecruitments?.map((recruitment, i) => (
              <RecruitmentCard
                key={recruitment.id}
                recruitment={recruitment}
                primaryButtonLabel="詳細を確認する"
                onClickPrimaryButton={() =>
                  history.push({
                    to: "RESIDENT_RECRUITMENT_DETAIL",
                    query: { id: recruitment.id },
                  })
                }
                cancelButtonLabel="不参加にする"
                cancelButtonIsDisabled={
                  getRecruitmentStatus(recruitment) !== "Future"
                }
                onClickCancelButton={() => handleOpenModal(recruitment)}
              />
            ))}
          </CardContainer>
        )}
      </NotEndedRecruitments>
      <EndedRecruitments>
        <Title>参加した募集一覧</Title>
        {pastRecruitments?.length === 0 ? (
          <NotFoundRecruitment
            title="参加した募集はありません"
            description="参加した募集が作成されるとこちらの画面に表示されます"
          />
        ) : (
          <CardContainer>
            {pastRecruitments?.map((recruitment, i) => (
              <RecruitmentCard
                key={recruitment.id}
                recruitment={recruitment}
                primaryButtonLabel="詳細を確認する"
                onClickPrimaryButton={() =>
                  history.push({
                    to: "RESIDENT_RECRUITMENT_DETAIL",
                    query: { id: recruitment.id },
                  })
                }
              />
            ))}
          </CardContainer>
        )}
      </EndedRecruitments>
      {selectedRecruitment && (
        <SelfDeclineApplicationModal
          recruitment={selectedRecruitment}
          onSubmit={handleDeclineSubmit}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  margin: 2em auto;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const NotEndedRecruitments = styled.section``;
const EndedRecruitments = styled.section`
  margin-top: 16px;
`;
