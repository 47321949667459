import { EmptyObject } from "react-hook-form";
import * as client from "./client";
import { DayOfWeekType } from "../utils/types/dayOfWeek";
import { PaidType, VolunteerType } from "../@shared/types/volunteerType";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
} from "../utils/types/license";
import { ChildClass } from "./child";
import { UserType } from "../utils/types/userType";
import { ServiceType } from "../utils/types/serviceType";
import { Role } from "../utils/types/role";
import {
  FirebaseStateResponse,
  GetAccountsResponse,
  ResendEmailVerificationMailResponse,
  SignupWithInvitationResponse,
} from "@shared/types/auth";
import { CommunityUserType } from "@shared/types/userType";
import {
  residentInfoSchema,
  ResidentInfoSchemaType,
} from "@shared/validator/models/residentInfo.schema";
import {
  firebaseStateSchema,
  FirebaseStateSchemaType,
  GetUserByIdSchemaForClientType,
  getUserByIdSchemaForClient,
  resendEmailVerificationMailSchema,
  ResidentInfoBodyForBESchemaType,
  ResidentInfoBodyForCSSchemaType,
  ResidentInfoBodySchemaType,
  UpdateAccountCommunityRolesRequestSchemaType,
  changeEmailByInternalAdminSchema,
  ChangeEmailByInternalAdminSchemaType,
  IsExistsOrganizationUserSchemaType,
  isExistsOrganizationUserSchema,
  registerUserTypeSchema,
  RegisterUserTypeSchemaType,
  RegisterInfoSchemaType,
  registerFaceImgPathSchema,
  RegisterFaceImgPathSchemaType,
  registerFaceImgPathForBESchema,
  RegisterFaceImgPathForBESchemaType,
  UpdateTeacherInfoSchemaType,
  updateTeacherInfoSchema,
  updateTeacherInfoByAdminSchema,
  UpdateTeacherInfoByAdminSchemaType,
  residentInfoBodyForCSSchema,
  residentInfoBodyForBESchema,
  DeleteAccountSchemaType,
  deleteAccountSchema,
  DeleteUnApprovedAccountSchemaType,
  deleteUnApprovedAccountSchema,
  registerResidentBaseInfoSchema,
  RegisterResidentBaseInfoSchemaType,
  registerResidentContactInfoSchema,
  RegisterResidentContactInfoSchemaType,
  approveUsersSchema,
  ApproveUsersSchemaType,
  updateBelongAndPartSchema,
  UpdateBelongAndPartSchemaType,
  SetCurrentAccountSchemaType,
  setCurrentAccountSchema,
  updateAccountCommunityRolesRequestSchema,
  updateCommunityRolesByTokenRequestSchema,
  UpdateCommunityRolesByTokenRequestSchemaType,
  updateRoleSchema,
  UpdateRoleSchemaType,
  updateAccountByInternalAdminSchema,
  UpdateAccountByInternalAdminSchemaType,
  CanDeleteCommunityRoleSchemaType,
  canDeleteCommunityRoleSchema,
  checkUserInThisCommunitySchema,
  CheckUserInThisCommunitySchemaType,
} from "@shared/validator/features/auth.schema";
import { InternalUserRole } from "@shared/types/internalUserRole";
import {
  ResendEmailVerificationMailSchemaType,
  signupWithInvitationSchema,
  SignupWithInvitationSchemaType,
} from "@shared/validator/features/auth.schema";
import {
  baseInfoSchema,
  BaseInfo as BaseInfoType,
} from "@shared/validator/models/baseInfo.schema";

export type CurrentUser = {
  id: string;
  email: string;
  name: string;
  internalRole: InternalUserRole;
  picture: string;
  role: Role;
  isApprover: boolean;
  firebaseUid: string;
  able: boolean;
  baseInfo?: BaseInfo;
  signUpState: boolean;
  type: UserType;
  account?: Account;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};

export type Account = {
  id: string;
  organizationId: string;
  userId: string;
  roleId: string;
  belongId: string | null;
  partId: string | null;
  able: boolean;
  signUpState: boolean;
  isApprover: boolean;
  type: UserType;
  serviceType: ServiceType;
  schoolGroup: string | null;
  isPreschooler: boolean;
  selfFreeMemo: string;
  adminFreeMemo: string;
  communityRoles: AccountCommunityRole[];
  createdAt: Date;
  updatedAt: Date;
};

export type AccountCommunityRole = {
  accountId: string;
  roleId: string;
  role: {
    id: string;
    name: Role;
  };
  communityId: string;
  isMain: boolean;
  type?: CommunityUserType;
};

export type BaseInfo = BaseInfoType;

export type ResidentBaseInfo = {
  userId: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  gender: "MALE" | "FEMALE" | "OTHER";
  birthday: Date;
  ageGroup: number;
  postalCode: string;
  prefecture: string;
  city: string;
  address1: string;
  address2: string;
  phoneNumber: string;
};

export type ResidentInfo = {
  preferredDays: DayOfWeekType[];
  teacherLicenses: TeachingLicenseType[];
  medicalLicenses: MedicalLicenseType[];
  skills: SkillType[];
  preferredPaid: PaidType[];
  preferredVolunteerType: VolunteerType[];
  privateEnterprise: string | null;
  university: string | null;
  externalOrganization: string | null;
  pta: string | null;
};

export async function signupWithInvitation({
  token,
  isGuest,
}: SignupWithInvitationSchemaType): Promise<SignupWithInvitationResponse> {
  signupWithInvitationSchema.parse({ token, isGuest });
  const res = await client.post<
    SignupWithInvitationSchemaType,
    SignupWithInvitationResponse
  >("/auth/signupWithInvitation", {
    token,
    isGuest,
  });
  return res;
}

export async function resendEmailVerificationMail({
  token,
}: ResendEmailVerificationMailSchemaType): Promise<ResendEmailVerificationMailResponse> {
  resendEmailVerificationMailSchema.parse({ token });
  const res = await client.post<
    ResendEmailVerificationMailSchemaType,
    ResendEmailVerificationMailResponse
  >("/auth/resendEmailVerificationMail", {
    token,
  });
  return res;
}

export async function currentUser(): Promise<CurrentUser> {
  const res = await client.get<{}, { user: CurrentUser }>(
    "/auth/currentUser",
    {}
  );
  return res.user;
}

export async function firebaseState(
  userId: string
): Promise<FirebaseStateResponse> {
  firebaseStateSchema.parse({ userId });
  const res = await client.get<FirebaseStateSchemaType, FirebaseStateResponse>(
    "/auth/firebaseState",
    { userId }
  );
  return res;
}

export async function getUserById(userId: string): Promise<CurrentUser> {
  getUserByIdSchemaForClient.parse({ userId });
  const res = await client.get<
    GetUserByIdSchemaForClientType,
    { user: CurrentUser }
  >("/auth/getUser", { userId });
  return res.user;
}

export async function syncEmail(): Promise<{ success: boolean }> {
  const res = await client.post<{}, { success: boolean }>(
    "/auth/syncEmail",
    {}
  );
  return res;
}

export async function changeEmailByInternalAdmin(
  userId: string,
  email: string
): Promise<{ status: string }> {
  changeEmailByInternalAdminSchema.parse({ userId, email });
  const res = await client.post<
    ChangeEmailByInternalAdminSchemaType,
    { status: string }
  >("/auth/changeEmailByInternalAdmin", {
    userId,
    email,
  });
  console.log(res);
  return res;
}

export async function isExistsOrganizationUser(
  token: string
): Promise<boolean> {
  isExistsOrganizationUserSchema.parse({ token });
  const res = await client.get<IsExistsOrganizationUserSchemaType>(
    "/auth/isExistsOrganizationUser",
    { token }
  );

  return res.result;
}

export async function registerUserType(userType: UserType): Promise<{}> {
  registerUserTypeSchema.parse({ userType });
  const res = await client.post<RegisterUserTypeSchemaType>(
    "/auth/registerUserType",
    { userType }
  );
  return res;
}

export async function registerInfo(data: BaseInfo) {
  baseInfoSchema.parse(data);
  const baseInfo = await client.post<RegisterInfoSchemaType, BaseInfo>(
    "/auth/registerInfo",
    {
      data,
    }
  );
  return baseInfo;
}
/**
 * CSの登録時の「基本情報の入力」ページ用のAPI
 **/
export async function registerResidentBaseInfo(
  data: RegisterResidentBaseInfoSchemaType["data"]
) {
  registerResidentBaseInfoSchema.parse({ data });
  const res = await client.post<
    RegisterResidentBaseInfoSchemaType,
    Partial<BaseInfo>
  >("/auth/registerResidentBaseInfo", { data });
  return res;
}

/**
 * CSの登録時の「連絡先の入力」ページ用のAPI
 **/
export async function registerResidentContactInfo(
  data: RegisterResidentContactInfoSchemaType["data"]
) {
  registerResidentContactInfoSchema.parse({ data });
  const res = await client.post<
    RegisterResidentContactInfoSchemaType,
    Partial<BaseInfo>
  >("/auth/registerResidentContactInfo", { data });
  return res;
}

export async function registerFaceImgPath(data: { faceImgPath: string }) {
  registerFaceImgPathSchema.parse({ data });
  const baseInfo = await client.post<RegisterFaceImgPathSchemaType, BaseInfo>(
    "/auth/registerFaceImgPath",
    { data }
  );
  return baseInfo;
}

export async function registerFaceImgPathForBE(data: {
  accountId: string;
  faceImgPath: string;
}) {
  registerFaceImgPathForBESchema.parse(data);
  const baseInfo = await client.post<
    RegisterFaceImgPathForBESchemaType,
    BaseInfo
  >("/auth/registerFaceImgPathForBE", data);
  return baseInfo;
}

export async function getBaseInfo(): Promise<BaseInfo> {
  const res = await client.get<{}, BaseInfo>("/auth/getBaseInfo", {});
  return res;
}

export async function getResidentBaseInfo(): Promise<ResidentBaseInfo> {
  const res = await client.get<{}, ResidentBaseInfo>(
    "/auth/getResidentBaseInfo",
    {}
  );
  return res;
}

export async function getBaseInfoById(userId: string): Promise<BaseInfo> {
  const res = await client.get<{ userId: string }, BaseInfo>(
    "/auth/getBaseInfo",
    {
      userId,
    }
  );
  return res;
}

/**
 * 名簿用の退会処理
 */
export async function deleteAccountById(
  targetAccountId: DeleteAccountSchemaType["data"]["targetAccountId"]
): Promise<EmptyObject> {
  const data = { targetAccountId };
  deleteAccountSchema.parse({ data });
  const res = await client.post<DeleteAccountSchemaType>(
    "/auth/deleteAccount",
    { data }
  );
  return res;
}

/**
 * PTAの未承認のメンバー用の退会処理
 */
export async function deleteUnApprovedAccount(
  targetAccountId: DeleteUnApprovedAccountSchemaType["data"]["targetAccountId"]
): Promise<EmptyObject> {
  const data = { targetAccountId };
  deleteUnApprovedAccountSchema.parse({ data });
  const res = await client.post<DeleteUnApprovedAccountSchemaType>(
    "/auth/deleteUnApprovedAccount",
    { data }
  );
  return res;
}

export async function approveUsers(data: ApproveUsersSchemaType["data"]) {
  approveUsersSchema.parse({ data });
  const res = await client.post<ApproveUsersSchemaType>("/auth/approveUsers", {
    data,
  });
  return res;
}

export async function completePTARegistration() {
  const res = await client.post("/auth/completePTARegistration", {});
  return res;
}

export async function completeResidentRegistration() {
  const res = await client.post("/auth/completeResidentRegistration", {});
  return res;
}

export async function updateBelongAndPart(
  data: UpdateBelongAndPartSchemaType["data"]
) {
  updateBelongAndPartSchema.parse({ data });
  const res = await client.post<UpdateBelongAndPartSchemaType, BaseInfo>(
    "/auth/updateBelongAndPart",
    { data }
  );
  return res;
}

export async function getTeacherInfo(): Promise<{
  data: { grade: number; class: ChildClass } | undefined | null;
}> {
  const res = await client.get<
    {},
    { data: { grade: number; class: ChildClass } | undefined | null }
  >("/auth/getTeacherInfo", {});
  return res;
}

export async function updateTeacherInfo(
  data: UpdateTeacherInfoSchemaType["data"]
): Promise<{}> {
  updateTeacherInfoSchema.parse({ data });
  const res = await client.post<UpdateTeacherInfoSchemaType>(
    "/auth/updateTeacherInfo",
    { data }
  );
  return res;
}

export async function getResidentInfo(): Promise<{
  data: ResidentInfoSchemaType | undefined | null;
}> {
  const res = await client.get<
    {},
    {
      data: ResidentInfoSchemaType | undefined | null;
    }
  >("/auth/getResidentInfo", {});
  return res;
}

export async function updateResidentInfo(
  data: ResidentInfoSchemaType
): Promise<{}> {
  residentInfoSchema.parse(data);
  const res = await client.post<ResidentInfoBodySchemaType>(
    "/auth/updateResidentInfo",
    { data }
  );
  return res;
}

export async function updateTeacherInfoByAdmin(
  userId: UpdateTeacherInfoByAdminSchemaType["userId"],
  data: UpdateTeacherInfoByAdminSchemaType["data"]
): Promise<{}> {
  updateTeacherInfoByAdminSchema.parse({ userId, data });
  const res = await client.post<UpdateTeacherInfoByAdminSchemaType>(
    "/auth/updateTeacherInfoByAdmin",
    { userId, data }
  );
  return res;
}

export async function updateResidentInfoByCSAdmin(
  userId: string,
  data: ResidentInfoSchemaType
): Promise<{}> {
  residentInfoBodyForCSSchema.parse({ userId, data });
  const res = await client.post<ResidentInfoBodyForCSSchemaType>(
    "/auth/updateResidentInfoByCSAdmin",
    { userId, data }
  );
  return res;
}

/**
 * TODO: こちらのAPIはaccountIdを用いているが、updateResidentInfoByCSAdminはuserIdを用いている
 * accountIdに統一する
 */
export async function updateResidentInfoByBEAdmin(
  accountId: string,
  data: ResidentInfoSchemaType
): Promise<{}> {
  residentInfoBodyForBESchema.parse({ accountId, data });
  const res = await client.post<ResidentInfoBodyForBESchemaType>(
    "/auth/updateResidentInfoByBEAdmin",
    { accountId, data }
  );
  return res;
}

/**
 * アカウント一覧を取得する
 */
export const getAccounts = async (): Promise<GetAccountsResponse[]> => {
  const res = await client.get<{}, GetAccountsResponse[]>(
    "/auth/getAccounts",
    {}
  );
  return res;
};

/**
 * 現在のアカウントを設定する
 */
export const setCurrentAccount = async (
  userId: SetCurrentAccountSchemaType["userId"],
  organizationId: SetCurrentAccountSchemaType["organizationId"]
) => {
  setCurrentAccountSchema.parse({ userId, organizationId });
  const res = await client.post<SetCurrentAccountSchemaType>(
    "/auth/setCurrentAccount",
    { userId, organizationId }
  );

  await client.refreshToken();

  return res;
};

/**
 * 紐づくcommunitiesを更新する
 */
export const updateCommunityRoles = async (
  data: UpdateAccountCommunityRolesRequestSchemaType
) => {
  updateAccountCommunityRolesRequestSchema.parse(data);
  const res = await client.post<UpdateAccountCommunityRolesRequestSchemaType>(
    "/auth/updateCommunityRoles",
    data
  );
  return res;
};

/**
 * tokenに紐づくcommunityをアカウントのcommunityRolesに追加する
 */
export const updateCommunityRolesByToken = async ({
  token,
  roleName,
}: UpdateCommunityRolesByTokenRequestSchemaType) => {
  updateCommunityRolesByTokenRequestSchema.parse({ token, roleName });
  const res = await client.post<UpdateCommunityRolesByTokenRequestSchemaType>(
    "/auth/updateCommunityRolesByToken",
    { token, roleName }
  );
  return res;
};

/**
 * 現在のRoleを更新する
 */
export const updateRole = async ({ userId, role }: UpdateRoleSchemaType) => {
  updateRoleSchema.parse({ userId, role });
  const res = await client.post<UpdateRoleSchemaType>("/auth/updateRole", {
    userId,
    role,
  });
  return res;
};

/**
 * 強制的にAccountを更新する
 * InternalAdminのみ
 */
export const updateAccountByInternalAdmin = async (
  data: UpdateAccountByInternalAdminSchemaType["data"],
  organizationId: UpdateAccountByInternalAdminSchemaType["organizationId"]
) => {
  updateAccountByInternalAdminSchema.parse({ data, organizationId });
  const res = await client.post<UpdateAccountByInternalAdminSchemaType>(
    "/auth/updateAccountByInternalAdmin",
    { data, organizationId }
  );
  return res;
};

/**
 * 校区の選択を変更できるかどうかを確認する
 */
export async function canDeleteCommunityRole({
  communityId,
  userId,
}: CanDeleteCommunityRoleSchemaType) {
  canDeleteCommunityRoleSchema.parse({ communityId, userId });
  const res = await client.get<CanDeleteCommunityRoleSchemaType, boolean>(
    "/auth/canDeleteCommunityRole",
    { communityId, userId }
  );
  return res;
}

export async function checkUserInThisCommunity(
  userId: CheckUserInThisCommunitySchemaType["userId"]
) {
  checkUserInThisCommunitySchema.parse({ userId });
  const res = await client.get<CheckUserInThisCommunitySchemaType, boolean>(
    "/auth/checkUserInThisCommunity",
    { userId }
  );
  return res;
}
