import { GradeSize } from "@shared/validator/features/gradeSize.schema";
import { SchoolDisplayType } from "@shared/types/organization";
import React, { useState } from "react";
import { TargetGradesType } from "@shared/types/post/api";
import styled from "styled-components";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import Chart from "./Chart";

function SelectChart({
  childrenCountsByGrade,
  gradeSizes,
  grades,
  schoolDisplayType,
}: {
  childrenCountsByGrade: { grade: number; _count: number }[];
  gradeSizes: GradeSize[] | undefined;
  grades: { id: TargetGradesType; name: string }[];
  schoolDisplayType: SchoolDisplayType;
}) {
  const [selectedGrade, setSelectedGrade] = useState<TargetGradesType>(1);
  return (
    <SelectChartSection>
      <InputSelect
        value={selectedGrade}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setSelectedGrade(Number(e.target.value) as TargetGradesType)
        }
      >
        {grades.map((grade) => (
          <option key={grade.id} value={grade.id}>
            {getGradeLabel(grade.id, schoolDisplayType, true, true, "full")}
          </option>
        ))}
      </InputSelect>
      {selectedGrade !== undefined &&
      grades.find((g) => g.id === selectedGrade) ? (
        <Chart
          childrenCounts={
            childrenCountsByGrade.find((c) => c.grade === selectedGrade)?._count
          }
          grade={grades.find((g) => g.id == selectedGrade)!}
          gradeSize={gradeSizes?.find((g) => g.grade === selectedGrade)?.size}
          schoolDisplayType={schoolDisplayType}
        />
      ) : (
        <></>
      )}
    </SelectChartSection>
  );
}

export default SelectChart;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
  margin-bottom: 8px;
`;

const SelectChartSection = styled.div`
  background: #fff;
  padding: 8px;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
`;
