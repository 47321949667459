import React, { useState } from "react";
import styled from "styled-components";
import { Scanner } from "@yudiel/react-qr-scanner";
import { Title } from "src/components/Title";
import type { IDetectedBarcode } from "@yudiel/react-qr-scanner";

type Props = {
  header?: React.ReactNode;
  onScanResult: (result: string) => void;
};

export const QRScanner: React.FC<Props> = ({ header, onScanResult }) => {
  const [scanError, setScanError] = useState<string | null>(null);

  const handleScan = (results: IDetectedBarcode[]) => {
    if (results && results.length > 0) {
      const result = results[0];
      onScanResult(result.rawValue);
    }
  };

  const handleError = (error: unknown) => {
    setScanError(
      "カメラの起動に失敗しました。カメラへのアクセスを許可してください。"
    );
    console.error("スキャナーエラー:", error);
  };

  return (
    <Container>
      <PageHeader>
        <Title>QRコードスキャン</Title>
      </PageHeader>
      {header}
      {scanError ? (
        <ErrorContainer>
          <p>{scanError}</p>
        </ErrorContainer>
      ) : (
        <ScannerContainer>
          <Scanner
            onScan={handleScan}
            onError={handleError}
            constraints={{
              facingMode: "environment",
            }}
            components={{
              finder: true,
              audio: true,
            }}
            styles={{
              container: {
                width: "100%",
                height: "100%",
                position: "relative",
              },
              video: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
              finderBorder: 3,
            }}
          />
        </ScannerContainer>
      )}
      <Instructions>
        画面のフレーム内にバーコードまたはQRコードを合わせてください
      </Instructions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 20px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;

const Instructions = styled.p`
  margin-bottom: 24px;
  text-align: center;
  color: #666;
`;

const ScannerContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
`;

const ErrorContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  text-align: center;

  p {
    color: #d32f2f;
    margin: 0;
  }
`;
