import React, { JSX, memo } from "react";
import { QuestionContainer, QuestionField } from "./Question";
import { Label } from "../../../components/form/Label";
import styled from "styled-components";
import { CloseButton } from "./closeButton";
import { Controller, useFieldArray, Control } from "react-hook-form";
import { SurveyQuestions } from "@shared/validator/features/survey.schema";
import {
  MINIMUM_NUMBER_OF_CHOICES,
  MAXIMUM_NUMBER_OF_CHOICES,
} from "../../../apiClients/survey";
import { ChoiceOption } from "./ChoiceOption";
import { CheckBox } from "../../../components/form/CheckBox";
import { ToggleQuestionTypeField } from "./ToggleQuestionTypeField";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "src/components/Toast";

type Props = {
  index: number;
  control: Control<SurveyQuestions>;
  onDelete: (index: number) => void;
  isDeletable: boolean;
};

export const MultiChoiceQuestionForm = memo(
  ({ index, control, onDelete, isDeletable }: Props): JSX.Element => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: `questionList.${index}.choiceList`,
    });

    const isDeletableChoice = fields.length > MINIMUM_NUMBER_OF_CHOICES;
    const toast = useToast();

    return (
      <QuestionContainer>
        {isDeletable && <CloseButton onClick={() => onDelete(index)} />}
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問の種類
          </Label>
          <ToggleQuestionTypeField index={index} control={control} />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            質問
          </Label>
          <Controller
            name={`questionList.${index}.text`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField
                  placeholder="例）次期PTA会長に推薦したい方を選択してください"
                  {...field}
                />
                {error && <ErrorText>{error.message}</ErrorText>}
              </>
            )}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            補足説明
          </Label>
          <Controller
            name={`questionList.${index}.description`}
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="例）他に推薦したい方がいればお知らせください"
                {...field}
              />
            )}
          />
        </QuestionField>
        <QuestionField>
          <Label size="s" marginBottom={4}>
            選択肢(10個まで作成可能)
          </Label>
          <ChoiceOptionContainer>
            {fields.map((field, choiceIndex) => (
              <ChoiceOption
                key={field.id}
                control={control}
                questionIndex={index}
                choiceIndex={choiceIndex}
                onDelete={() => remove(choiceIndex)}
                isDeletable={isDeletableChoice}
              />
            ))}
          </ChoiceOptionContainer>
        </QuestionField>
        {fields.length < MAXIMUM_NUMBER_OF_CHOICES && (
          <FlexEnd>
            <TextButton
              type="button"
              onClick={() => {
                if (fields.length == 9) {
                  toast.info("選択肢数の上限に達しました。");
                }
                append({ id: uuidv4(), text: "" });
              }}
            >
              + 選択肢を追加
            </TextButton>
          </FlexEnd>
        )}
        <QuestionField>
          <Controller
            name={`questionList.${index}.weightsNumberOfChildren`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckBox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                label="複数の子供がいる場合、子供ごとに票を数える"
              />
            )}
          />
        </QuestionField>
        <QuestionField>
          <Controller
            name={`questionList.${index}.allowMultipleAnswers`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckBox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                label="複数の選択肢を選べるようにする"
              />
            )}
          />
        </QuestionField>
      </QuestionContainer>
    );
  }
);

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  ::placeholder {
    color: #aab4c4;
    font-size: 14px;
  }
  &:disabled {
    opacity: 0.6;
  }
  -webkit-appearance: none;
`;

const ChoiceOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TextButton = styled.button`
  color: #005ec4;
  font-size: 14px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ErrorText = styled.p`
  color: #bd271e;
  font-size: 14px;
`;
