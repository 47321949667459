import { AppliedRecruitment } from "@shared/types/appliedRecruitment";

/**
 * 当日欠席率を計算する関数
 * 当日欠席率 = (欠席イベント数 / 母数イベント数) × 100%
 *   母数イベント数: selectionStatusがAPPROVED(参加決定)かつattendanceStatusがATTENDED or ABSENT)のイベントをカウント
 */
export const calculateAbsentRate = (
  recruitmentList: AppliedRecruitment[] | null
): number | null => {
  if (!recruitmentList || recruitmentList.length === 0) {
    return null;
  }

  const totalEligibleEvents = recruitmentList.flatMap((recruitment) =>
    recruitment.eventStatuses.filter(
      (event) =>
        event.selectionStatus === "APPROVED" &&
        (event.attendanceStatus === "ATTENDED" ||
          event.attendanceStatus === "ABSENT")
    )
  );

  if (totalEligibleEvents.length === 0) {
    return null;
  }

  const absentCount = totalEligibleEvents.filter(
    (event) => event.attendanceStatus === "ABSENT"
  ).length;

  const rate = (absentCount / totalEligibleEvents.length) * 100;
  return Math.round(rate * 10) / 10;
};
