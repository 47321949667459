import { TargetGradesType } from "@shared/types/post/api";

export const getGradeColor = (grade: TargetGradesType) => {
  const gradeColors = {
    1: "#FEC514",
    2: "#00BFB3",
    3: "#0077CC",
    4: "#BD271E",
    5: "#98A2B3",
    6: "#343741",
    7: "#FF6B6B",
    8: "#4ECDC4",
    9: "#45B7D1",
    10: "#F7B731",
    11: "#5D5D5D",
    12: "#8E44AD",
  };
  return gradeColors[grade] || "#1a84d1"; //targetBadgeのデフォルト色に則る
};
