import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import { MobileViewOnly, PcViewOnly } from "src/components/Responsive";
import { Spinner } from "src/components/icons/Spinner";
import { NoResults } from "src/features/ListPage/NoResults";
import { Role } from "src/@shared/types/role";
import { usePolyfitHistory } from "src/hooks/router";
import { Avatar } from "src/components/Common/Avatar";
import { roleOptions } from "src/utils/types/role";
import {
  getCommunityUserTypeText,
  getLicensesAndSkills,
  getPreferredVolunteerTypes,
} from "src/features/Member/Community/utils/utils";
import { getAgeGroupText } from "src/utils/types/ageGroup";
import { dayOfWeekOptions, getDayOfWeekText } from "src/utils/types/dayOfWeek";
import { ArrowRightIcon } from "src/components/icons/ArrowRightIcon";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { useBEResidentUsers } from "src/hooks/query/boardEducation/users/useBEResidentUsers";
import { BEResidentUser } from "src/@shared/types/boardEducation/residentUsers";
import { withinWalkMinuteLabel } from "src/features/ListPage/utils";
import { BEResidentFilterForm } from "src/features/ListPage/ResidentList/BEResidentFilterForm";
import { useBEResidentFilter } from "../../../hooks/recoil/boardEducation/residentFilter";
import { ResidentFilterType } from "@shared/types/user";
import { useGetManagedOrganization } from "src/hooks/query/boardEducation/organizations/organization";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export const BEMemberList = () => {
  const [residentFilter, setResidentFilter] = useBEResidentFilter();
  const [searchCondition, setSearchCondition] = useState({
    filter: residentFilter,
  });

  const handleFilterFormSubmit = useCallback((data: ResidentFilterType) => {
    setResidentFilter(data);
    setSearchCondition({ filter: data });
  }, []);

  const { isLoading, residents } = useBEResidentUsers(searchCondition.filter);
  const { isLoading: isLoadingOrganization, organization } =
    useGetManagedOrganization();

  return (
    <NameListPage>
      <NameListHeadingRow>
        <NameListHeading>名簿</NameListHeading>
      </NameListHeadingRow>

      {residentFilter && (
        <BEResidentFilterForm
          defaultValues={residentFilter}
          onSubmit={handleFilterFormSubmit}
          loading={isLoading || isLoadingOrganization}
          communities={organization?.communities ?? []}
        />
      )}

      <Margin marginBottom={10} />

      <PcViewOnly>
        <NameListTable>
          <NameListColumnNameRow head={true}>
            <NameListColumn>名前</NameListColumn>
            <NameListColumnWrapper>校区</NameListColumnWrapper>
            <NameListColumnWrapper>ユーザータイプ</NameListColumnWrapper>
            <NameListColumnWrapper>年代</NameListColumnWrapper>
            <NameListColumnWrapper>住所</NameListColumnWrapper>
            <NameListColumnWrapper>学校からの徒歩</NameListColumnWrapper>
            <NameListColumnWrapper>有償・無償</NameListColumnWrapper>
            <NameListColumnWrapper>希望活動日</NameListColumnWrapper>
            <NameListColumnWrapper>スキル・資格</NameListColumnWrapper>
            <NameListColumnWrapper>希望活動内容</NameListColumnWrapper>
          </NameListColumnNameRow>
          {/* TODO: テーブル化 */}
          {residents?.length
            ? residents?.map((resident, i) => {
                return <ResidentListColumn key={i} resident={resident} />;
              })
            : null}
        </NameListTable>
        {isLoading && residents.length === 0 && (
          <LoadingCard>
            <Spinner />
          </LoadingCard>
        )}
        {!isLoading && residents && !residents?.length && <NoResults />}
      </PcViewOnly>
      <MobileViewOnly>
        {residents?.length
          ? residents?.map((resident, i) => {
              return <ResidentListCardColumn key={i} resident={resident} />;
            })
          : null}
        {isLoading && residents.length === 0 && (
          <LoadingCard>
            <Spinner />
          </LoadingCard>
        )}
        {!isLoading && residents && !residents?.length && <NoResults />}
      </MobileViewOnly>
    </NameListPage>
  );
};

const ResidentListColumn = ({ resident }: { resident: BEResidentUser }) => {
  const history = usePolyfitHistory();

  return (
    <NameListColumnNameRow
      onClick={() =>
        history.push({
          to: "BE_ADMIN_MEMBER_DETAIL",
          query: {
            accountId: resident.accountId,
            communityId: resident.communityId,
          },
        })
      }
    >
      <UserPicCell>
        <PreUserPicCell>
          <Avatar
            src={resident.account.user.picture || defaultUserImg}
            alt={resident.account.user.name}
            size={40}
          />
        </PreUserPicCell>
        <PreUserNameCell>
          <Name>{resident.account.user.name}</Name>
          <NameListOrganizationData>
            <ResidentRoleTagWrapper role={resident.role.name}>
              {
                roleOptions.find((role) => role.value === resident.role.name)
                  ?.text
              }
            </ResidentRoleTagWrapper>
          </NameListOrganizationData>
        </PreUserNameCell>
      </UserPicCell>
      <Panel>
        {/* 校区 */}
        {resident.community.name}
      </Panel>
      <Panel>
        {/* ユーザータイプ */}
        {getCommunityUserTypeText(resident.type)}
      </Panel>
      <Panel>
        {/* 年代 */}
        {getAgeGroupText(resident.account.user.baseInfo?.ageGroup)}
      </Panel>
      <Panel>
        {/* 住所 */}
        {resident?.account?.user?.baseInfo?.city}
        {resident?.account?.user?.baseInfo?.address1}
      </Panel>
      <Panel>
        {withinWalkMinuteLabel(
          resident?.account?.user?.residentInfo?.walkMinute
        )}
      </Panel>
      <Panel>
        {/* 有償・無償 */}
        {resident?.account?.user?.residentInfo?.preferredPaid.includes(
          "PAID"
        ) &&
        resident?.account?.user?.residentInfo?.preferredPaid.includes("FREE")
          ? "どちらでも"
          : resident?.account?.user?.residentInfo?.preferredPaid.includes(
              "PAID"
            )
          ? "有償"
          : resident?.account?.user?.residentInfo?.preferredPaid.includes(
              "FREE"
            )
          ? "無償"
          : "該当なし"}
      </Panel>
      <Panel>
        {/* 希望活動日 */}
        {resident?.account?.user?.residentInfo?.preferredDays?.length > 0
          ? (resident?.account?.user?.residentInfo?.preferredDays ?? [])
              ?.sort((a, b) => {
                const indexA = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === a
                );
                const indexB = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === b
                );
                return indexA - indexB;
              })
              .map((day, index) => <p key={index}>{getDayOfWeekText(day)}</p>)
          : "該当なし"}
      </Panel>
      <Panel>
        {/* スキル・資格 */}
        {getLicensesAndSkills(resident.account.user.residentInfo)?.map(
          (license, index) => <p key={index}>{license}</p>
        ) ?? "該当なし"}
      </Panel>
      <Panel>
        {/* 希望活動内容 */}
        {getPreferredVolunteerTypes(resident.account.user.residentInfo)?.map(
          (license, index) => <p key={index}>{license}</p>
        ) ?? "該当なし"}
      </Panel>

      <Panel>
        <ArrowRightIcon size={24} />
      </Panel>
    </NameListColumnNameRow>
  );
};

const ResidentListCardColumn = ({ resident }: { resident: BEResidentUser }) => {
  const history = usePolyfitHistory();

  return (
    <ResidentMobileCard
      onClick={() =>
        history.push({
          to: "BE_ADMIN_MEMBER_DETAIL",
          query: {
            accountId: resident.accountId,
            communityId: resident.communityId,
          },
        })
      }
    >
      <ResidentMobileCardHeader>
        <Avatar
          src={resident.account.user.picture || defaultUserImg}
          alt={resident.account.user.name}
          size={40}
        />
        <div>
          <Name>{resident.account.user.name}</Name>
          <NameListOrganizationData>
            <ResidentRoleTagWrapper role={resident.role.name}>
              {
                roleOptions.find((role) => role.value === resident.role.name)
                  ?.text
              }
            </ResidentRoleTagWrapper>
          </NameListOrganizationData>
        </div>
      </ResidentMobileCardHeader>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>校区</ResidentMobileCardLabel>
        {resident.community.name}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>ユーザータイプ</ResidentMobileCardLabel>
        {roleOptions.find((role) => role.value === resident.role.name)?.text}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>年代</ResidentMobileCardLabel>
        {getAgeGroupText(resident.account.user.baseInfo?.ageGroup)}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>住所</ResidentMobileCardLabel>
        {resident?.account?.user?.baseInfo?.city}
        {resident?.account?.user?.baseInfo?.address1}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>学校からの徒歩</ResidentMobileCardLabel>
        {withinWalkMinuteLabel(
          resident?.account?.user?.residentInfo?.walkMinute
        )}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>有償・無償</ResidentMobileCardLabel>
        {resident?.account?.user?.residentInfo?.preferredPaid.includes(
          "PAID"
        ) &&
        resident?.account?.user?.residentInfo?.preferredPaid.includes("FREE")
          ? "どちらでも"
          : resident?.account?.user?.residentInfo?.preferredPaid.includes(
              "PAID"
            )
          ? "有償"
          : resident?.account?.user?.residentInfo?.preferredPaid.includes(
              "FREE"
            )
          ? "無償"
          : "該当なし"}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>希望活動日</ResidentMobileCardLabel>
        {resident?.account?.user?.residentInfo?.preferredDays?.length > 0
          ? (resident?.account?.user?.residentInfo?.preferredDays ?? [])
              ?.sort((a, b) => {
                const indexA = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === a
                );
                const indexB = dayOfWeekOptions.findIndex(
                  (dayObj) => dayObj.value === b
                );
                return indexA - indexB;
              })
              .map((day, index) => <p key={index}>{getDayOfWeekText(day)}</p>)
          : "該当なし"}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>スキル・資格</ResidentMobileCardLabel>
        {getLicensesAndSkills(resident.account.user.residentInfo)?.map(
          (license, index) => <p key={index}>{license}</p>
        ) ?? "該当なし"}
      </ResidentMobileCardSection>
      <ResidentMobileCardSection>
        <ResidentMobileCardLabel>希望活動内容</ResidentMobileCardLabel>
        {getPreferredVolunteerTypes(resident.account.user.residentInfo)?.map(
          (license, index) => <p key={index}>{license}</p>
        ) ?? "該当なし"}
      </ResidentMobileCardSection>
    </ResidentMobileCard>
  );
};

const NameListPage = styled.div`
  margin: 0 auto;
`;

const NameListHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #1a1c21;
`;

const NameListTable = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const Name = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const Panel = styled.th`
  color: #343741;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  padding-left: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const NameListHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
`;

const NameListColumnNameRow = styled.tr<{ head?: boolean }>`
  background-color: #fff;
  ${(props) =>
    !props.head &&
    `border-top: 1px solid #e3e6eb; cursor: pointer; &:hover { filter: brightness(95%);}`};
`;

const NameListColumn = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
`;

const NameListColumnWrapper = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const NameListOrganizationData = styled.div`
  display: flex;
  gap: 4px;
  color: #69707d;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  text-align: left;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserPicCell = styled.th`
  color: #343741;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const PreUserPicCell = styled.div`
  padding: 14px;
`;

const PreUserNameCell = styled.div`
  padding: 14px 0;
`;

const ResidentMobileCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid rgba(228, 230, 243, 1);
  gap: 16px;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
`;

const ResidentMobileCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ResidentRoleTagWrapper = styled.div<{ role: Role }>`
  display: inline-block;
  padding: 2px 8px;
  background-color: ${(props) => {
    switch (props.role) {
      case Role.CSAdmin:
        return "#79aad9";
      case Role.CSOperator:
        return "#EE789D";
      case Role.CSMember:
        return "#6DCCB1";
      default:
        break;
    }
  }};
  border-radius: 40px;
  color: #ffffff;
  /* font-size: 12px; */
`;

const ResidentMobileCardSection = styled.div``;

const ResidentMobileCardLabel = styled.div`
  color: #69707d;
  font-size: 12px;
`;
