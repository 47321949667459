import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import {
  EditBaseInfoIcon,
  EditLinkContainer,
  PCBaseInfoWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
} from "../../../pages/pta/admin/ProfilePage";
import { usePartner } from "../../../hooks/api/partner";
import { Heading2 } from "../../../components/Typo";
import { PartnerInfoEditModal } from "./PartnerInfoEditModal";
import { NoPartner } from "./NoPartner";

export const PartnerInfoContent = ({ inPTA }: { inPTA: boolean }) => {
  const { partner, isLoading, fetchPartner } = usePartner(inPTA);
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    fetchPartner();
  }, []);

  if (isLoading === false && partner === null) {
    return <NoPartner fetchPartner={fetchPartner} />;
  }
  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <>読み込み中…</>
          ) : (
            <div>
              <StyledContainerPc>
                <Title>他の保護者情報</Title>

                <StyledTable>
                  <StyledTr>
                    <StyledTh>他の保護者名</StyledTh>
                    <StyledTd>
                      {partner?.lastName} {partner?.firstName}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>ふりがな</StyledTh>
                    <StyledTd>
                      {partner?.lastNameKana} {partner?.firstNameKana}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>他の保護者のメールアドレス</StyledTh>
                    <StyledTd>{partner?.email}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>保護者情報を管理者に共有</StyledTh>
                    <StyledTd>
                      {partner?.isShare ? "共有する" : "共有しない"}
                    </StyledTd>
                  </StyledTr>
                </StyledTable>
              </StyledContainerPc>

              <StyledContainerSp>
                <Title>他の保護者情報</Title>
                <StyledTableSp>
                  <StyledTr>
                    <StyledTHSp>他の保護者名</StyledTHSp>
                    <StyledTdSp>
                      {partner?.lastName} {partner?.firstName}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>ふりがな</StyledTHSp>
                    <StyledTdSp>
                      {partner?.lastNameKana} {partner?.firstNameKana}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>他の保護者のメールアドレス</StyledTHSp>
                    <StyledTdSp>{partner?.email}</StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>保護者情報を管理者に共有</StyledTHSp>
                    <StyledTdSp>
                      {partner?.isShare ? "共有する" : "共有しない"}
                    </StyledTdSp>
                  </StyledTr>
                </StyledTableSp>
              </StyledContainerSp>
            </div>
          )}
          {!isLoading && (
            <EditLinkContainer onClick={onOpen}>
              <PencilIcon />
              <EditBaseInfoIcon>編集</EditBaseInfoIcon>
            </EditLinkContainer>
          )}

          {isOpen && (
            <PartnerInfoEditModal
              onClose={onClose}
              partner={partner}
              fetch={fetchPartner}
            />
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>
    </>
  );
};

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

export const PartnerInfoContainer = styled.div`
  width: 100%;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
  word-break: break-all;
`;

export const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #0077cc;
  padding: 5px;
`;
