import { z } from "zod";
import { transformToBoolean } from "../utils/transform";
import { classNumberSchema, gradeNumberSchema } from "../rules/child";

export const baseInfoTeacherInfoSchema = z
  .object({
    hasHomeroom: z
      .union([z.string(), z.boolean()])
      .transform(transformToBoolean),
    grade: gradeNumberSchema.optional(),
    class: classNumberSchema.optional(),
  })
  .refine(
    (data) => {
      if (data.hasHomeroom) {
        return !!data.grade;
      }
      return true;
    },
    {
      message: "学年・クラスを選択してください",
      path: ["teacherInfo", "grade"],
    }
  )
  .refine(
    (data) => {
      if (data.hasHomeroom) {
        return !!data.class;
      }
      return true;
    },
    {
      message: "学年・クラスを選択してください",
      path: ["teacherInfo", "class"],
    }
  );

export type BaseInfoTeacherInfo = z.infer<typeof baseInfoTeacherInfoSchema>;

export const teacherInfoSchema = z
  .object({
    grade: gradeNumberSchema,
    class: classNumberSchema,
  })
  .nullable();

export type TeacherInfo = z.infer<typeof teacherInfoSchema>;
