// アンケートの集計に用いるデータ型

import {
  MultiChoiceQuestion,
  OpenEndedQuestion,
} from "@shared/validator/features/survey.schema";
import { ChildClass } from "./child";
import { get } from "./client";
import { isMultiChoiceQuestion, isOpenEndedQuestion } from "./survey";

// 各ユーザーの子供の名前、学年、クラスを一緒に返す
export interface Child {
  lastName: string;
  firstName: string;
  grade: number;
  class: ChildClass | undefined;
}

// 各結果（選択式と記述式それぞれ）を表すデータ型
export type SurveyResult = MultiChoiceSurveyResult | OpenEndedSurveyResult;

// 各選択式回答を表すデータ型
export interface MultiChoiceSurveyResultAnswer {
  userId: string;
  userName: string;
  children: Array<Child>;
  choiceIds: Array<string>;
  numberOfChildren: number;
}
export interface MultiChoiceSurveyResult {
  // 対応する質問
  question: MultiChoiceQuestion;
  answers: Array<MultiChoiceSurveyResultAnswer>;
}

// 各記述式回答を表すデータ型
export interface OpenEndedSurveyResultAnswer {
  userId: string;
  userName: string;
  children: Array<Child>;
  text: string;
}
export interface OpenEndedSurveyResult {
  // 対応する質問
  question: OpenEndedQuestion;
  answers: Array<OpenEndedSurveyResultAnswer>;
}

// サーバーとの受け渡しを担当する型
export interface SurveyResults {
  // 結果の閲覧権限
  isAllowedToShowResults: boolean;
  // 権限ない場合は空が返ってくる
  resultList: SurveyResult[];
}

// 種別判定用の関数、回答用
// 質問用の判定関数を呼ぶだけ
export function isMultiChoiceSurveyResult(
  result: SurveyResult
): result is MultiChoiceSurveyResult {
  return isMultiChoiceQuestion(result.question);
}
export function isOpenEndedSurveyResult(
  result: SurveyResult
): result is OpenEndedSurveyResult {
  return isOpenEndedQuestion(result.question);
}

// アンケート回答をサーバーから受信する関数
// ユーザーと質問から該当する回答を探す
export async function fetchSurveyResults(
  surveyQuestionsId: string
): Promise<SurveyResults> {
  const query = {
    surveyQuestionsId: surveyQuestionsId,
  };
  return await get<typeof query, SurveyResults>("/surveyResults/fetch", query);
}
