import { InternalUserRole } from "../types/internalUserRole";

/**
 * 管理者権限かどうか
 * @param internalRole
 * @returns 管理者権限（INTERNAL_ADMINまたはPOLYME_ADMIN）の場合はtrue
 */
export function isInternalRoleAdmin(internalRole?: InternalUserRole): boolean {
  if (!internalRole) {
    return false;
  }
  return (
    internalRole === InternalUserRole.INTERNAL_ADMIN ||
    internalRole === InternalUserRole.POLYME_ADMIN
  );
}

/**
 *  指定したInternalUserRole以上のInternalUserRoleを持つかどうか
 * @param currentInternalUserRole 自身のInternalUserRole
 * @param referenceInternalUserRole 参照するInternalUserRole
 * @returns 自身のInternalUserRoleが参照するInternalUserRole以上の場合はtrue
 * @example hasHigherInternalRole("InternalAdmin", "PolymeAdmin") => true
 * @example hasHigherInternalRole("PolymeAdmin", "InternalAdmin") => false
 * @example hasHigherInternalRole("PolymeAdmin", "PolymeAdmin") => true
 */
export function hasHigherInternalRole(
  currentRole?: InternalUserRole,
  referenceRole?: InternalUserRole
): boolean {
  if (!currentRole || !referenceRole) {
    return false;
  }
  switch (referenceRole) {
    case InternalUserRole.INTERNAL_ADMIN:
      return currentRole === InternalUserRole.INTERNAL_ADMIN;
    case InternalUserRole.POLYME_ADMIN:
      return (
        currentRole === InternalUserRole.INTERNAL_ADMIN ||
        currentRole === InternalUserRole.POLYME_ADMIN
      );
    case InternalUserRole.MEMBER:
      return true;
  }
}
