import React, { useState } from "react";
import styled from "styled-components";
import { applicationStatusOptions } from "../../../apiClients/recruitmentApplication";
import { Avatar } from "../../../components/Common/Avatar";
import { toDateIntervalFormat } from "../../../utils/time";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { Link } from "src/components/Link";
import { RandomSelection } from "./RandomSelection";
import { MemberDetailLink } from "./MemberDetailLink";
import {
  approveApplication,
  declineApplicationByAdmin,
  rejectApplication,
} from "src/apiClients/recruitmentApplicationEvent";
import {
  applicationAttendanceStatus,
  ApplicationAttendanceStatus,
} from "@shared/types/recruitmentApplicationEvent";
import { getCommunityUserTypeText } from "src/features/Member/Community/utils/utils";
import { CommunityUser, CommunityUserType } from "@shared/types/userType";
import { colorsPallet } from "src/theme";
import { ApplicationChildInfoPopup } from "./ApplicationChildInfoPopup";

type ApplicantTableProps = {
  fetchRecruitment: () => Promise<void>;
  applicantList: RecruitmentApplication[] | null;
  handleChangeStatus: (
    applicant: RecruitmentApplication,
    eventId: string
  ) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleAttendanceModalOpen: (
    applicant: RecruitmentApplication,
    eventId: string
  ) => void;
};

export const ApplicantTable = ({
  fetchRecruitment,
  applicantList,
  handleChangeStatus,
  handleAttendanceModalOpen,
}: ApplicantTableProps) => {
  const [randomSelectedApplicants, setRandomSelectedApplicants] = useState<
    RecruitmentApplication[]
  >([]);

  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

  const filteredApplicationStatusOptions = (
    event: RecruitmentApplication["eventStatuses"][number]
  ) => {
    if (event.selectionStatus === "APPROVED") {
      return applicationStatusOptions.filter((opt) => opt.value !== "APPLIED");
    }
    return applicationStatusOptions;
  };

  const onClickRandomSelection = (count: number) => {
    if (!applicantList) return;
    const selectedIndices = new Set<number>();
    while (selectedIndices.size < count) {
      const randomIndex = Math.floor(Math.random() * applicantList.length);
      selectedIndices.add(randomIndex);
    }
    const selectedApplicants = Array.from(selectedIndices).map(
      (index) => applicantList[index]
    );

    setRandomSelectedApplicants(selectedApplicants);
  };

  const onClickExecute = async (status: string, comment: string) => {
    for (const applicant of randomSelectedApplicants) {
      for (const event of applicant.eventStatuses) {
        if (
          event.selectionStatus !== "REJECTED" &&
          event.selectionStatus !== "DECLINED"
        ) {
          switch (status) {
            case "APPROVED":
              if (event.selectionStatus !== "APPROVED") {
                await approveApplication(event.id);
              }
              break;
            case "REJECTED":
              await rejectApplication(event.id, comment);
              break;
            case "DECLINED":
              await declineApplicationByAdmin(event.id, comment);
              break;
            default:
              break;
          }
        }
      }
    }
    await fetchRecruitment();
  };

  const showApplicantTable =
    randomSelectedApplicants.length > 0
      ? randomSelectedApplicants
      : applicantList;

  return (
    <>
      {applicantList && (
        <RandomSelection
          applicants={applicantList}
          randomSelectedApplicants={randomSelectedApplicants}
          onClickRandomSelection={onClickRandomSelection}
          onClickExecute={onClickExecute}
        />
      )}
      <Table>
        <thead>
          <TheadTr>
            <TheadTh $first>名前</TheadTh>
            <TheadTh>応募タイトル</TheadTh>
            <TheadTh>コメント</TheadTh>
            <TheadTh>選考</TheadTh>
            <TheadTh>出欠席</TheadTh>
            <TheadTh $last></TheadTh>
          </TheadTr>
        </thead>

        {showApplicantTable?.map((applicant, i) => {
          return (
            <Tbody key={i}>
              <TbodyTr>
                <TbodyTd>
                  <UserPicCell>
                    <Avatar
                      src={applicant.user.picture || defaultUserImg}
                      alt="ユーザーのアイコン"
                      size={40}
                    />
                    <FlexBox>
                      <ChildNameWrapper>
                        <Name>{applicant.user.name}</Name>
                        {applicant.cSChildren &&
                          applicant.cSChildren.length > 0 && (
                            <ApplicationChildInfoPopup applicant={applicant} />
                          )}
                      </ChildNameWrapper>
                      <CommunityUserTypeLabel
                        $type={applicant.communityUserType}
                      >
                        {getCommunityUserTypeText(
                          applicant.communityUserType || ""
                        )}
                      </CommunityUserTypeLabel>
                    </FlexBox>
                  </UserPicCell>
                </TbodyTd>
                <TbodyTd>
                  <LinkText
                    to={{
                      to: "RESIDENT_RECRUITMENT_DETAIL",
                      query: { id: applicant.recruitment.id },
                    }}
                    $place="start"
                  >
                    {applicant.recruitment.title}
                  </LinkText>
                </TbodyTd>
                <TbodyTd>
                  <CommentText
                    title={applicant.coverLetterChatMessage?.content}
                    $isEmpty={!applicant.coverLetterChatMessage?.content}
                  >
                    {applicant.coverLetterChatMessage?.content || "なし"}
                  </CommentText>
                </TbodyTd>
                <TbodyTd>
                  <StatusColumn>
                    {applicant.eventStatuses.map((event) => (
                      <StatusRow key={event.id}>
                        <DateText>
                          {toDateIntervalFormat(
                            new Date(event.recruitmentSchedule.start),
                            new Date(event.recruitmentSchedule.end)
                          )}
                        </DateText>
                        <InputSelect
                          value={
                            event.rejectedAt
                              ? "REJECTED"
                              : event.selectionStatus
                          }
                          onChange={handleChangeStatus(applicant, event.id)}
                          disabled={
                            !!event.rejectedAt ||
                            event.selectionStatus === "REJECTED" ||
                            event.selectionStatus === "DECLINED"
                          }
                        >
                          {filteredApplicationStatusOptions(event).map(
                            (opt) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            )
                          )}
                        </InputSelect>
                      </StatusRow>
                    ))}
                  </StatusColumn>
                </TbodyTd>
                <TbodyTd>
                  <StatusColumn>
                    {applicant.eventStatuses.map((event) => (
                      <StatusRow key={event.id}>
                        {event.selectionStatus === "APPROVED" ? (
                          <AttendanceStatusButton
                            onClick={() =>
                              handleAttendanceModalOpen(applicant, event.id)
                            }
                          >
                            <StatusBadge $status={event.attendanceStatus}>
                              {event.attendanceStatus ===
                              applicationAttendanceStatus.ATTENDED
                                ? "済"
                                : event.attendanceStatus ===
                                  applicationAttendanceStatus.ABSENT
                                ? "欠"
                                : "未"}
                            </StatusBadge>
                          </AttendanceStatusButton>
                        ) : (
                          <StatusDash>-</StatusDash>
                        )}
                      </StatusRow>
                    ))}
                  </StatusColumn>
                </TbodyTd>
                <TbodyTd>
                  <MemberDetailLink userId={applicant.user.id} />
                </TbodyTd>
              </TbodyTr>
            </Tbody>
          );
        })}
      </Table>
    </>
  );
};

const Table = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const TheadTr = styled.tr`
  background-color: #fff;
`;

const TheadTh = styled.th<{
  $first?: boolean;
  $last?: boolean;
}>`
  color: #343741;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding: 8px;
  ${(props) => props.$first && `padding-left: 24px;`}
  ${(props) => props.$last && `padding-right: 16px;`}
`;

const Tbody = styled.tbody`
  padding: 0 16px;
`;

const TbodyTr = styled.tr`
  background-color: #fff;
  border-top: 1px solid #e3e6eb;
`;

const TbodyTd = styled.td`
  color: #343741;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
  max-width: 200px;
`;

const UserPicCell = styled.div`
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

const Name = styled.div`
  color: #343741;
  font-size: 16px;
  font-weight: normal;
`;

const ChildNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const LinkText = styled(Link)<{ $place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.$place};
  color: #07c;
`;

const CommentText = styled.div<{ $isEmpty: boolean }>`
  color: ${(props) => (props.$isEmpty ? "grey" : "inherit")};
  max-height: 100px;
  overflow-y: auto;
  white-space: normal;
  padding-right: 10px;
`;

const StatusColumn = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 16px;
`;

const StatusRow = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const DateText = styled.div`
  font-size: 14px;
  color: #343741;
`;

const AttendanceStatusButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: block;
  width: 48px;
`;

const StatusBadge = styled.span<{
  $status: ApplicationAttendanceStatus | null;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 20px;
  color: #ffffff;
  background-color: ${(props) => {
    switch (props.$status) {
      case applicationAttendanceStatus.ATTENDED:
        return "#0077cc";
      case applicationAttendanceStatus.ABSENT:
        return "#e65c5c";
      default:
        return "#969696";
    }
  }};
`;

const StatusDash = styled.span`
  color: #69707d;
  font-size: 14px;
  padding: 0 12px;
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 300px;
  gap: 4px;
`;

const CommunityUserTypeLabel = styled.div<{ $type: CommunityUserType | null }>`
  display: inline-block;
  padding: 2px 8px;
  border-radius: 40px;
  background-color: ${
    (props) =>
      props.$type === CommunityUser.PARENT
        ? colorsPallet.primary // 保護者
        : props.$type === CommunityUser.TEACHER
        ? colorsPallet.accent // 教職員
        : colorsPallet.success // 地域住民
  };
  color: #ffffff;
  white-space: nowrap;
`;
