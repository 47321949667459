import React, { useMemo } from "react";
import { Margin } from "../../../components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "src/components/Modal";
import { ModalLabel } from "src/features/Post/CreatePostModal";
import { Recruitment } from "@shared/types/recruitment";
import {
  applicationAddingScheduleBodySchema,
  ApplicationAddingScheduleBodySchema,
} from "@shared/validator/features/recruitment-application.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toDateIntervalFormat } from "src/utils/time";
import { CheckBox } from "src/components/form/CheckBox";
import { Label } from "src/components/form/Label";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { colorsPallet } from "src/theme";

type Props = {
  recruitment: Recruitment;
  applicant: RecruitmentApplication;
  onSubmit: (values: ApplicationAddingScheduleBodySchema) => void;
  onClose: () => void;
};

export const AddScheduleRecruitmentModal = ({
  recruitment,
  applicant,
  onSubmit,
  onClose,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<ApplicationAddingScheduleBodySchema>({
    resolver: zodResolver(applicationAddingScheduleBodySchema),
    defaultValues: {
      recruitmentId: applicant.recruitment.id,
      recruitmentApplicationId: applicant.id,
      selectedScheduleIds: [],
      comment: "",
    },
    mode: "onChange",
  });

  const selectedScheduleIds = watch("selectedScheduleIds");

  const appliedScheduleIds = useMemo(
    () =>
      applicant.eventStatuses.map((status) => status.recruitmentSchedule.id),
    [applicant]
  );

  const handleScheduleChange = (scheduleId: string, checked: boolean) => {
    const newSelectedIds = checked
      ? [...selectedScheduleIds, scheduleId]
      : selectedScheduleIds.filter((id) => id !== scheduleId);
    setValue("selectedScheduleIds", newSelectedIds, { shouldValidate: true });
  };

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>応募日程を追加</p>
      </ModalHeader>
      <ModalBody>
        <p>追加で応募したい日程を選択してください</p>
        <Margin marginTop={15} />

        <ModalLabel>
          <Label required>追加日程</Label>
        </ModalLabel>
        <Margin marginTop={5} />
        <ScheduleCheckboxContainer>
          {recruitment.schedule
            .filter(
              (schedule) => !appliedScheduleIds.includes(schedule.id ?? "")
            )
            .map((schedule) => (
              <CheckboxLabel key={schedule.id}>
                <CheckBox
                  checked={selectedScheduleIds.includes(schedule.id ?? "")}
                  onChange={(e) =>
                    handleScheduleChange(schedule.id ?? "", e.target.checked)
                  }
                  disabled={schedule.status === "CLOSED"}
                />
                <ScheduleText $disabled={schedule.status === "CLOSED"}>
                  {toDateIntervalFormat(
                    new Date(schedule.start),
                    new Date(schedule.end)
                  )}
                  {schedule.status === "CLOSED" && (
                    <IsAlreadyClosedText> ※募集停止</IsAlreadyClosedText>
                  )}
                </ScheduleText>
              </CheckboxLabel>
            ))}
        </ScheduleCheckboxContainer>
        {errors.selectedScheduleIds && (
          <ErrorMessage>{errors.selectedScheduleIds.message}</ErrorMessage>
        )}

        <Margin marginTop={15} />
        <ModalLabel>
          <Label optional>応募メッセージ</Label>
        </ModalLabel>
        <Margin marginTop={5} />
        <TextArea
          {...register("comment")}
          cols={30}
          rows={5}
          placeholder="追加で参加したいです！よろしくお願い致します。"
        />
        {errors.comment && (
          <ErrorMessage>{errors.comment.message}</ErrorMessage>
        )}
      </ModalBody>
      <ModalSubmitButtons
        submitText="追加応募"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onClose}
        disabled={!isValid}
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;

const TextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

const ScheduleCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ScheduleText = styled.span<{ $disabled: boolean }>`
  color: ${(props) => (props.$disabled ? "#69707d" : "inherit")};
`;

const IsAlreadyClosedText = styled.span`
  font-size: 12px;
  color: ${colorsPallet.danger};
`;
